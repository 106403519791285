import { IFoeOrder } from "../interfaces/foe";

export class FoeActions {
    public static CREATE_FOE_REQ = 'CREATE_FOE_REQ';
    public static createForReq(foe: IFoeOrder) {
        return {
            type: this.CREATE_FOE_REQ,
            payload: foe,
        };
    }

    public static CREATE_FOE_OK = 'CREATE_FOE_OK';
    public static createFoeOk(resp: string) {
        return {
            type: this.CREATE_FOE_OK,
            payload: resp,
        };
    }

    public static CREATE_FOE_FAIL = 'CREATE_FOE_FAIL';
    public static createFoeFail(error: string) {
        return {
            type: this.CREATE_FOE_FAIL,
            payload: error,
        };
    }

}
