import * as actions from './order-list.actions';

import { Action } from '../../shared/interfaces/shared';
import { GET_FILTER_SOAS_OK } from '../../soa/state/soa.actions';
import { IOrderListState } from '../interfaces/order-list-state';
import { orderListInitState } from './order-list.init-state';
import { uniqBy } from 'lodash';

export function orderListReducer(state: IOrderListState = orderListInitState, action: Action<any>): IOrderListState {
    switch (action.type) {
        case actions.RESET_ORDER_LIST: {
            return {
                ...orderListInitState,
            };
        }

        case actions.GET_ORIGINAL_ORDER_OK: {
            return {
                ...state,
                document: {
                    ...state.document,
                    response: action.payload
                }
            };
        }

        case actions.TOGGLE_GET_ORIGINAL_ORDER_LOADING: {
            return {
                ...state,
                document: {
                    ...state.document,
                    loading: action.payload
                }
            };
        }

        case actions.GET_ALL_ORDER_LISTS_FAIL: {
            return {
                ...state,
                document: {
                    ...state.document,
                    error: action.payload
                }
            };
        }

        case actions.GET_ALL_ORDER_LIST_OK: {
            const orderLists = action.payload.data;

            return {
                ...state,
                orderLists: orderLists,
                pagination: action.payload,
                filteredOrderLists: orderLists,
            };
        }

        case actions.TOGGLE_GET_ORDER_LISTS_LOADING: {
            return {
                ...state,
                getOrderListsLoading: action.payload
            };
        }

        case actions.TOGGLE_CREATE_ORDER_LIST_MODAL: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    create: action.payload
                }
            };
        }

        case actions.CREATE_ORDER_LIST_OK: {
            return {
                ...state,
                createOrderList: {
                    ...state.createOrderList,
                    response: action.payload,
                    error: ''
                },
                deleteOrderList: {
                    ...orderListInitState.deleteOrderList
                },
                editOrderList: {
                    ...orderListInitState.editOrderList
                }
            };
        }

        case actions.CREATE_ORDER_LIST_FAIL: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    create: false
                },
                createOrderList: {
                    loading: false,
                    error: action.payload,
                    response: ''
                },
                deleteOrderList: {
                    ...orderListInitState.deleteOrderList
                },
                editOrderList: {
                    ...orderListInitState.editOrderList
                }
            };
        }

        case actions.TOGGLE_CREATE_ORDER_LIST_LOADING: {
            return {
                ...state,
                createOrderList: {
                    ...state.createOrderList,
                    loading: action.payload
                }
            };
        }

        case actions.SET_CURRENT_ORDER_LIST: {
            return {
                ...state,
                editOrderList: {
                    ...state.editOrderList,
                    currentOrderList: action.payload
                },
            };
        }

        case actions.RESET_CURRENT_ORDER_LIST: {
            return {
                ...state,
                editOrderList: {
                    ...state.editOrderList,
                    currentOrderList: orderListInitState.editOrderList.currentOrderList
                },
                modals: {
                    delete: false,
                    create: false,
                    shippingFees: false,
                    updateAddress: false,
                },
                viewMode: false,
            }
        }

        case actions.EDIT_ORDER_LIST_OK: {
            return {
                ...state,
                editOrderList: {
                    ...state.editOrderList,
                    response: action.payload,
                    error: ''
                },
                deleteOrderList: {
                    ...orderListInitState.deleteOrderList
                },
                createOrderList: {
                    ...orderListInitState.createOrderList
                }
            };
        }

        case actions.EDIT_ORDER_LIST_FAIL: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    create: false,
                },
                editOrderList: {
                    ...state.editOrderList,
                    loading: false,
                    error: action.payload,
                    response: ''
                },
                deleteOrderList: {
                    ...orderListInitState.deleteOrderList
                },
                createOrderList: {
                    ...orderListInitState.createOrderList
                }
            };
        }

        case actions.TOGGLE_EDIT_ORDER_LIST_LOADING: {
            return {
                ...state,
                editOrderList: {
                    ...state.editOrderList,
                    loading: action.payload
                }
            };
        }

        case actions.TOGGLE_DELETE_ORDER_LIST_MODAL: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    delete: action.payload
                }
            };
        }

        case actions.SET_DELETED_ORDER_LIST: {
            return {
                ...state,
                deleteOrderList: {
                    ...state.deleteOrderList,
                    deletedOrderList: action.payload
                }
            };
        }

        case actions.DELETE_ORDER_LIST_BY_ID_OK: {
            return {
                ...state,
                deleteOrderList: {
                    ...state.deleteOrderList,
                    response: action.payload,
                    error: ''
                },
                editOrderList: {
                    ...orderListInitState.editOrderList
                },
                createOrderList: {
                    ...orderListInitState.createOrderList
                }

            };
        }

        case actions.DELETE_ORDER_LIST_BY_ID_FAIL: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    delete: false
                },
                deleteOrderList: {
                    ...state.deleteOrderList,
                    loading: false,
                    error: action.payload,
                    response: ''
                },
                editOrderList: {
                    ...orderListInitState.editOrderList
                },
                createOrderList: {
                    ...orderListInitState.createOrderList
                }
            };
        }

        case actions.TOGGLE_DELETE_ORDER_LIST_LOADING: {
            return {
                ...state,
                deleteOrderList: {
                    ...state.deleteOrderList,
                    loading: action.payload
                }
            };
        }

        case actions.OrderListActions.CONFIRM_CUSTOMER_ORDER_REQ: {
            return {
                ...state,
                confirmCustomerOrder: {
                    ...state.confirmCustomerOrder,
                    loading: true,
                },
            };
        }

        case actions.OrderListActions.CONFIRM_CUSTOMER_ORDER_OK: {
            return {
                ...state,
                confirmCustomerOrder: {
                    ...state.confirmCustomerOrder,
                    loading: false,
                    error: '',
                },
            };
        }

        case actions.OrderListActions.CONFIRM_CUSTOMER_ORDER_FAIL: {
            return {
                ...state,
                confirmCustomerOrder: {
                    ...state.confirmCustomerOrder,
                    loading: false,
                    error: action.payload,
                },
            };
        }

        case actions.OrderListActions.SEND_TO_ERP_REQ: {
            return {
                ...state,
                sendToErp: {
                    ...state.sendToErp,
                    loading: true,
                    error: '',
                },
            };
        }

        case actions.OrderListActions.SEND_TO_ERP_OK: {
            return {
                ...state,
                sendToErp: {
                    ...state.sendToErp,
                    loading: false,
                    error: '',
                },
            };
        }

        case actions.OrderListActions.SEND_TO_ERP_FAIL: {
            return {
                ...state,
                sendToErp: {
                    ...state.sendToErp,
                    loading: false,
                    error: action.payload,
                },
            };
        }

        case actions.OrderListActions.TOGGLE_VALIDATION_LOADING: {
            return {
                ...state,
                validationLoading: action.payload,
            };
        }

        case actions.OrderListActions.TOGGLE_FILTERS: {
            return {
                ...state,
                showFilters: action.payload,
                filtersModal: {
                    ...state.filtersModal,
                    poNumbers: [],
                },
            };
        }

        case actions.OrderListActions.SET_FILTERED_ORDER_LIST: {
            return {
                ...state,
                filteredOrderLists: action.payload,
            };
        }

        case actions.OrderListActions.SET_ORDER_LIST_FILTERS: {
            return {
                ...state,
                filters: action.payload,
            };
        }

        case actions.OrderListActions.SET_VIEW_MODE: {
            return {
                ...state,
                viewMode: action.payload,
            };
        }

        case GET_FILTER_SOAS_OK: {
            return {
                ...state,
                filterSoas: action.payload,
            };
        }

        case actions.OrderListActions.GET_FILTER_PO_NUMBERS_OK: {

            return {
                ...state,
                filtersModal: {
                    ...state.filtersModal,
                    poNumbers: uniqBy(action.payload, 'value')
                        .map((obj: any) => obj && obj.value && obj.value),
                },
            };
        }

        case actions.OrderListActions.GET_ORDER_BLOB_OK: {
            return {
                ...state,
                orderBlob: action.payload,
            };
        }

        case actions.OrderListActions.TOGGLE_SHIPPING_FEES_MODAL: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    shippingFees: action.payload,
                },
            };
        }

        case actions.OrderListActions.GET_SHIPPING_FEES_BY_ORDER_ID_OK: {
            return {
                ...state,
                shippingFees: action.payload,
            };
        }

        case actions.OrderListActions.SET_SELECTED_SHIPPING_FEE: {
            return {
                ...state,
                selectedShippingFee: action.payload,
            };
        }

        case actions.OrderListActions.TOGGLE_LOADING_SHIPPING_FEES: {
            return {
                ...state,
                loadingShippingFees: action.payload,
            };
        }

        case actions.OrderListActions.TOGGLE_UPDATE_ADDRESS_MODAL: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    updateAddress: action.payload,
                },
            };
        }

        default:
            return state;
    }
}
