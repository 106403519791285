import * as styles from './customers-inventory.page.style';

import { CustomersStateService, _saveCustomer } from '../services/customers.state-service';
import { ICustomer, ICustomerInventoryShippingAddress, ISaveCustomerReq } from '../interfaces/customer';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { ActivityIndicator } from '../../shared/components/activity-indicator/activity-indicator';
import { AppState } from '../../shared/interfaces/app.state';
import { Button } from '../../shared/components/button/button';
import { Card } from 'antd';
import { IAzureADFunctionProps } from 'react-aad-msal';
import { Input } from '../../shared/components/input/input';
import { MultipleInputValues } from '../../shared/components/multiple-input-values/multiple-input-values';
import { PageTitle } from '../../shared/components/page-title/page-title';
import { PageWrapper } from '../../shared/styles/page-wrapper';
import { SaveInventoryAddressModal } from '../components/save-inventory-address-modal/save-inventory-address-modal';
import { SectionTitle } from '../../shared/components/section-title/section-title';
import { SlimButton } from '../../shared/components/button/slim-button';
import { Theme } from '../../shared/styles/theme';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

interface IUrlParams {
    customerId: string;
}
interface Props extends IAzureADFunctionProps {}

export const CustomersInventoryPage: React.FC<Props> = (props: Props) => {
    const { customerId } = useParams<IUrlParams>();
    const history = useHistory();

    const customer = useSelector<AppState, ICustomer>(state => state.shared.breadcrumbs.customer);
    const inventoryAddressModal = useSelector<AppState, boolean>(state => state.customers.modals.inventoryAddress);
    const selectedInventoryAddress = useSelector<AppState, ICustomerInventoryShippingAddress>(state => state.customers.selectedInventoryAddress);

    const getInitialEmailValues = () => {
        if (!isEmpty(customer) && customer.inventoryEmail) {
            return customer.inventoryEmail.split(',').filter(e => e);
        }

        return [];
    };

    const getInitialInventoryMultiplier = () => {
        if (!isEmpty(customer) && customer.inventoryMultiplier) {
            return customer.inventoryMultiplier!.toString();
        }

        return '1';
    };

    const [emailValue, setEmailValue] = useState(getInitialEmailValues());
    const [poNumber, setPoNumber] = useState(!isEmpty(customer) ? customer.inventoryPoNumber! : '');
    const [addresses, setAddresses] = useState(!isEmpty(customer) ? customer.customerInventoryShippingAddresses! : []);
    const [inventoryMultiplier, setInventoryMultiplier] = useState(getInitialInventoryMultiplier());

    const render = () => {
        if (isEmpty(customer)) {
            return <ActivityIndicator />
        };

        return (
            <PageWrapper>
                <Theme.Section>
                    <SlimButton label={'Back'}
                        onClick={() => history.goBack()} />
                </Theme.Section>

                <Theme.SpaceBetween>
                    <PageTitle text={'Customer Inventory PO'} />

                    <Button label={'Save All'} onClick={() => onSaveCustomer()} disabled={isSaveButtonDisabled()} />
                </Theme.SpaceBetween>

                <Theme.Section>
                    <MultipleInputValues values={emailValue}
                        label={'Inventory Email (press ENTER to add email)'}
                        onTagClose={email => onEmailTagClose(email)}
                        required={true}
                        onChange={email => onChangeMultiple(email)} />

                    <Input value={poNumber}
                        label={'Inventory PO Number'}
                        required={true}
                        onChange={poNumber => setPoNumber(poNumber)} />

                    <Input value={inventoryMultiplier}
                        label={'Multiple to get desired inventory'}
                        type={'number'}
                        minValue={'1'}
                        required={true}
                        onChange={inventory => setInventoryMultiplier(inventory)} />
                </Theme.Section>

                <Theme.Section>
                    <SectionTitle text={'Shipping addresses (must be identical to Visual address)'} />

                    <SlimButton label={'Add New Address'}
                        overrides={{ root: 'margin-bottom: 20px' }}
                        onClick={() => onAddNewAddressClick()} />

                    {
                        (addresses && !addresses.length) &&
                        <div>No addresses found</div>
                    }

                    {
                        (addresses && !!addresses.length) &&
                        <Theme.Inline>
                            {
                                addresses.map(addr =>
                                    <Card hoverable={true}
                                        style={{ width: 350, marginRight: 10, marginBottom: 20 }}
                                        onClick={() => onCardClick(addr)}
                                        key={`${addr.id}_${addr.uuid}`}>
                                            <styles.CardRow>
                                                <styles.CardTitle>Location</styles.CardTitle>
                                                <styles.CardDescription>{addr.location || '-'}</styles.CardDescription>
                                            </styles.CardRow>

                                            <styles.CardRow>
                                                <styles.CardTitle>Ship to Address Line 1</styles.CardTitle>
                                                <styles.CardDescription>{addr.shipToAddressLine1 || '-'}</styles.CardDescription>
                                            </styles.CardRow>

                                            <styles.CardRow>
                                                <styles.CardTitle>Ship to Address Line 2</styles.CardTitle>
                                                <styles.CardDescription>{addr.shipToAddressLine2 || '-'}</styles.CardDescription>
                                            </styles.CardRow>

                                            <styles.CardRow>
                                                <styles.CardTitle>Ship to City</styles.CardTitle>
                                                <styles.CardDescription>{addr.shipToCity || '-'}</styles.CardDescription>
                                            </styles.CardRow>

                                            <styles.CardRow>
                                                <styles.CardTitle>Ship to State</styles.CardTitle>
                                                <styles.CardDescription>{addr.shipToState || '-'}</styles.CardDescription>
                                            </styles.CardRow>

                                            <styles.CardRow>
                                                <styles.CardTitle>Ship to Zip Code</styles.CardTitle>
                                                <styles.CardDescription>{addr.shipToZipCode || '-'}</styles.CardDescription>
                                            </styles.CardRow>

                                            <styles.CardRow>
                                                <styles.CardTitle>Ship to Country</styles.CardTitle>
                                                <styles.CardDescription>{addr.shipToCountry || '-'}</styles.CardDescription>
                                            </styles.CardRow>

                                            <styles.CardRow>
                                                <styles.CardTitle>Shipped From Country Code</styles.CardTitle>
                                                <styles.CardDescription>{addr.shippedFromCountry || '-'}</styles.CardDescription>
                                            </styles.CardRow>

                                            <styles.CardRow>
                                                <styles.CardTitle>Mexico Average Shipping Working Days</styles.CardTitle>
                                                <styles.CardDescription>{addr.mexicoAverageShippingWorkingDays || 0}</styles.CardDescription>
                                            </styles.CardRow>

                                            <styles.CardRow>
                                                <styles.CardTitle>Quebec Average Shipping Working Days</styles.CardTitle>
                                                <styles.CardDescription>{addr.quebecAverageShippingWorkingDays || 0}</styles.CardDescription>
                                            </styles.CardRow>

                                            <styles.CardRow>
                                                <styles.CardTitle>Default Shipping Address for the Location</styles.CardTitle>
                                                <styles.CardDescription>{addr.defaultShippingAddress == true ? 'yes' : 'no'}</styles.CardDescription>
                                            </styles.CardRow>

                                            <styles.CardRow>
                                                <Theme.Center>
                                                    <SlimButton label={'Delete'}
                                                        onClick={(event: any) => onDeleteCardClick(event, addr)} />
                                                </Theme.Center>
                                            </styles.CardRow>
                                    </Card>
                                )
                            }
                        </Theme.Inline>
                    }

                </Theme.Section>

                {
                    inventoryAddressModal &&
                    <SaveInventoryAddressModal
                        customerId={parseInt(customerId)}
                        onSubmit={(address, isNew) => onSubmitAddress(address, isNew)}
                        address={selectedInventoryAddress} />
                }
            </PageWrapper>
        );
    };

    useEffect(() => {
        CustomersStateService.getBreadcrumbCustomer(customerId);
    }, []);

    useEffect(() => {
        if (!isEmpty(customer)) {
            if (customer.inventoryEmail) {
                setEmailValue(customer.inventoryEmail!.split(',').filter(e => e));
            }

            setPoNumber(customer.inventoryPoNumber!);
            setAddresses(customer.customerInventoryShippingAddresses!);
            setInventoryMultiplier(customer.inventoryMultiplier!.toString());
        }

        return () => {
            setEmailValue([]);
        };
    }, [customer]);

    const onDeleteCardClick = (event: any, addr: ICustomerInventoryShippingAddress) => {
        event.stopPropagation();

        let newAddresses = addresses.filter(a => {
            if (addr.id) {
                return a.id !== addr.id;
            }

            if (addr.uuid) {
                return a.uuid !== addr.uuid;
            }
        });

        setAddresses(newAddresses);
    };

    const onEmailTagClose = (email: string) => {
        const emails = emailValue.filter(e => e !== email);

        setEmailValue(emails);
    };

    const onChangeMultiple = (email: string) => {
        if (email) {
            setEmailValue([...emailValue, email]);
        }
    };

    const onAddNewAddressClick = () => {
        CustomersStateService.setCurrentInventoryAddress({} as ICustomerInventoryShippingAddress);
        CustomersStateService.toggleInventoryAddressModal(true);
    };

    const onCardClick = (addr: ICustomerInventoryShippingAddress) => {
        CustomersStateService.setCurrentInventoryAddress(addr);
        CustomersStateService.toggleInventoryAddressModal(true);
    };

    const onSubmitAddress = (address: ICustomerInventoryShippingAddress, isNew: boolean) => {
        if (isNew) {
            setAddresses([...addresses, address]);
        } else {
            let newAddresses = addresses.map(addr => {
                if (addr.id === 0 && addr.uuid === address.uuid) {
                    return address;
                }

                if (addr.id !== 0 && addr.id === address.id) {
                    return address;
                }

                return addr;
            });

            setAddresses([...newAddresses]);
        }

        CustomersStateService.toggleInventoryAddressModal(false);
    };

    const onSaveCustomer = () => {
        const emailFullString = (',' + emailValue.concat(','));

        const saveCustomerReq: ISaveCustomerReq = {
            ...customer,
            inventoryEmail: emailFullString.substring(0, emailFullString.length - 1),
            inventoryPoNumber: poNumber,
            customerInventoryShippingAddresses: addresses.map(addr => {
                delete addr.uuid;

                return addr;
            }),
            isEditMode: true,
            inventoryMultiplier: parseInt(inventoryMultiplier),
        };

        _saveCustomer(saveCustomerReq);
    };

    const isSaveButtonDisabled = () => {
        if (!poNumber || !emailValue || !addresses.length) {
            return true;
        }

        return false;
    };

    return render();
};
