import { IOrderList, IOrderListSaveReq, IOrderListValidationResponse, IUpdateAddressReq } from '../interfaces/order-list';
import { Observable, from } from 'rxjs';

import { FiltersService } from '../../shared/services/filters.service';
import { IApiTableRequest } from '../../shared/interfaces/shared';
import { axios } from '../../shared/services/axios.service';
import download from 'downloadjs';

export class OrderListWebApi {
    public static getOrderListById(id: string): Observable<IOrderList> {
        return from<Promise<IOrderList>>(
            axios.get(`/CustomerPoHeader/${id}`)
                .then(res => res.data),
        );
    }

    public static confirmCustomerOrder(id: number): Observable<null> {
        return from<Promise<null>>(
            axios.post(`/CustomerPoHeader/confirm/${id}`)
                .then(res => res.data),
        );
    }

    public static sendToVisual(id: number): Observable<null> {
        return from<Promise<null>>(
            axios.post(`/CustomerPoHeader/sendToErp/${id}`)
                .then(res => res.data),
        );
    }

    public static validateOrderList(orderListId: string): Observable<IOrderListValidationResponse[]> {
        return from<Promise<IOrderListValidationResponse[]>>(
            axios.get(`/Validation/${orderListId}`)
                .then(res => res.data),
        );
    }

    public static getFilterPoNumbers(text: string): Observable<string[]> {
        return from<Promise<string[]>>(
            axios.get(`/CustomerPoHeader/poNumber/${text}`)
                .then(res => res.data),
        );
    }

    public static getOrderBlob(orderList: IOrderList) {
        const encodeUrl = encodeURIComponent(orderList.emailAttachmentBlobName);

        return from(
            axios.get(`/OriginalOrder/${encodeUrl}`, { responseType: 'blob' })
                .then(res => {
                    const split = orderList.emailAttachmentBlobName.split('.');
                    const type = split[split.length - 1];

                    return {
                        type,
                        blob: res.data,
                    };
                }),
        );
    }

    public static archivePo(id: number) {
        return from(
            axios.post(`/CustomerPoHeader/archive/${id}`)
                .then(res => res.data),
        );
    }

    public static getShippingFees(id: number) {
        return from(
            axios.get(`/CustomerPoHeader/shippingRates/${id}`)
                .then(res => res.data),
        );
    }

    public static updateAddress(req: IUpdateAddressReq) {
        return from (
            axios.put(`/CustomerPoHeader/shipToAddress/${req.id}`, { ...req })
                .then(res => res.data),
        );
    }

}

export const getOriginalOrder = (orderList: IOrderList): Observable<any> => {
    const encodeUrl = encodeURIComponent(orderList.emailAttachmentBlobName);

    return from<Promise<any>>(axios.get<any>(`/OriginalOrder/${encodeUrl}`, { responseType: 'blob' })
        .then(res => {
            const split = orderList.emailAttachmentBlobName.split('.');
            const type = split[split.length - 1];

            return download(res.data, `po-${orderList.poNumber}-original-order.${type}`);
        }));
};

export const getAllOrderLists = (request: IApiTableRequest): Observable<IOrderList[]> => {
    let qs = FiltersService.getApiQuerySearch(request);

    return from<Promise<IOrderList[]>>(
        axios.get<IOrderList[]>(`/CustomerPoHeader?${qs}`)
            .then(res => res.data)
    );
}

export const createOrderList = (orderList: IOrderListSaveReq): Observable<string> =>
    from<Promise<string>>(axios.post<string>('/CustomerPoHeader', { ...orderList })
        .then(res => res.statusText));

export const editOrderList = (orderList: IOrderListSaveReq): Observable<IOrderList> =>
    from<Promise<IOrderList>>(axios.put<IOrderList>(`/CustomerPoHeader/${orderList.id}`, { ...orderList })
        .then(res => res.data));

export const deleteOrderList = (orderList: IOrderList): Observable<string> =>
    from<Promise<string>>(axios.delete<string>(`/CustomerPoHeader/${orderList.id}`)
        .then(res => res.statusText));
