import { AppState } from '../../../shared/interfaces/app.state';
import { Button } from '../../../shared/components/button/button';
import { ButtonTypes } from '../../../shared/components/button/button.utils';
import { ErpOrderInvoiceTableUtils } from './erp-order-invoice-table.utils';
import { GeckoTable } from '../../../shared/components/gecko-table/gecko-table';
import { IErpOrderInvoice } from '../../interfaces/order';
import { ITokenUserRole } from '../../../users/interfaces/token-user-role';
import React from 'react';
import { Theme } from '../../../shared/styles/theme';
import { useSelector } from 'react-redux';

interface Props {
    showExport?: boolean;
    showFilters?: boolean;
    invoices: IErpOrderInvoice[];
}

export const ErpOrderInvoiceTable: React.FC<Props> = (props: Props) => {
    const { showFilters, invoices } = props;

    const tokenUserRole = useSelector<AppState, ITokenUserRole | null>(state => state.users.tokenUserRole);

    const render = () => {
        return (
            <>
                <Theme.SpaceBetween>
                    {
                        showFilters &&
                        <Button type={ButtonTypes.PRIMARY}
                            label={'Filters'}
                            disabled={true} />
                    }
                </Theme.SpaceBetween>

                <GeckoTable dataSource={{ data: invoices }}
                    columns={ErpOrderInvoiceTableUtils.columns(tokenUserRole)}
                    tableId={'erpOrderInvoice'}
                    filters={[]} />
            </>
        );
    };

    return render();
};
