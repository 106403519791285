import * as React from "react";
import * as div from "./login.page.style";

import { ActivityIndicator } from "../../shared/components/activity-indicator/activity-indicator";
import { AppState } from "../../shared/interfaces/app.state";
import { Button } from "../../shared/components/button/button";
import { GeckoLogo } from "../../shared/components/gecko-logo/gecko-logo";
import { IAzureADFunctionProps } from "react-aad-msal";
import { MSAL } from "../../shared/services/msal";
import { Theme } from "../../shared/styles/theme";
import { config } from "../components/auth-provider/config";
import { loginPageTexts } from "../constants/login.page.constants";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

interface Props extends IAzureADFunctionProps { }

export const LoginPage: React.FC<Props> = (_props: Props) => {
    // State
    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    const history = useHistory();

    const token = useSelector<AppState, string>(state => state.users.currentUser.token);

    // Store
    const isAC = config.isAC;

    const render = () => {
        if (isLoading) {
            return (
                <ActivityIndicator />
            );
        }

        return (
            <div.Wrapper>
                {/** Left Container */}
                <div.Left />

                {/** Right Content */}
                <div.Right>

                    {/** Logo */}
                    <GeckoLogo width={123} height={72} isAc={isAC} />

                    {/** Texts */}
                    <div.Texts>
                        <div.TitleText>{loginPageTexts.header}</div.TitleText>

                        <div.DescriptionText>
                            {loginPageTexts.description}
                        </div.DescriptionText>
                    </div.Texts>

                    <Theme.Section>
                        <Theme.Center>
                            {
                                isAC &&
                                <Button label={loginPageTexts.signInButton}
                                    disabled={!!isLoading}
                                    onClick={() => onLoginClick()}
                                />
                            }

                            {
                                !isAC &&
                                <Button label={'Login as a customer'}
                                    overrides={{ root: 'margin-left: 20px;' }}
                                    onClick={() => onCustomerLoginClick()} />
                            }
                        </Theme.Center>
                    </Theme.Section>

                    {isLoading !== null && isLoading && <ActivityIndicator />}
                </div.Right>
            </div.Wrapper>
        );
    };

    React.useEffect(() => {
        setInterval(() => {
            const appLoading = localStorage.getItem('appLoading');

            if (appLoading === 'true') {
                setIsLoading(true);
            } else {
                setIsLoading(false);
            }
        }, 1000);
    }, []);

    React.useEffect(() => {
        if (token) {
            history.push('/');
        }
    }, [token]);

    const onLoginClick = () => {

        try {
            localStorage.setItem('appLoading', JSON.stringify(true));

            MSAL.loginRedirect({ scopes: config.scopes });

            localStorage.setItem('provider', 'b2b');
        } catch (err) {
            console.error(err);
        }
    };

    const onCustomerLoginClick = () => {
        try {
            if (!isAC) {
                MSAL.loginRedirect({ scopes: ['https://geckob2cdev.onmicrosoft.com/api/General.Scope' ]});
                localStorage.setItem('provider', 'b2c');
            }
        } catch (err) {
            console.error(err);
        }
    };

    return render();
};
