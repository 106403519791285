import * as styles from './notification-events-form.style';

import { INotificationEventPreferencesReq, INotificationPreference, INotificationPreferenceReq } from '../../interfaces/notification-preference';
import React, { useEffect, useState } from 'react';

import { ActivityIndicator } from '../../../shared/components/activity-indicator/activity-indicator';
import { AppState } from '../../../shared/interfaces/app.state';
import { Button } from '../../../shared/components/button/button';
import { ButtonTypes } from '../../../shared/components/button/button.utils';
import { INotificationChannel } from '../../interfaces/notification-channel';
import { INotificationEvent } from '../../interfaces/notification-event';
import { ITokenUserRole } from '../../../users/interfaces/token-user-role';
import { Input } from '../../../shared/components/input/input';
import { NotificationsStateService } from '../../services/notifications-state.service';
import { ROLES } from '../../../users/interfaces/roles';
import { Radio } from 'antd';
import { Theme } from '../../../shared/styles/theme';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

interface Props {
    events: INotificationEvent[];
    channels: INotificationChannel[];
    currentPreference: INotificationPreference;
    customerId: string;
}

export const NotificationEventsForm: React.FC<Props> = (props: Props) => {
    const { events, channels, currentPreference, customerId } = props;

    const getInitGroupNotification = () => {
        if (currentPreference.groupNotification) {
            return 1;
        }

        return 2;
    };

    const tokenUserRole = useSelector<AppState, ITokenUserRole | null>(state => state.users.tokenUserRole);

    const [selection, setSelection] = useState<any>({});
    const [phoneNumber, setPhoneNumber] = useState<string>(currentPreference.phoneNumber || '');
    const [isLoading, setIsLoading] = useState(true);
    const [checkboxes, setCheckboxes] = useState<any>({});
    const [groupNotification, setGroupNotification] = useState<number>(getInitGroupNotification());
    const [displayPhoneNumber, setDisplayPhoneNumber] = useState(false);

    const render = () => {
        if (isLoading) {
            return <ActivityIndicator />;
        }

        return (
            <Theme.Section>
                {
                    !!events.length &&
                    events.map(event =>
                        <styles.EventWrapper key={event.id}>

                            {event.name}

                            <styles.ChannelsWrapper>
                                <Radio.Group onChange={e => setRadioValue(e.target.value, event.id)}
                                    value={selection[event.id]}
                                    defaultValue={selection[event.id]}>
                                    {
                                        !!channels.length &&
                                        channels.map(channel =>
                                            <Radio key={channel.id} value={channel.id}>
                                                {channel.name}
                                            </Radio>
                                        )
                                    }
                                </Radio.Group>

                            </styles.ChannelsWrapper>

                        </styles.EventWrapper>
                    )
                }

                <Theme.Section style={{ marginTop: 20 }}>
                    <Radio.Group onChange={e => setGroupNotification(e.target.value)}
                        value={groupNotification}>
                        <Radio value={1}>Group Notifications</Radio>

                        <Radio value={2}>Send Notifications in real time</Radio>
                    </Radio.Group>
                </Theme.Section>

                {
                    (tokenUserRole && (tokenUserRole.role !== ROLES.Admin && tokenUserRole.role !== ROLES.SOA) && displayPhoneNumber) &&
                    <Theme.Section style={{ width: 420 }}>
                        <Input value={phoneNumber}
                            label={'International Phone Number'}
                            onChange={phoneNumber => setPhoneNumber(phoneNumber)} />

                        {
                            !phoneNumber &&
                            <Theme.Label>For receiving SMS notifications, please update your phone number</Theme.Label>
                        }
                    </Theme.Section>
                }

                <Button type={ButtonTypes.PRIMARY}
                    label={isEmpty(currentPreference) ? 'Save' : 'Update'}
                    overrides={{ root: 'margin-top: 20px' }}
                    onClick={() => onSaveClick()} />
            </Theme.Section>
        );
    };

    useEffect(() => {
        let obj: { [key: number]: number } = {};

        events.forEach(e => {
            if (isEmpty(currentPreference) && channels) {
                obj[e.id] = channels[0].id;
            } else {
                currentPreference.notificationEventPreferences.forEach(p => {
                    if (e.id === p.notificationEventId) {
                        if (p.notificationChannelId === 3) {
                            setDisplayPhoneNumber(true);
                        }

                        obj[e.id] = p.notificationChannelId;
                    }
                });
            }
        });

        setSelection(obj);

        if (events && channels) {
            setIsLoading(false);
        }
    }, [events, channels, currentPreference]);

    useEffect(() => {
        if (currentPreference.phoneNumber && !phoneNumber) {
            setPhoneNumber(currentPreference.phoneNumber);
        }
    }, [currentPreference.phoneNumber]);

    useEffect(() => {
        let checkboxesObj: { [key: number]: boolean } = {};

        events.forEach(e => {
            checkboxesObj[e.id] = true;
        });

        setCheckboxes(checkboxesObj);
    }, [events]);

    const setRadioValue = (value: number, eventId: number) => {
        let newSelection = { ...selection };

        newSelection[eventId] = value;

        if (newSelection[eventId] === 3) {
            setDisplayPhoneNumber(true);
        } else {
            let isSmsSelected = false;

            // check if there are any other SMS selections
            Object.keys(newSelection).forEach(key => {
                if (newSelection[key] === 3) {
                    isSmsSelected = true;
                }
            });

            if (isSmsSelected) {
                setDisplayPhoneNumber(true);
            } else {
                setDisplayPhoneNumber(false);
            }
        }

        setSelection(newSelection);
    };

    const onSaveClick = () => {
        let obj: INotificationPreferenceReq = {
            externalCustomerId: customerId,
            groupNotification: groupNotification === 1 ? true : false,
            phoneNumber: '',
            notificationEventPreferences: [],
        };

        let eventPreferencesArr: INotificationEventPreferencesReq[] = [];

        Object.keys(checkboxes).forEach(key => {
            if (checkboxes[key]) {
                // it means that is selected
                let eventPreferences: INotificationEventPreferencesReq = {
                    notificationEventId: parseInt(key),
                    notificationChannelId: selection[key],
                };

                eventPreferencesArr = [...eventPreferencesArr, eventPreferences];
            }
        });

        obj.notificationEventPreferences = eventPreferencesArr;

        if (currentPreference) {
            obj.id = currentPreference.id;
            obj.notificationEventPreferences = obj.notificationEventPreferences
                .map(p => ({ ...p, notificationPreferenceId: currentPreference.id }));
        }

        if (phoneNumber) {
            obj.phoneNumber = phoneNumber;
        }

        NotificationsStateService.saveNotificationPreference(obj);
    };

    return render();
};
