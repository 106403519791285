import React, { useEffect, useState } from 'react';

import { ActivityIndicator } from '../../shared/components/activity-indicator/activity-indicator';
import { AppState } from '../../shared/interfaces/app.state';
import { CustomersStateService } from '../../customers/services/customers.state-service';
import { Dropdown } from '../../shared/components/dropdown/dropdown';
import { IAzureADFunctionProps } from 'react-aad-msal';
import { INotificationChannel } from '../interfaces/notification-channel';
import { INotificationEvent } from '../interfaces/notification-event';
import { INotificationPreference } from '../interfaces/notification-preference';
import { NotificationEventsForm } from '../components/notification-events-form/notification-events-form';
import { NotificationsStateService } from '../services/notifications-state.service';
import { PageTitle } from '../../shared/components/page-title/page-title';
import { SectionTitle } from '../../shared/components/section-title/section-title';
import { Theme } from '../../shared/styles/theme';
import { useSelector } from 'react-redux';

interface Props extends IAzureADFunctionProps {}

export const NotificationPreferencesPage: React.FC<Props> = (_props: Props) => {
    const customerIds = useSelector<AppState, string[]>(state => state.customers.erpCustomerIds);
    const customerIdsLoading = useSelector<AppState, boolean>(state => state.customers.erpCustomerIdsLoading);
    const channels = useSelector<AppState, INotificationChannel[]>(state => state.notifications.channels);
    const events = useSelector<AppState, INotificationEvent[]>(state => state.notifications.events);
    const currentPreference = useSelector<AppState, INotificationPreference>(state => state.notifications.currentPreference);
    const currentPreferenceLoading = useSelector<AppState, boolean>(state => state.notifications.currentPreferenceLoading);

    const [customerId, setCustomerId] = useState('');
    const [showForm, setShowForm] = useState(false);

    /**
     * {
  "externalCustomerId": "106POO01",
  "groupNotification": true,
  "notificationEventPreferences": [
    {
      "notificationEventId": 1,
      "notificationChannelId": 1,
    }
  ]
}
     */

    const render = () => {
        if (customerIdsLoading) {
            return <ActivityIndicator />;
        }

        return (
            <Theme.PageWrapper>
                <PageTitle text={'Notifications Preferences'} />

                <Theme.Section width='300px'>
                    <SectionTitle text={'Select customer'} />

                    <Dropdown value={customerId}
                        keyToShow={''}
                        options={customerIds}
                        allowClear={true}
                        onChange={customerId => setCustomerId(customerId)} />

                </Theme.Section>

                {
                    currentPreferenceLoading &&
                    <ActivityIndicator />
                }

                {
                    (showForm && !currentPreferenceLoading) &&
                    <Theme.Section>
                        <SectionTitle text={'Set Notifications Events'} />

                        <NotificationEventsForm events={events}
                            channels={channels}
                            currentPreference={currentPreference}
                            customerId={customerId} />
                    </Theme.Section>
                }
            </Theme.PageWrapper>
        );
    };

    useEffect(() => {
        CustomersStateService.getErpCustomerIds();
        NotificationsStateService.getNotificationChannels();
        NotificationsStateService.getNotificationEvents();
    }, []);

    useEffect(() => {
        if (customerIds.length === 1) {
            setCustomerId(customerIds[0]);
        }
    }, [customerIds]);

    useEffect(() => {
        if (customerId) {
            setShowForm(true);
            NotificationsStateService.getNotificationPreferenceByCustomerId(customerId);
        } else {
            setShowForm(false);
        }
    }, [customerId]);

    return render();
};
