import { _setDeletedDayOff, _toggleCreatetDayOffModal, _toggleDeleteDayOffModal } from "../services/day-off-state.service";

import { ColumnsType } from "antd/lib/table";
import { IRowMenu } from "../../shared/interfaces/row-menu";
import { IDayOff } from "../interfaces/day-off";

export class DayOffPageUtils {
    public static tableColumns: ColumnsType<IDayOff> = [{
        title: 'Site Id',
        dataIndex: 'siteId'
    }, {
        title: 'Day',
        dataIndex: 'day'
    }];

    public static rowMenu(dayOff: IDayOff): IRowMenu[] {
        return [
           /* {
            label: 'Update DayOff',
            onClick: () => this.handleEdit(dayOff),
        }, */
        {
            label: 'Delete DayOff',
            onClick: () => this.handleDelete(dayOff),
        }];
    }

    private static handleEdit(dayOff: IDayOff) {
        _toggleCreatetDayOffModal(true);
    }

    private static handleDelete(dayOff: IDayOff) {
        _setDeletedDayOff(dayOff);
        _toggleDeleteDayOffModal(true);
    }
}