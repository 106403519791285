import * as OrderLineService from '../services/order-line.state-service';
import * as React from 'react';
import * as styled from './order-line.page.style';

import { IFreightPref, IIncoterm } from '../../customers/interfaces/customer';
import { IOrderBlob, IOrderList, ISortColumn, IUpdateAddressReq } from '../../order-list/interfaces/order-list';
import { OrderLineContainer, TitleContainer } from './order-line.page.style';
import { OrderListStateService, _createOrderList, _editOrderList, _toggleCreateOrderListModal } from '../../order-list/services/order-list-state.service';
import { useHistory, useParams } from 'react-router';

import { ActivityIndicator } from '../../shared/components/activity-indicator/activity-indicator';
import { AppState } from '../../shared/interfaces/app.state';
import { AuthService } from '../../auth/services/auth.service';
import { BackendError } from '../../shared/components/backend-error/backend-error';
import { ButtonTypes } from '../../shared/components/button/button.utils';
import { CompaniesStateService } from '../../companies/services/companies.state-service';
import { ConfirmationModal } from '../../shared/components/confirmation-modal/confirmation-modal';
import { CreateOrderLineModal } from '../components/create-order-line-modal/create-order-line-modal'
import { CustomersStateService } from '../../customers/services/customers.state-service';
import { Button as GeckoButton } from '../../shared/components/button/button';
import { GeckoTable } from '../../shared/components/gecko-table/gecko-table';
import { IAzureADFunctionProps } from 'react-aad-msal';
import { ICountryCode } from '../../shared/interfaces/country-code';
import { IOrderLine } from '../interfaces/order-line';
import { IRowMenu } from '../../shared/interfaces/row-menu';
import { IShipVia } from '../../shared/interfaces/ship-via';
import { ISiteId } from '../../shared/interfaces/site-id';
import { OrderLineFooter } from '../components/order-line-footer/order-line-footer';
import { OrderLinePageUtils } from './order-line-page.utils';
import { OrderListDocument } from '../../order-list/components/order-list-document/order-list-document';
import { OrderListTableUtils } from '../../order-list/components/order-list-table/order-list-table.utils';
import { PageTitle } from '../../shared/components/page-title/page-title';
import { PageWrapper } from '../../shared/styles/page-wrapper';
import { SaveHTMLOrderListModal } from '../../order-list/components/save-order-list-modal/save-html-order-list-modal';
import { SaveOrderListModal } from '../../order-list/components/save-order-list-modal/save-order-list-modal';
import { SectionTitle } from '../../shared/components/section-title/section-title';
import { SharedStateService } from '../../shared/services/shared.state-service';
import { ShippingFeesModal } from '../../order-list/components/shipping-fees-modal/shipping-fees-modal';
import { UpdateAddressModal } from '../../order-list/components/update-address-modal/update-address-modal';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

interface IUrlParams {
    companyId: string;
    customerId: string;
    orderListId: string;
}
interface Props extends IAzureADFunctionProps { }

export const OrderLinePage: React.FC<Props> = (props: Props) => {
    const urlParams: IUrlParams = useParams();

    const { orderListId, companyId, customerId } = urlParams;

    const [showDoc, setShowDoc] = React.useState(false);
    const [actionDisabled, setActionDisabled] = React.useState(false);

    const history = useHistory();

    // store
    const isLoading = useSelector<AppState, boolean>(state => state.orderLine.orderLineLoading);
    const orderLines = useSelector<AppState, IOrderLine[]>(state => state.orderLine.orderLines);
    const deleteOrderLine = useSelector<AppState, boolean>(state => state.orderLine.modals.delete);
    const createOrderLine = useSelector<AppState, boolean>(state => state.orderLine.modals.save);
    const archiveModal = useSelector<AppState, boolean>(state => state.orderLine.modals.archiveOrder);
    const orderLine = useSelector<AppState, IOrderLine>(state => state.orderLine.orderLine);
    const saveOrderLineLoading = useSelector<AppState, boolean>(state => state.orderLine.saveOrderLine.loading);
    const editOrderLineLoading = useSelector<AppState, boolean>(state => state.orderLine.editOrderLine.loading);
    const orderList = useSelector<AppState, IOrderList>(state => state.shared.breadcrumbs.orderList);
    const confirmOrderLoading = useSelector<AppState, boolean>(state => state.orderList.confirmCustomerOrder.loading);
    const editOrderList = useSelector<AppState, boolean>(state => state.orderList.modals.create);
    const shipVias = useSelector<AppState, IShipVia[]>(state => state.shared.shipVias);
    const orderListValidationLoading = useSelector<AppState, boolean>(state => state.orderList.validationLoading);
    const partIds = useSelector<AppState, string[]>(state => state.orderLine.partIds);
    const partDescriptions = useSelector<AppState, string[]>(state => state.orderLine.partDescriptions);
    const viewMode = useSelector<AppState, boolean>(state => state.orderList.viewMode);
    const viewModeOrderLine = useSelector<AppState, boolean>(state => state.orderLine.viewMode);
    const siteIDs = useSelector<AppState, ISiteId[]>(state => state.shared.siteIds);
    const blob = useSelector<AppState, IOrderBlob>(state => state.orderList.orderBlob);
    const shippingFeesModal = useSelector<AppState, boolean>(state => state.orderList.modals.shippingFees);
    const validateOrderLoading = useSelector<AppState, boolean>(state => state.orderList.sendToErp.loading);
    const countryCodes = useSelector<AppState, ICountryCode[]>(state => state.shared.countryCodes);
    const freights = useSelector<AppState, IFreightPref[]>(state => state.customers.freights);
    const incoterms = useSelector<AppState, IIncoterm[]>(state => state.customers.incoterms);
    const updateAddressModal = useSelector<AppState, boolean>(state => state.orderList.modals.updateAddress);

    const render = () => {
        if (isLoading || confirmOrderLoading || validateOrderLoading) {
            return <ActivityIndicator />;
        }

        return (
            <PageWrapper>
                <TitleContainer>
                    <PageTitle text={'Purchase Order Lines'} />

                    <GeckoButton type={ButtonTypes.PRIMARY}
                        label={'Back to Purchase Order List'}
                        onClick={() => onBackClick()} />
                </TitleContainer>

                <OrderLineContainer>
                    <SectionTitle text={'PO Header'} />

                    <GeckoTable dataSource={{ data: [orderList] }}
                        showPagination={false}
                        showRowMenu={true}
                        showSorting={false}
                        filters={[]}
                        tableId='order-lists-from-line'
                        rowMenu={(row: any) => rowMenuOrderList(row)}
                        columns={OrderListTableUtils.columns(true, {} as ISortColumn)} />
                </OrderLineContainer>

                <OrderLineContainer>
                    <SectionTitle text={'PO Lines'} />

                    <styled.PoLinesContainer className='po-lines-container'>

                        <GeckoTable dataSource={{ data: orderLines }}
                            showFilters={false}
                            filters={[]}
                            tableId={'order-line'}
                            actions={{ add: !actionDisabled, showDoc: true }}
                            onAddClick={() => OrderLineService._toggleOrderLineSaveModal(true)}
                            onShowDocClick={() => setShowDoc(!showDoc)}
                            showRowMenu={true}
                            rowMenu={(row: IOrderLine) => rowMenuOrderLine(row)}
                            columns={OrderLinePageUtils.columns(orderList)}
                            />

                        {
                            showDoc &&
                            <OrderListDocument blob={blob} orderList={orderList} />
                        }
                    </styled.PoLinesContainer>

                </OrderLineContainer>

                <OrderLineFooter orderList={orderList}
                    confirmButtonDisabled={!orderLines.length} />

                {
                    deleteOrderLine &&
                    <ConfirmationModal onClose={() => onDeleteClick()}
                        onConfirm={() => OrderLineService._deleteOrderLine(orderLine)} />
                }

                {
                    archiveModal &&
                    <ConfirmationModal onClose={() => OrderLineService._toggleArchiveOrderModal(false)}
                        title={'Are you sure you want to archive this PO?'}
                        confirmationButtonText={'Archive'}
                        onConfirm={() => OrderListStateService.archivePo(orderList.id)} />
                }

                {
                    createOrderLine &&
                    <CreateOrderLineModal
                        orderListValidationLoading={orderListValidationLoading}
                        onSubmitEdit={OrderLineService._editOrderLine}
                        currentOrderList={orderList}
                        currentOrderLine={orderLine}
                        loading={editOrderLineLoading ? editOrderLineLoading : saveOrderLineLoading}
                        onSubmit={OrderLineService._saveOrderLine}
                        headerId={parseInt(urlParams.orderListId)}
                        viewMode={viewModeOrderLine}
                        onClose={() => {
                            OrderLineService._resetCurrentOrderLine();
                            OrderLineService._toggleOrderLineSaveModal(false)
                        }}
                        partIds={partIds}
                        siteIds={siteIDs}
                        partDescriptions={partDescriptions}
                        onValidateClick={() => onValidateClick()}
                    />
                }

                {
                    editOrderList &&
                    renderSaveModal()
                }

                {
                    shippingFeesModal &&
                    <ShippingFeesModal orderId={orderListId} />
                }

                {
                    updateAddressModal &&
                    <UpdateAddressModal onClose={() => OrderListStateService.toggleUpdateAddressModal(false)}
                        onSubmit={req => onUpdateAddressSubmit(req)}
                        countryCodes={countryCodes}
                        orderList={orderList} />
                }

                <BackendError />
            </PageWrapper>
        );
    };

    React.useEffect(() => {
        AuthService.checkAuth(history);

        CustomersStateService.getFreights();
        CustomersStateService.getIncoterm();
        SharedStateService.getShipVias();
        SharedStateService.getSiteIds();
        SharedStateService.getCountryCodes();
    }, []);

    React.useEffect(() => {
        if (!isEmpty(orderList)) {
            OrderListStateService.getOrderBlob(orderList);

            handleOrderStatus(orderList.orderStatus);
        }
    }, [orderList]);

    React.useEffect(() => {
        if (orderListId) {
            OrderLineService._getOrderLines(orderListId);
            OrderListStateService.getBreadcrumbOrderList(orderListId);
        }
    }, [orderListId]);

    React.useEffect(() => {
        if (companyId) {
            CompaniesStateService.getBreadcrumbCompany(companyId);
        }

        if (customerId) {
            CustomersStateService.getBreadcrumbCustomer(customerId);
        }

    }, [companyId, customerId]);

    React.useEffect(() => {
        if (orderList && orderList.externalCustomerId) {
            OrderLineService._getPartDescriptions(orderList.externalCustomerId);
            OrderLineService._getPartIds(orderList.externalCustomerId);
        }

    }, [orderList.externalCustomerId]);

    const rowMenuOrderList = (orderList: IOrderList): IRowMenu[] => [{
        label: 'Update Order',
        onClick: () => OrderListTableUtils.handleEdit(orderList),
        disabled: orderList.orderStatus === 4 || orderList.orderStatus === 7,
    }, {
        label: 'View Order',
        onClick: () => OrderListTableUtils.handleView(orderList),
        disabled: orderList.orderStatus !== 4 && orderList.orderStatus !== 7,
    }, {
        label: 'Update address',
        onClick: () => OrderListTableUtils.handleUpdateAddress(orderList),
        hide: orderList.orderStatus !== 4,
    }];

    const rowMenuOrderLine = (orderLine: IOrderLine): IRowMenu[] => {
        return [{
            label: 'Update Order Line',
            onClick: () => OrderLinePageUtils.onEditClick(orderLine),
            disabled: actionDisabled || !!orderLine.serviceChargeId,
        }, {
            label: 'Delete Order Line',
            onClick: () => OrderLinePageUtils.onDeleteClick(orderLine),
            disabled: actionDisabled,
        }, {
            label: 'View Order Line',
            onClick: () => OrderLinePageUtils.handleView(orderLine),
            disabled: !!orderLine.serviceChargeId,
        }];
    };

    const renderSaveModal = () => {
        if (orderList && orderList.typeId === 1) {
            return (
                <SaveHTMLOrderListModal
                    orderListValidationLoading={orderListValidationLoading}
                    onSubmit={_createOrderList}
                    errors={orderList.errorsList}
                    currentOrderList={orderList && orderList}
                    isEditMode={true}
                    viewMode={viewMode}
                    onSubmitEdit={_editOrderList}
                    onClose={() => handleSaveOrderListModalClose()}
                    loading={false}
                    siteIds={siteIDs}
                    shipVias={shipVias}
                    countryCodes={countryCodes}
                    externalCustomerId={orderList.externalCustomerId}
                    onValidateClick={() => onValidateClick()}
                />
            );
        }

        return (
            <SaveOrderListModal
                orderListValidationLoading={orderListValidationLoading}
                onSubmit={_createOrderList}
                errors={orderList.errorsList}
                currentOrderList={orderList && orderList}
                isEditMode={true}
                shipVias={shipVias}
                viewMode={viewMode}
                onSubmitEdit={_editOrderList}
                onClose={() => handleSaveOrderListModalClose()}
                loading={false}
                siteIds={siteIDs}
                countryCodes={countryCodes}
                incoterms={incoterms}
                externalCustomerId={orderList.externalCustomerId}
                fobs={freights}
                onValidateClick={() => onValidateClick()}
            />
        );
    };

    const handleSaveOrderListModalClose = () => {
        _toggleCreateOrderListModal(false);
        OrderListStateService.setViewMode(false);
    };

    const onValidateClick = () => {
        const { id } = orderList;

        OrderListStateService.toggleValidationLoading(true);
        OrderListStateService.validateOrderList(id.toString());
    };

    const onDeleteClick = () => {
        OrderLineService._resetCurrentOrderLine();
        OrderLineService._toggleOrderLineDeleteModal(false);
    };

    const onBackClick = () => {
        history.goBack();
    };

    /** Block order line edit if status is CREATED or ERP Confirmation */
    const handleOrderStatus = (status: number) => {
        if (status === 4 || status === 7 || status === 6) {
            // block
            setActionDisabled(true);
        } else {
            setActionDisabled(false);
        }
    };

    const onUpdateAddressSubmit = (req: IUpdateAddressReq) => {
        OrderListStateService.updateAddress(req);
    };

    return render();
};
