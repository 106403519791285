import { Action } from "../../shared/interfaces/shared"
import { FoeActions } from "./foe.actions"
import { IFoeState } from "../interfaces/foe-state"
import { foeInitState } from "./foe.init-state"

export function foeReducer(state: IFoeState = foeInitState, action: Action<any>): IFoeState {
    switch(action.type) {
        case FoeActions.CREATE_FOE_REQ: {
            return {
                ...state,
                loading: true,
            }
        }

        case FoeActions.CREATE_FOE_OK: {
            return {
                ...state,
                loading: false,
                error: '',
                response: action.payload,
            };
        }

        case FoeActions.CREATE_FOE_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload,
                response: '',
            };
        }

        default:
            return state;
    }
}