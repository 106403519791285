import { IAttachRoleReq, IRole, IUserRole } from '../interfaces/roles';
import React, { useEffect } from 'react';

import { AppState } from '../../shared/interfaces/app.state';
import { AttachRoleModal } from '../components/attach-role-modal/attach-role-modal';
import { AuthService } from '../../auth/services/auth.service';
import { CompaniesStateService } from '../../companies/services/companies.state-service';
import { GeckoTable } from '../../shared/components/gecko-table/gecko-table';
import { IApiTableRequest } from '../../shared/interfaces/shared';
import { IPagination } from '../../shared/interfaces/pagination';
import { IRowMenu } from '../../shared/interfaces/row-menu';
import { PageTitle } from '../../shared/components/page-title/page-title';
import { SortingOrder } from '../../shared/interfaces/sorting';
import { Theme } from '../../shared/styles/theme';
import { UserRolesPageUtils } from './user-roles.page.utils';
import { UsersStateService } from '../services/users-state.service';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

interface Props {}

export const UserRolesPage: React.FC<Props> = (_props: Props) => {
    const history = useHistory();

    const userRoles = useSelector<AppState, IPagination<IUserRole>>(state => state.users.userRoles);
    const roles = useSelector<AppState, IRole[]>(state => state.users.roles);
    const currentUserRole = useSelector<AppState, IUserRole>(state => state.users.currentUserRole);
    const changeRoleModal = useSelector<AppState, boolean>(state => state.users.modals.changeRole);
    const isLoading = useSelector<AppState, boolean>(state => state.users.isLoading);
    const erpCompanies = useSelector<AppState, string[]>(state => state.companies.erpCompanies);

    const initUserRolesConfig: IApiTableRequest = {
        pageSize: 20,
        pageNumber: 1,
        sortColumn: 'RequestDate',
        sortOrder: SortingOrder.Desc,
        filters: [],
    };

    const render = () => {
        return (
            <Theme.PageWrapper>
                <PageTitle text={'Access - User Roles'} />

                <GeckoTable dataSource={userRoles}
                    isLoading={isLoading}
                    columns={UserRolesPageUtils.columns(roles)}
                    actions={{ refresh: false }}
                    showRowMenu={true}
                    rowMenu={row => menuItems(row)}
                    showPagination={true}
                    filters={[]}
                    apiCall={apiCall}
                    initConfig={initUserRolesConfig}
                    tableId={'userRolesTable'} />

                {
                    (changeRoleModal && !isEmpty(currentUserRole)) &&
                    <AttachRoleModal currentUserRole={currentUserRole}
                        roles={roles}
                        companies={erpCompanies}
                        handleCancel={() => UsersStateService.toggleChangeUserRoleModal(false)}
                        handleSubmit={(req: IAttachRoleReq) =>
                            handleModalSubmit(req)
                        } />
                }

            </Theme.PageWrapper>
        );
    };

    useEffect(() => {
        AuthService.checkAuth(history);

        UsersStateService.getRoles();
        CompaniesStateService.getErpCompanies();
    }, []);

    const handleModalSubmit = (req: IAttachRoleReq) => {
        UsersStateService.attachRole(req);
    };

    const apiCall = (params: IApiTableRequest) => {
        UsersStateService.getUserRoles(params);
    };

    const menuItems = (userRole: IUserRole): IRowMenu[] => {
        return [{
            label: 'Update user',
            onClick: () => UserRolesPageUtils.onEditClick(userRole),
        }];
    };

    return render();
};
