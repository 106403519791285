import { FoeActions } from "../state/foe.actions";
import { IFoeOrder } from "../interfaces/foe";
import { store } from "../../shared/services/state.service";

export class FoeStateService {
    public static createFoe(foe: IFoeOrder) {
        store.dispatch(
            FoeActions.createForReq(foe),
        );
    }
}
