import styled from 'styled-components';

export const CardRow = styled.div`
    margin-bottom: 5px;
`;

export const CardTitle = styled.div`
    font-weight: 600;
    font-size: 14px;
`;

export const CardDescription = styled.div`
    font-weight: 300;
    font-size: 12px;
`;
