import 'jspdf-autotable';

import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as styles from './erp-shipping-info-table.style';

import { ColumnsType } from "antd/lib/table";
import { IErpShipper } from "../../interfaces/order";
import { ITokenUserRole } from '../../../users/interfaces/token-user-role';
import { OrdersStateService } from '../../services/orders-state.service';
import { ROLES } from '../../../users/interfaces/roles';
import React from 'react';
import { Theme } from '../../../shared/styles/theme';
import moment from 'moment';
import { store } from '../../../shared/services/state.service';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
export class ErpShippingInfoTableUtils {
    public static readonly shipperColumns = (tokenUserRole: ITokenUserRole | null): ColumnsType<IErpShipper> => ([{
        title: <styles.HeaderTitle>Packlist</styles.HeaderTitle>,
        key: 'orderDetails_packlist',
        dataIndex: 'packlistId',
        render: (_text: string, shipper: IErpShipper) =>
            <Theme.Clickable onClick={() => ErpShippingInfoTableUtils.downloadShippingInfo(shipper)}>
                {shipper.packlistId}
            </Theme.Clickable>
    }, {
        title: <styles.HeaderTitle>Ship Date</styles.HeaderTitle>,
        key: 'orderDetails_shipDate',
        dataIndex: 'shippedDate',
        render: (_text: string, shipper: IErpShipper) =>
            moment(shipper.shippedDate).format('MM/DD/YYYY'),
    }, {
        title: <styles.HeaderTitle>Ship From_plmftl</styles.HeaderTitle>,
        key: 'orderDetails_shipFrom',
        dataIndex: 'shippedFrom',
    }, {
        title: <styles.HeaderTitle>VIA</styles.HeaderTitle>,
        key: 'orderDetails_via',
        dataIndex: 'shipVia',
    }, {
        title: <styles.HeaderTitle>Waybill</styles.HeaderTitle>,
        key: 'orderDetails_wayBill',
        dataIndex: 'wayBill',
    }, {
        title: <styles.HeaderTitle>Tracking URL</styles.HeaderTitle>,
        key: 'orderDetails_trackingUrl',
        dataIndex: 'trackingUrl',
        width: 200,
        render: (_text: string, shipper: IErpShipper) =>
            <>
                <a href={shipper.trackingUrl} rel='noopener noreferrer' target='_blank'>
                    {ErpShippingInfoTableUtils.trimTrackingUrl(shipper.trackingUrl)}
                </a>
            </>
    }, {
        title: <styles.HeaderTitle>Invoice</styles.HeaderTitle>,
        key: 'orderDetails_invoiceId',
        render: (_text: string, shipper: IErpShipper) =>
        <>
            {
                (tokenUserRole && tokenUserRole.role === ROLES.Client) &&
                shipper.invoiceId
            }

            {
                (tokenUserRole && tokenUserRole.role !== ROLES.Client) &&
                <Theme.Clickable onClick={() => ErpShippingInfoTableUtils.onDownloadInvoiceClick(shipper)}>
                    {shipper.invoiceId}
                </Theme.Clickable>
            }
        </>
    }]);

    private static trimTrackingUrl(text: string) {
        if (text && text.length > 50) {
            let newText = text.slice(0, 50);
            newText += '...';

            return newText;
        }

        return text;
    }

    private static onDownloadInvoiceClick(shipper: IErpShipper) {
        const order = store.getState().orders.currentCompleteOrder;

        OrdersStateService.downloadInvoice({
            invoiceId: shipper.invoiceId,
            customerId: order.customerId,
        });
    }

    private static downloadShippingInfo(shipper: IErpShipper) {
        const order = store.getState().orders.currentCompleteOrder;
       
        const detailsKeys = ['packlistId', 'customerPartId', 'partId', 'partDescription', 'orderId', 'shippedQty', 'unitPrice'];
        const detailsKeysWith2Digit = ['unitPrice'];
        const detailsBody = shipper.erpShipperLines.map((detail: any) => {
            let arr: any[] = [];

            detailsKeys.forEach(key => {
                let columnValue = detail[key];
                if (detailsKeysWith2Digit.includes(key))
                    columnValue = detail[key].toFixed(2);
                arr = [
                    ...arr,
                   { text: columnValue, style: 'tableCell' },
                   
                ];
            });

            return arr;
        });

        const detailsHead = [
            { text: 'Packlist', style: 'tableHeader'  },
            { text: 'Customer Part ID', style: 'tableHeader'  },
            { text: 'Part ID', style: 'tableHeader'  },
            { text: 'Part Description', style: 'tableHeader'  },
            { text: 'Order', style: 'tableHeader'  },
            { text: 'Qty Ship', style: 'tableHeader'  },
            { text: 'Unit Price', style: 'tableHeader'  },
          
        ];

        const docDefinition = {
            pageOrientation: 'portrait',
            content: [
                { image: 'logo', width: 100, height: 75 },
                { text: `Shipping information - Order number ${shipper.orderId}${order.poNumber ?' - ' + order.poNumber : ''} `, style: 'header' },
                { text: `Packlist: ${shipper.packlistId}`, style: 'content' },
                { text: `Shipped Date: ${shipper.shippedDate ? moment(shipper.shippedDate).format('MM-DD-YYYY') : '-'}`, style: 'content' },
                { text: `Shipped From: ${shipper.shippedFrom ? shipper.shippedFrom : '-'}`, style: 'content' },
                { text: `Ship Via: ${shipper.shipVia ? shipper.shipVia : '-'}`, style: 'content' },
                { text: `Way Bill: ${shipper.wayBill ? shipper.wayBill : '-'}`, style: 'content' },
                { text: `Tracking URL: ${shipper.trackingUrl ? shipper.trackingUrl : '-'}`, style: 'content' },
                { text: `Invoice: ${shipper.invoiceId ? shipper.invoiceId : '-'}`, style: 'content' },
                { text: `Shipping information - Packlist details`, style: 'header2' },
              

                {
                    style: 'tableExample',
                    table: {
                        widths: ['auto', 'auto', 'auto', 'auto', 'auto' ,'auto', 'auto'],
                        headerRows: 1,
                        body: [
                            detailsHead,
                            ...detailsBody,
                        ],
                    },
                    layout: 'lightHorizontalLines',
                },
            ],
            images: {
                logo: 'https://images.squarespace-cdn.com/content/v1/54885a1fe4b05708750a9d40/1614977544757-W45C8NT9414OHKGHFRAI/Logo_Gecko_Produit_RGB.png?format=1500w',
            },
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 20, 0, 10],
                },
                header2: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 50, 0, 10],
                },
                content: {
                    fontSize: 12,
                },
                emptySpace: {
                    margin: [0, 50, 0, 0],
                }
            },
        };
          
        pdfMake.createPdf(docDefinition as any).download(`${order.poNumber}_${shipper.packlistId}`);
    }

}
