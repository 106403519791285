import { BellContainer, ErrorNumber, ErrorNumberText } from "./order-line.page.style";
import { OrderLineStateService, _setCurrentOrderLine, _toggleOrderLineDeleteModal, _toggleOrderLineSaveModal } from "../services/order-line.state-service";

import { ColumnsType } from "antd/lib/table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IOrderLine } from "../interfaces/order-line";
import { IOrderList } from "../../order-list/interfaces/order-list";
import React from 'react';
import { Theme } from "../../shared/styles/theme";
import { colors } from "../../shared/styles/colors";
import { faBell } from "@fortawesome/free-solid-svg-icons";

export class OrderLinePageUtils {
    public static columns = (orderList: IOrderList): ColumnsType<IOrderLine> => [
        {
            title: '',
            render: (_text: string, orderLine: IOrderLine) =>
            OrderLinePageUtils.renderBell(orderList, orderLine)
        },
        {
            title: 'No',
            render: (_text: string, _orderLine: IOrderLine, index: number) => index + 1,
        }, {
            title: 'Part ID',
            dataIndex: 'partId',
        }, {
            title: 'Customer Part ID',
            dataIndex: 'customerPartId',
        }, {
            title: 'Part Description',
            dataIndex: 'partDescription',
        }, {
            title: 'Service Charge ID',
            dataIndex: 'serviceChargeId',
        }, {
            title: 'Text',
            dataIndex: 'text',
        }, {
            title: 'Quantity',
            dataIndex: 'quantity',
        }, {
            title: 'Revised Quantity',
            render: (_text: string, orderLine: IOrderLine) =>
                {
                    if (orderLine.revisedQuantity) {
                        if (orderLine.revisedQuantity.toFixed(3) !== parseFloat(orderLine.quantity).toFixed(3)) {
                            return (
                                <Theme.Bold>{orderLine.revisedQuantity}</Theme.Bold>
                            );
                        } else {
                            return orderLine.revisedQuantity;
                        }
                    }

                    return '-';
                }
        }, {
            title: 'Unit Price',
            render: (_text: string, orderLine: IOrderLine) =>
                orderLine.unitPrice ? orderLine.unitPrice : '-',
        }, {
            title: 'Revised Unit Price',
            render: (_text: string, orderLine: IOrderLine) => {
                if (orderLine.revisedUnitPrice) {
                    if (orderLine.revisedUnitPrice.toFixed(3) !== parseFloat(orderLine.unitPrice).toFixed(3)) {
                        return (
                            <Theme.Bold>{orderLine.revisedUnitPrice}</Theme.Bold>
                        );
                    } else {
                        return orderLine.revisedUnitPrice;
                    }
                }

                return '-';
            }
        }, {
            title: 'Total line amount',
            render: (_text: string, orderLine: IOrderLine) => OrderLinePageUtils.getInitialExtendedPrice(orderLine),
        }, {
            title: 'Site ID',
            dataIndex: 'siteId',
        }
    ];

    private static renderBell = (orderList: IOrderList, orderLine: IOrderLine) => {
        if (orderList && orderList?.orderStatus == 2 && orderLine.errorsList && orderLine.errorsList.length) {
                return (
                    <BellContainer>
                        <FontAwesomeIcon icon={faBell}
                            color={colors.$warning}
                            size={'lg'} />

                        <ErrorNumber>
                            <ErrorNumberText>
                                {orderLine.errorsList && orderLine.errorsList.length || ''}
                            </ErrorNumberText>
                        </ErrorNumber>
                    </BellContainer>
                );
        }
        return <></>;
    }

    public static onDeleteClick = (orderLine: IOrderLine) => {
        _toggleOrderLineDeleteModal(true);
        _setCurrentOrderLine(orderLine);
    }

    public static onEditClick = (orderLine: IOrderLine) => {
        _toggleOrderLineSaveModal(true)
        _setCurrentOrderLine(orderLine);
    }

    public static handleView = (orderLine: IOrderLine) => {
        _toggleOrderLineSaveModal(true);
        _setCurrentOrderLine(orderLine);
        OrderLineStateService.toggleViewMode(true);
    }

    public static getInitialExtendedPrice(currentOrderLine: IOrderLine) {
        if (!currentOrderLine.revisedUnitPrice || !currentOrderLine.revisedQuantity) {
            return 0;
        }

        return (currentOrderLine.revisedQuantity * currentOrderLine.revisedUnitPrice)
            .toFixed(2)
            .toString();
    }

}
