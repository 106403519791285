import React, { useEffect, useState } from 'react';

import { Input } from '../../../shared/components/input/input';
import { Theme } from '../../../shared/styles/theme';

interface Props {
    onInputChange: (key: string, value: string) => void;
    initPriceFrom: string;
    initPriceTo: string;
}

const { Filters } = Theme;

export const ErpTotalCostFilter: React.FC<Props> = (props: Props) => {
    const { onInputChange, initPriceFrom, initPriceTo } = props;

    // State
    const [priceFrom, setPriceFrom] = useState(initPriceFrom || '');
    const [priceTo, setPriceTo] = useState(initPriceTo || '');

    const render = () => {
        return (
            <Filters.Wrapper>
                <Filters.Title>Total Cost</Filters.Title>

                <Filters.Body>
                    <Filters.Column paddingRight='20px'>
                        <Input label={'From'}
                            value={priceFrom}
                            onChange={priceFrom => onChange(priceFrom, `priceFromErp`)} />
                    </Filters.Column>

                    <Filters.Column paddingLeft='10px'>
                        <Input label={'To'}
                            value={priceTo}
                            onChange={priceTo => onChange(priceTo, `priceToErp`)} />
                    </Filters.Column>
                </Filters.Body>
            </Filters.Wrapper>
        );
    };

    useEffect(() => {
        if (!initPriceFrom && !initPriceTo) {
            setPriceFrom('');
            setPriceTo('');
        }
    }, [initPriceFrom, initPriceTo]);

    const onChange = (price: string, key: string) => {
        if (key === 'priceToErp') {
            setPriceTo(price);
        }

        if (key === 'priceFromErp') {
            setPriceFrom(price);
        }

        onInputChange(key, price);
    };

    return render();
};
