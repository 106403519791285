import { Action, IApiTableRequest } from '../../shared/interfaces/shared';
import { IOrderBlob, IOrderList, IOrderListFilters, IOrderListSaveReq, IOrderListValidationResponse, IUpdateAddressReq } from '../interfaces/order-list';

import { IShippingFee } from '../interfaces/shipping-fee';

export const RESET_ORDER_LIST = 'RESET_ORDER_LIST';
export const resetOrderList = () => ({
    type: RESET_ORDER_LIST,
    payload: null
});

/** GET ALL ORDER LIST */

export const GET_ALL_ORDER_LISTS_REQ = 'GET_ALL_ORDER_LISTS_REQ';
export const getAllOrderListsReq = (request: IApiTableRequest) => ({
    type: GET_ALL_ORDER_LISTS_REQ,
    payload: request,
});

export const GET_ALL_ORDER_LIST_OK = 'GET_ALL_ORDER_LIST_OK';
export const getAllOrderListsOk = (orderLists: IOrderList[]) => ({
    type: GET_ALL_ORDER_LIST_OK,
    payload: orderLists
});

export const GET_ALL_ORDER_LISTS_FAIL = 'GET_ALL_ORDER_LISTS_FAIL';
export const getAllOrderListsFail = (error: string) => ({
    type: GET_ALL_ORDER_LISTS_FAIL,
    payload: error
});

export const GET_BREADCRUMB_ORDER_LIST_REQ = 'GET_BREADCRUMB_ORDER_LIST_REQ';
export const getBreadcrumbOrderListReq = (id: string) => ({
    type: GET_BREADCRUMB_ORDER_LIST_REQ,
    payload: id,
});

export const GET_BREADCRUMB_ORDER_LIST_OK = 'GET_BREADCRUMB_ORDER_LIST_OK';
export const getBreadcrumbOrderListOk = (orderList: IOrderList) => ({
    type: GET_BREADCRUMB_ORDER_LIST_OK,
    payload: orderList,
});

export const GET_BREADCRUMB_ORDER_LIST_FAIL = 'GET_BREADCRUMB_ORDER_LIST_FAIL';
export const getBreadcrumbOrderListFail = (error: string) => ({
    type: GET_BREADCRUMB_ORDER_LIST_FAIL,
    payload: error,
});

export const SET_BREADCRUMB_ORDER_LIST = 'SET_BREADCRUMB_ORDER_LIST';
export const setBreadcrumbOrderList = (orderList: IOrderList) => ({
    type: SET_BREADCRUMB_ORDER_LIST,
    payload: orderList,
});

// GET ORIGINAL ORDER
export const GET_ORIGINAL_ORDER_REQ = 'GET_ORIGINAL_ORDER_REQ';
export const getOriginalOrderReq = (orderList: IOrderList) => ({
    type: GET_ORIGINAL_ORDER_REQ,
    payload: orderList
});

export const GET_ORIGINAL_ORDER_OK = 'GET_ORIGINAL_ORDER_OK';
export const getOriginalOrderOk = (response: string) => ({
    type: GET_ORIGINAL_ORDER_OK,
    payload: response
});

export const GET_ORIGINAL_ORDER_FAIL = 'GET_ORIGINAL_ORDER_FAIL';
export const getOriginalOrderFail = (error: string) => ({
    type: GET_ORIGINAL_ORDER_FAIL,
    payload: error
});


export const TOGGLE_GET_ORIGINAL_ORDER_LOADING = 'TOGGLE_GET_ORIGINAL_ORDER_LOADING';
export const toggleGetOriginalLoading = (flag: boolean) => ({
    type: TOGGLE_GET_ORIGINAL_ORDER_LOADING,
    payload: flag
});




/** GET ORDER LISTS */

export const TOGGLE_CREATE_ORDER_LIST_MODAL = 'TOGGLE_CREATE_ORDER_LIST_MODAL';
export const toggleCreateOrderListModal = (flag: boolean): Action<boolean> => ({
    type: TOGGLE_CREATE_ORDER_LIST_MODAL,
    payload: flag
});

export const TOGGLE_GET_ORDER_LISTS_LOADING = 'TOGGLE_GET_ORDER_LISTS_LOADING';
export const toggleGetOrderListsLoading = (flag: boolean) => ({
    type: TOGGLE_GET_ORDER_LISTS_LOADING,
    payload: flag
});


/** CREATE ORDER LIST */

export const CREATE_ORDER_LIST_REQ = 'CREATE_ORDER_LIST_REQ';
export const createOrderListReq = (orderList: IOrderListSaveReq): Action<IOrderListSaveReq> => ({
    type: CREATE_ORDER_LIST_REQ,
    payload: orderList
});

export const CREATE_ORDER_LIST_OK = 'CREATE_ORDER_LIST_OK';
export const createOrderListOk = (response: string): Action<string> => ({
    type: CREATE_ORDER_LIST_OK,
    payload: response
});

export const CREATE_ORDER_LIST_FAIL = 'CREATE_ORDER_LIST_FAIL';
export const createOrderListFail = (error: string): Action<string> => ({
    type: CREATE_ORDER_LIST_FAIL,
    payload: error
});

export const TOGGLE_CREATE_ORDER_LIST_LOADING = 'TOGGLE_CREATE_ORDER_LIST_LOADING';
export const toggleCreateOrderListLoading = (flag: boolean): Action<boolean> => ({
    type: TOGGLE_CREATE_ORDER_LIST_LOADING,
    payload: flag
});


/** EDIT ORDER LIST */

export const SET_CURRENT_ORDER_LIST = 'SET_CURRENT_ORDER_LIST';
export const setCurrentOrderList = (orderList: IOrderList): Action<IOrderList> => ({
    type: SET_CURRENT_ORDER_LIST,
    payload: orderList
});

export const RESET_CURRENT_ORDER_LIST = 'RESET_CURRENT_ORDER_LIST';
export const resetCurrentOrderList = (): Action<null> => ({
    type: RESET_CURRENT_ORDER_LIST
});

export const EDIT_ORDER_LIST_REQ = 'EDIT_ORDER_LIST_REQ';
export const editOrderListReq = (orderList: IOrderListSaveReq): Action<IOrderListSaveReq> => ({
    type: EDIT_ORDER_LIST_REQ,
    payload: orderList
});

export const EDIT_ORDER_LIST_OK = 'EDIT_ORDER_LIST_OK';
export const editOrderListOk = (response: IOrderList): Action<IOrderList> => ({
    type: EDIT_ORDER_LIST_OK,
    payload: response
});

export const EDIT_ORDER_LIST_FAIL = 'EDIT_ORDER_LIST_FAIL';
export const editOrderListFail = (error: string): Action<string> => ({
    type: EDIT_ORDER_LIST_FAIL,
    payload: error
});

export const TOGGLE_EDIT_ORDER_LIST_LOADING = 'TOGGLE_EDIT_ORDER_LIST_LOADING';
export const toggleEditOrderListLoading = (flag: boolean): Action<boolean> => ({
    type: TOGGLE_EDIT_ORDER_LIST_LOADING,
    payload: flag
});


/** DELETE ORDER LIST */



export const TOGGLE_DELETE_ORDER_LIST_MODAL = 'TOGGLE_DELETE_ORDER_LIST_MODAL';
export const toggleDeleteOrderListModal = (flag: boolean): Action<boolean> => ({
    type: TOGGLE_DELETE_ORDER_LIST_MODAL,
    payload: flag
});

export const SET_DELETED_ORDER_LIST = 'SET_DELETED_ORDER_LIST';
export const setDeletedOrderList = (orderList: IOrderList): Action<IOrderList> => ({
    type: SET_DELETED_ORDER_LIST,
    payload: orderList
});

export const DELETE_ORDER_LIST_BY_ID_REQ = 'DELETE_ORDER_LIST_BY_ID_REQ';
export const deleteOrderListByIdReq = (orderList: IOrderList): Action<IOrderList> => ({
    type: DELETE_ORDER_LIST_BY_ID_REQ,
    payload: orderList
});

export const DELETE_ORDER_LIST_BY_ID_OK = 'DELETE_ORDER_LIST_BY_ID_OK';
export const deleteOrderListByIdOK = (response: string): Action<string> => ({
    type: DELETE_ORDER_LIST_BY_ID_OK,
    payload: response
});

export const DELETE_ORDER_LIST_BY_ID_FAIL = 'DELETE_ORDER_LIST_BY_ID_FAIL';
export const deleteOrderListByIdFail = (error: string): Action<string> => ({
    type: DELETE_ORDER_LIST_BY_ID_FAIL,
    payload: error
});

export const TOGGLE_DELETE_ORDER_LIST_LOADING = 'TOGGLE_DELETE_ORDER_LIST_LOADING';
export const toggleDeleteOrderListLoading = (flag: boolean): Action<boolean> => ({
    type: TOGGLE_DELETE_ORDER_LIST_LOADING,
    payload: flag
});

export class OrderListActions {
    public static readonly CONFIRM_CUSTOMER_ORDER_REQ = 'CONFIRM_CUSTOMER_ORDER_REQ';
    public static confirmCustomerOrderReq(id: number) {
        return {
            type: this.CONFIRM_CUSTOMER_ORDER_REQ,
            payload: id,
        };
    }

    public static readonly CONFIRM_CUSTOMER_ORDER_OK = 'CONFIRM_CUSTOMER_ORDER_OK';
    public static confirmCustomerOrderOk() {
        return {
            type: this.CONFIRM_CUSTOMER_ORDER_OK,
            payload: null,
        };
    }

    public static readonly CONFIRM_CUSTOMER_ORDER_FAIL = 'CONFIRM_CUSTOMER_ORDER_FAIL';
    public static confirmCustomerOrderFail(error: string) {
        return {
            type: this.CONFIRM_CUSTOMER_ORDER_FAIL,
            payload: error,
        };
    }

    public static readonly VALIDATE_ORDER_LIST_REQ = 'VALIDATE_ORDER_LIST_REQ';
    public static validateOrderListReq(orderListId: string) {
        return {
            type: this.VALIDATE_ORDER_LIST_REQ,
            payload: orderListId,
        };
    }

    public static readonly VALIDATE_ORDER_LIST_OK = 'VALIDATE_ORDER_LIST_OK';
    public static validateOrderListOk(response: IOrderListValidationResponse[]) {
        return {
            type: this.VALIDATE_ORDER_LIST_OK,
            payload: response,
        };
    }

    public static readonly VALIDATE_ORDER_LIST_ERROR = 'VALIDATE_ORDER_LIST_ERROR';
    public static validateOrderListError(error: string) {
        return {
            type: this.VALIDATE_ORDER_LIST_ERROR,
            payload: error,
        };
    }

    public static readonly TOGGLE_VALIDATION_LOADING = 'TOGGLE_VALIDATION_LOADING';
    public static toggleValidationLoading(flag: boolean) {
        return {
            type: this.TOGGLE_VALIDATION_LOADING,
            payload: flag,
        };
    }

    public static readonly TOGGLE_FILTERS = 'TOGGLE_FILTERS';
    public static toggleFilters(flag: boolean) {
        return {
            type: this.TOGGLE_FILTERS,
            payload: flag,
        };
    }

    public static readonly SET_FILTERED_ORDER_LIST = 'SET_FILTERED_ORDER_LIST';
    public static setFilteredOrderList(orderLists: IOrderList[]) {
        return {
            type: this.SET_FILTERED_ORDER_LIST,
            payload: orderLists,
        };
    }

    public static readonly SET_ORDER_LIST_FILTERS = 'SET_ORDER_LIST_FILTERS';
    public static setFilters(filters: IOrderListFilters) {
        return {
            type: this.SET_ORDER_LIST_FILTERS,
            payload: filters,
        };
    }

    public static readonly SET_VIEW_MODE = 'SET_VIEW_MODE';
    public static setViewMode(flag: boolean) {
        return {
            type: this.SET_VIEW_MODE,
            payload: flag,
        };
    }

    public static readonly GET_FILTER_PO_NUMBERS_REQ = 'GET_FILTER_PO_NUMBERS_REQ';
    public static getFilterPoNumbersReq(text: string) {
        return {
            type: this.GET_FILTER_PO_NUMBERS_REQ,
            payload: text,
        };
    }

    public static readonly GET_FILTER_PO_NUMBERS_OK = 'GET_FILTER_PO_NUMBERS_OK';
    public static getFilterPoNumbersOk(arr: string[]) {
        return {
            type: this.GET_FILTER_PO_NUMBERS_OK,
            payload: arr,
        };
    }

    public static readonly GET_FILTER_PO_NUMBERS_FAIL = 'GET_FILTER_PO_NUMBERS_FAIL';
    public static getFilterPoNumbersFail(error: string) {
        return {
            type: this.GET_FILTER_PO_NUMBERS_FAIL,
            payload: error,
        };
    }

    public static readonly GET_ORDER_BLOB_REQ = 'GET_ORDER_BLOB_REQ';
    public static getOrderBlobReq(order: IOrderList) {
        return {
            type: this.GET_ORDER_BLOB_REQ,
            payload: order,
        };
    }

    public static readonly GET_ORDER_BLOB_OK = 'GET_ORDER_BLOB_OK';
    public static getOrderBlobOk(obj: IOrderBlob) {
        return {
            type: this.GET_ORDER_BLOB_OK,
            payload: obj,
        };
    }

    public static readonly GET_ORDER_BLOB_FAIL = 'GET_ORDER_BLOB_FAIL';
    public static getOrderBlobFail(error: string) {
        return {
            type: this.GET_ORDER_BLOB_FAIL,
            payload: error,
        };
    }

    public static readonly ARCHIVE_PO_REQ = 'ARCHIVE_PO_REQ';
    public static archivePoReq(id: number) {
        return {
            type: this.ARCHIVE_PO_REQ,
            payload: id,
        };
    }

    public static readonly ARCHIVE_PO_OK = 'ARCHIVE_PO_OK';
    public static archivePoOk() {
        return {
            type: this.ARCHIVE_PO_OK,
            payload: null,
        };
    }

    public static readonly ARCHIVE_PO_FAIL = 'ARCHIVE_PO_FAIL';
    public static archivePoFail(error: string) {
        return {
            type: this.ARCHIVE_PO_FAIL,
            payload: error,
        };
    }

    public static readonly TOGGLE_SHIPPING_FEES_MODAL = 'TOGGLE_SHIPPING_FEED_MODAL';
    public static toggleShippingFeedModal(flag: boolean) {
        return {
            type: this.TOGGLE_SHIPPING_FEES_MODAL,
            payload: flag,
        };
    }

    public static readonly GET_SHIPPING_FEES_BY_ORDER_ID_REQ = 'GET_SHIPPING_FEES_BY_ORDER_ID_REQ';
    public static getShippingFeedByOrderIdReq(id: number) {
        return {
            type: this.GET_SHIPPING_FEES_BY_ORDER_ID_REQ,
            payload: id,
        };
    }

    public static readonly GET_SHIPPING_FEES_BY_ORDER_ID_OK = 'GET_SHIPPING_FEES_BY_ORDER_ID_OK';
    public static getShippingFeedByOrderIdOk(fees: IShippingFee[]) {
        return {
            type: this.GET_SHIPPING_FEES_BY_ORDER_ID_OK,
            payload: fees,
        };
    }

    public static readonly GET_SHIPPING_FEES_BY_ORDER_ID_FAIL = 'GET_SHIPPING_FEES_BY_ORDER_ID_FAIL';
    public static getShippingFeedByOrderIdFail(error: string) {
        return {
            type: this.GET_SHIPPING_FEES_BY_ORDER_ID_FAIL,
            payload: error,
        };
    }

    public static readonly SET_SELECTED_SHIPPING_FEE = 'SET_SELECTED_SHIPPING_FEE';
    public static setSelectedShippingFee(fee: IShippingFee) {
        return {
            type: this.SET_SELECTED_SHIPPING_FEE,
            payload: fee,
        };
    }

    public static readonly TOGGLE_LOADING_SHIPPING_FEES = 'TOGGLE_LOADING_SHIPPING_FEES';
    public static toggleLoadingShippingFees(flag: boolean) {
        return {
            type: this.TOGGLE_LOADING_SHIPPING_FEES,
            payload: flag,
        };
    }

    public static readonly SEND_TO_ERP_REQ = 'SEND_TO_ERP_REQ';
    public static sendToErpReq(id: number) {
        return {
            type: this.SEND_TO_ERP_REQ,
            payload: id,
        };
    }

    public static readonly SEND_TO_ERP_OK = 'SEND_TO_ERP_OK';
    public static sendToErpOk() {
        return {
            type: this.SEND_TO_ERP_OK,
            payload: null,
        };
    }

    public static readonly SEND_TO_ERP_FAIL = 'SEND_TO_ERP_FAIL';
    public static sendToErpFail(error: string) {
        return {
            type: this.SEND_TO_ERP_FAIL,
            payload: error,
        };
    }

    public static readonly TOGGLE_UPDATE_ADDRESS_MODAL = 'TOGGLE_UPDATE_ADDRESS_MODAL';
    public static toggleUpdateModalAddress(flag: boolean) {
        return {
            type: this.TOGGLE_UPDATE_ADDRESS_MODAL,
            payload: flag,
        };
    }

    public static readonly UPDATE_ADDRESS_REQ = 'UPDATE_ADDRESS_REQ';
    public static updateAddressReq(req: IUpdateAddressReq ) {
        return {
            type: this.UPDATE_ADDRESS_REQ,
            payload: req,
        };
    }

    public static readonly UPDATE_ADDRESS_OK = 'UPDATE_ADDRESS_OK';
    public static updateAddressOk() {
        return {
            type: this.UPDATE_ADDRESS_OK,
            payload: null,
        };
    }

    public static readonly UPDATE_ADDRESS_FAIL = 'UPDATE_ADDRESS_FAIL';
    public static updateAddressFail(error: string) {
        return {
            type: this.UPDATE_ADDRESS_FAIL,
            payload: error,
        };
    }
}
