import * as actions from './customers.actions';

import { Action } from "../../shared/interfaces/shared";
import { ICustomer } from '../interfaces/customer';
import { ICustomerDetails } from '../interfaces/customer-details';
import { ICustomersState } from "../interfaces/customers-state";
import { customersInitState } from "./customers.init-state";

export function customersReducer(state: ICustomersState = customersInitState, action: Action<any>): ICustomersState {
    switch (action.type) {
        case actions.GET_CUSTOMERS_BY_COMPANY_NAME_OK: {
            return {
                ...state,
                customers: action.payload,
                customersError: '',
            };
        }

        case actions.SET_ERP_CUSTOMER_IDS: {
            return {
                ...state,
                erpCustomerIds: action.payload,
            };
        }

        case actions.GET_CUSTOMERS_BY_COMPANY_NAME_FAIL: {
            return {
                ...state,
                customers: [],
                customersLoading: false,
                customersError: action.payload.title,
            };
        }

        case actions.GET_CUSTOMERS_BY_COMPANY_ID_OK: {
            return {
                ...state,
                customers: action.payload,
                customersLoading: false,
                customersError: '',
            };
        }

        case actions.GET_CUSTOMERS_BY_COMPANY_ID_FAIL: {
            return {
                ...state,
                customers: [],
                customersLoading: false,
                customersError: action.payload,
            };
        }

        case actions.GET_CUSTOMER_BY_ID_OK: {
            return {
                ...state,
                customer: action.payload,
                customersLoading: false,
                customersError: '',
            };
        }

        case actions.GET_CUSTOMER_BY_ID_FAIL: {
            return {
                ...state,
                customer: {} as ICustomer,
                customersLoading: false,
                customersError: action.payload,
            };
        }

        case actions.RESET_CUSTOMERS: {
            return {
                ...state,
                customers: [],
                customersLoading: false,
                customersError: '',
                currentCustomer: {} as ICustomer,
            };
        }

        case actions.RESET_CUSTOMER: {
            return {
                ...state,
                currentCustomer: customersInitState.currentCustomer,
            };
        }

        case actions.TOGGLE_CUSTOMERS_LOADING: {
            return {
                ...state,
                customersLoading: action.payload,
            };
        }

        case actions.SET_CURRENT_CUSTOMER: {
            return {
                ...state,
                currentCustomer: action.payload,
            };
        }

        case actions.TOGGLE_DELETE_CUSTOMER_MODAL: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    delete: action.payload,
                },
            };
        }

        case actions.DELETE_CUSTOMER_OK: {
            return {
                ...state,
                deleteCustomer: {
                    ...state.deleteCustomer,
                    response: action.payload,
                    error: '',
                },
            };
        }

        case actions.DELETE_CUSTOMER_FAIL: {
            return {
                ...state,
                deleteCustomer: {
                    ...state.deleteCustomer,
                    response: {} as ICustomer,
                    error: action.payload,
                },
            };
        }

        case actions.TOGGLE_SAVE_CUSTOMER_MODAL: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    save: action.payload,
                },
            };
        }

        case actions.GET_FILTER_EXTERNAL_CUSTOMER_IDS_OK: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    externalCustomerIds: action.payload,
                },
            };
        }

        case actions.GET_PARSERS_OK: {
            return {
                ...state,
                parsers: action.payload,
            };
        }

        case actions.GET_CUSTOMERS_FROM_ERP_COMPANY_OK: {
            return {
                ...state,
                erpCustomerIds: action.payload,
            };
        }

        case actions.GET_ERP_CUSTOMER_IDS_OK: {
            return {
                ...state,
                erpCustomerIds: action.payload,
            };
        }

        case actions.TOGGLE_ERP_CUSTOMER_IDS_LOADING: {
            return {
                ...state,
                erpCustomerIdsLoading: action.payload,
            };
        }

        case actions.GET_PRODUCT_CODES_OK: {
            return {
                ...state,
                productCodes: action.payload,
            };
        }

        case actions.GET_INCOTERM_REQ: {
            return {
                ...state,
                incotermsLoading: true,
            };
        }

        case actions.GET_INCOTERM_OK: {
            return {
                ...state,
                incoterms: action.payload,
                incotermsLoading: false,
            };
        }

        case actions.GET_INCOTERM_FAIL: {
            return {
                ...state,
                incotermsLoading: false,
                incoterms: [],
            };
        }

        case actions.GET_FREIGHTS_REQ: {
            return {
                ...state,
                freightsLoading: true,
            };
        }

        case actions.GET_FREIGHTS_OK: {
            return {
                ...state,
                freights: action.payload,
                freightsLoading: false,
            };
        }

        case actions.GET_FREIGHTS_FAIL: {
            return {
                ...state,
                freightsLoading: false,
                freights: [],
            };
        }

        case actions.GET_CUSTOMER_PARTS_REQ: {
            return {
                ...state,
                customerPartsLoading: true,
            };
        }

        case actions.GET_CUSTOMER_PARTS_OK: {
            return {
                ...state,
                customerParts: action.payload,
                customerPartsLoading: false,
            };
        }

        case actions.GET_CUSTOMER_PARTS_FAIL: {
            return {
                ...state,
                customerPartsLoading: false,
                customerParts: [],
            };
        }

        case actions.GET_CUSTOMER_DETAILS_REQ: {
            return {
                ...state,
                customerDetailsLoading: true,
            };
        }

        case actions.GET_CUSTOMER_DETAILS_OK: {
            return {
                ...state,
                customerDetails: action.payload,
                customerDetailsLoading: false,
            };
        }

        case actions.GET_CUSTOMER_DETAILS_FAIL: {
            return {
                ...state,
                customerDetailsLoading: false,
                customerDetails: {} as ICustomerDetails,
            };
        }

        case actions.GET_SHIPPING_ADDRESSES_REQ: {
            return {
                ...state,
                shippingAddressesLoading: true,
            };
        }

        case actions.GET_SHIPPING_ADDRESSES_OK: {
            return {
                ...state,
                shippingAddresses: action.payload,
                shippingAddressesLoading: false,
            };
        }

        case actions.GET_SHIPPING_ADDRESSES_FAIL: {
            return {
                ...state,
                shippingAddressesLoading: false,
                shippingAddresses: [],
            };
        }

        case actions.GET_CUSTOMER_BY_EXTERNAL_ID_REQ: {
            return {
                ...state,
                customerByExternalIdLoading: true,
            };
        }

        case actions.GET_CUSTOMER_BY_EXTERNAL_ID_OK: {
            return {
                ...state,
                customerByExternalId: {
                    ...state.customerByExternalId,
                    response: action.payload,
                    error: null,
                },
                customerByExternalIdLoading: false,
            };
        }

        case actions.GET_CUSTOMER_BY_EXTERNAL_ID_FAIL: {
            return {
                ...state,
                customerByExternalIdLoading: false,
                customerByExternalId: {
                    ...state.customerByExternalId,
                    response: null,
                    error: action.payload,
                },
            };
        }

        case actions.GET_ERP_CUSTOMERS_FOR_USER_REQ: {
            return {
                ...state,
                customersForUserLoading: true,
            };
        }

        case actions.GET_ERP_CUSTOMERS_FOR_USER_OK: {
            return {
                ...state,
                customersForUserLoading: false,
                customersForUser: action.payload,
            };
        }

        case actions.GET_ERP_CUSTOMERS_FOR_USER_FAIL: {
            return {
                ...state,
                customersForUserLoading: false,
                customersForUser: [],
            };
        }

        case actions.RESET_CUSTOMER_BY_EXTERNAL_ID_ERROR: {
            return {
                ...state,
                customerByExternalId: {
                    ...state.customerByExternalId,
                    error: '',
                },
            };
        }

        case actions.TOGGLE_SAVE_INVENTORY_ADDRESS_MODAL: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    inventoryAddress: action.payload,
                },
            };
        }

        case actions.SET_CURRENT_INVENTORY_ADDRESS: {
            return {
                ...state,
                selectedInventoryAddress: action.payload,
            };
        }

        default:
            return state;
    }

}
