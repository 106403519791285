import { IFilterDayOff, IDayOff } from '../interfaces/day-off';

import { Action } from '../../shared/interfaces/shared';
import { IDayOffFormValues } from '../interfaces/day-off-form-values'
import { ISiteId } from '../../shared/interfaces/site-id';

export const RESET_DAYOFFS = 'RESET_DAYOFFS';
export const resetDayOffs = () => ({
    type: RESET_DAYOFFS,
    payload: null
});

/** GET DayOffs */

export const TOGGLE_CREATE_DAYOFF_MODAL = 'TOGGLE_CREATE_DAYOFF_MODAL';
export const toggleCreateModal = (flag: boolean): Action<boolean> => ({
    type: TOGGLE_CREATE_DAYOFF_MODAL,
    payload: flag
});

export const GET_DAYOFFS_REQ = 'GET_DAYOFFS_REQ';
export const getDayOffsReq = (): Action<null> => ({
    type: GET_DAYOFFS_REQ,
    payload: null
});

export const GET_DAYOFFS_OK = 'GET_DAYOFFS_OK';
export const getDayOffsOk = (dayOffs: IDayOff[]) => ({
    type: GET_DAYOFFS_OK,
    payload: dayOffs
});

export const GET_DAYOFFS_FAIL = 'GET_DAYOFFS_FAIL';
export const getDayOffsFail = (error: string) => ({
    type: GET_DAYOFFS_FAIL,
    payload: error
});

export const TOGGLE_GET_DAYOFFS_LOADING = 'TOGGLE_GET_DAYOFFS_LOADING';
export const toggleGetDayOffsLoading = (flag: boolean) => ({
    type: TOGGLE_GET_DAYOFFS_LOADING,
    payload: flag
});

/** DELETE DayOff */

export const TOGGLE_DELETE_MODAL = 'TOGGLE_DELETE_MODAL';
export const toggleDeleteModal = (flag: boolean): Action<boolean> => ({
    type: TOGGLE_DELETE_MODAL,
    payload: flag
});

export const SET_DELETED_DAYOFF = 'SET_DELETED_DAYOFF';
export const setDeletedDayOff = (dayOff: IDayOff): Action<IDayOff> => ({
    type: SET_DELETED_DAYOFF,
    payload: dayOff
});

export const DELETE_DAYOFF_BY_ID_REQ = 'DELETE_DAYOFF_BY_ID_REQ';
export const deleteDayOffByIdReq = (id: number): Action<number> => ({
    type: DELETE_DAYOFF_BY_ID_REQ,
    payload: id
});

export const DELETE_DAYOFF_BY_ID_OK = 'DELETE_DAYOFF_BY_ID_OK';
export const deleteDayOffByIdOK = (response: string): Action<string> => ({
    type: DELETE_DAYOFF_BY_ID_OK,
    payload: response
});

export const DELETE_DAYOFF_BY_ID_FAIL = 'DELETE_DAYOFF_BY_ID_FAIL';
export const deleteDayOffByIdFail = (error: string): Action<string> => ({
    type: DELETE_DAYOFF_BY_ID_FAIL,
    payload: error
});

export const TOGGLE_DELETE_DAYOFF_LOADING = 'TOGGLE_DELETE_DAYOFF_LOADING';
export const toggleDeleteDayOffLoading = (flag: boolean): Action<boolean> => ({
    type: TOGGLE_DELETE_DAYOFF_LOADING,
    payload: flag
});


/** CREATE DayOff*/

export const CREATE_DAYOFF_REQ = 'CREATE_DAYOFF_REQ';
export const createDayOffReq = (dayOff: IDayOffFormValues): Action<IDayOffFormValues> => ({
    type: CREATE_DAYOFF_REQ,
    payload: dayOff
});

export const CREATE_DAYOFF_OK = 'CREATE_DAYOFF_OK';
export const createDayOffOk = (response: string): Action<string> => ({
    type: CREATE_DAYOFF_OK,
    payload: response
});

export const CREATE_DAYOFF_FAIL = 'CREATE_DAYOFF_FAIL';
export const createDayOffFail = (error: string): Action<string> => ({
    type: CREATE_DAYOFF_FAIL,
    payload: error
});

export const TOGGLE_CREATE_DAYOFF_LOADING = 'TOGGLE_CREATE_DAYOFF_LOADING';
export const toggleCreateDayOffLoading = (flag: boolean): Action<boolean> => ({
    type: TOGGLE_CREATE_DAYOFF_LOADING,
    payload: flag
});

/**EDIT DayOff */

export const SET_CURRENT_DAYOFF = 'SET_CURRENT_DAYOFF';
export const setCurrentSoa = (soa: IDayOff): Action<IDayOff> => ({
    type: SET_CURRENT_DAYOFF,
    payload: soa
});

export const RESET_CURRENT_DAYOFF = 'RESET_CURRENT_DAYOFF';
export const resetCurrentDayOff = (): Action<null> => ({
    type: RESET_CURRENT_DAYOFF
});

export const EDIT_DAYOFF_REQ = 'EDIT_DAYOFF_REQ';
export const editDayOffReq = (soa: IDayOff): Action<IDayOff> => ({
    type: EDIT_DAYOFF_REQ,
    payload: soa
});

export const EDIT_DAYOFF_OK = 'EDIT_DAYOFF_OK';
export const editDayOffOk = (response: string): Action<string> => ({
    type: EDIT_DAYOFF_OK,
    payload: response
});

export const EDIT_DAYOFF_FAIL = 'EDIT_DAYOFF_FAIL';
export const editDayOffFail = (error: string): Action<string> => ({
    type: EDIT_DAYOFF_FAIL,
    payload: error
});

export const TOGGLE_EDIT_DAYOFF_LOADING = 'TOGGLE_EDIT_DAYOFF_LOADING';
export const toggleEditDayOffLoading = (flag: boolean): Action<boolean> => ({
    type: TOGGLE_EDIT_DAYOFF_LOADING,
    payload: flag
});



export const GET_FILTER_DAYOFFS_REQ = 'GET_FILTER_DAYOFFS_REQ';
export const getFilterDayOffs = (): Action<null> => ({
    type: GET_FILTER_DAYOFFS_REQ,
    payload: null,
});

export const GET_FILTER_DAYOFFS_OK = 'GET_FILTER_DAYOFFS_OK';
export const getFilterDayOffsOk = (list: IFilterDayOff[]): Action<IFilterDayOff[]> => ({
    type: GET_FILTER_DAYOFFS_OK,
    payload: list,
});

export const GET_FILTER_DAYOFFS_FAIL = 'GET_FILTER_DAYOFFS_FAIL';
export const getFilterDayOffsFail = (error: string): Action<string> => ({
    type: GET_FILTER_DAYOFFS_FAIL,
    payload: error,
});

export const GET_DAYOFF_BY_ID_REQ = 'GET_DAYOFF_BY_ID_REQ';
export const getDayOffById = (id: number) => ({
    type: GET_DAYOFF_BY_ID_REQ,
    payload: id,
});

export const GET_DAYOFF_BY_ID_OK = 'GET_DAYOFF_BY_ID_OK';
export const getDayOffByIdOk = (item: IDayOff) => ({
    type: GET_DAYOFF_BY_ID_OK,
    payload: item,
});

export const GET_DAYOFF_BY_ID_FAIL = 'GET_DAYOFF_BY_ID_FAIL';
export const getDayOffByIdFail = (error: string) => ({
    type: GET_DAYOFF_BY_ID_FAIL,
    payload: error,
});

export const SET_DEFAULT_SITEID = 'SET_DEFAULT_SITEID';
export const setDefaultSiteId = (siteId: ISiteId) => { 
    return ({
    type: SET_DEFAULT_SITEID,
    payload: siteId,
});
}