import * as actions from './day-off.actions';

import { Action } from '../../shared/interfaces/shared';
import { IDayOffState } from '../interfaces/day-off-state';
import { IDayOff } from '../interfaces/day-off'
import { dayOffInitState } from './day-off.init-state';
import { ISiteId } from '../../shared/interfaces/site-id';

export function dayOffReducer(state: IDayOffState = dayOffInitState, action: Action<any>): IDayOffState {
    switch (action.type) {

        case actions.RESET_DAYOFFS: {
            return {
                dayOff: {} as IDayOff,
                deleteDayOffModal: false,
                createDayOffModal: false,
                dayOffs: [],
                getDayOffsLoading: false,
                defaultSiteId: {} as ISiteId,
                createDayOff: {
                    loading: false,
                    response: '',
                    error: ''
                },
                deleteDayOff: {
                    deletedDayOff: {} as IDayOff,
                    loading: false,
                    response: '',
                    error: ''
                },
                editDayOff: {
                    currentDayOff: {} as IDayOff,
                    loading: false,
                    response: '',
                    error: '',
                }
            };
        }

        case actions.GET_DAYOFFS_OK: {
            return {
                ...state,
                dayOffs: action.payload
            };
        }

        case actions.TOGGLE_GET_DAYOFFS_LOADING: {
            return {
                ...state,
                getDayOffsLoading: action.payload
            };
        }

        case actions.TOGGLE_DELETE_MODAL: {
            return {
                ...state,
                deleteDayOffModal: action.payload
            };
        }


        case actions.SET_DELETED_DAYOFF: {
            return {
                ...state,
                deleteDayOff: {
                    ...state.deleteDayOff,
                    deletedDayOff: action.payload
                }
            };
        }

        case actions.DELETE_DAYOFF_BY_ID_OK: {
            return {
                ...state,
                deleteDayOff: {
                    ...state.deleteDayOff,
                    response: action.payload,
                    error: ''
                },
                createDayOff: {
                    ...dayOffInitState.createDayOff
                }

            };
        }

        case actions.DELETE_DAYOFF_BY_ID_FAIL: {
            return {
                ...state,
                deleteDayOffModal: false,
                deleteDayOff: {
                    ...state.deleteDayOff,
                    loading: false,
                    error: action.payload,
                    response: ''
                },
                createDayOff: {
                    ...dayOffInitState.createDayOff
                }
            };
        }

        case actions.TOGGLE_DELETE_DAYOFF_LOADING: {
            return {
                ...state,
                deleteDayOff: {
                    ...state.deleteDayOff,
                    loading: action.payload
                }
            };
        }

        case actions.TOGGLE_CREATE_DAYOFF_MODAL: {
            return {
                ...state,
                createDayOffModal: action.payload
            };
        }

        case actions.CREATE_DAYOFF_OK: {
            return {
                ...state,
                createDayOff: {
                    ...state.createDayOff,
                    response: action.payload,
                    error: ''
                },
                deleteDayOff: {
                    ...dayOffInitState.deleteDayOff
                }
            };
        }

        case actions.CREATE_DAYOFF_FAIL: {
            return {
                ...state,
                createDayOffModal: false,
                createDayOff: {
                    loading: false,
                    error: action.payload,
                    response: ''
                },
                deleteDayOff: {
                    ...dayOffInitState.deleteDayOff
                }
            };
        }

        case actions.TOGGLE_CREATE_DAYOFF_LOADING: {
            return {
                ...state,
                createDayOff: {
                    ...state.createDayOff,
                    loading: action.payload
                }
            };
        }

        case actions.SET_DEFAULT_SITEID: {
            return {
                ...state,
                defaultSiteId: action.payload
            };
        }
        
        default:
            return state;
    }
}
