import * as actions from '../state/day-off.actions';
import * as selectors from '../state/day-off.selectors';

import { distinctUntilChanged, map, skipWhile } from 'rxjs/operators';
import { store, store$ } from '../../shared/services/state.service';

import { IDayOff } from '../interfaces/day-off';
import { IDayOffFormValues } from '../interfaces/day-off-form-values'
import { Observable } from 'rxjs';
import { ISiteId } from '../../shared/interfaces/site-id';

/** GET DAYOFFS */

export const dayOffs$ = (): Observable<IDayOff[]> =>
    store$.pipe(
        map(state => selectors.DAYOFFS(state)),
        skipWhile(dayOffs => !dayOffs),
        distinctUntilChanged()
    );

export const _getDayOffs = (): void => {
    store.dispatch(actions.getDayOffsReq());
};

export const getDayOffsLoading$ = (): Observable<boolean> =>
    store$.pipe(
        map(state => selectors.DAYOFFS_LOADING(state)),
        distinctUntilChanged()
    );

export const _toggleGetDayOffsLoading = (flag: boolean): void => {
    store.dispatch(actions.toggleGetDayOffsLoading(flag));
};


/** DELETE DAYOFF */

export function _toggleDeleteDayOffModal(flag: boolean): void {
    store.dispatch(actions.toggleDeleteModal(flag));
}
export function _setDeletedDayOff(item: IDayOff): void {
    store.dispatch(actions.setDeletedDayOff(item));
}

export const _deleteDayOff = (id: number): void => {
    store.dispatch(actions.deleteDayOffByIdReq(id));
}

export const _toggleDeleteDayOffLoading = (flag: boolean): void => {
    store.dispatch(actions.toggleDeleteDayOffLoading(flag));
};

export const deleteDayOffLoading$ = (): Observable<boolean> =>
    store$.pipe(
        map(state => selectors.DELETE_DAYOFF_LOADING(state)),
        distinctUntilChanged()
    );




/** CREATE DAYOFF */

export function _toggleCreatetDayOffModal(flag: boolean): void {
    store.dispatch(actions.toggleCreateModal(flag));
}


export function _createDayOff(item: IDayOffFormValues): void {
    store.dispatch(actions.createDayOffReq(item));
}

export const _toggleCreateDayOffLoading = (flag: boolean): void => {
    store.dispatch(actions.toggleCreateDayOffLoading(flag));
};

export const createDayOffLoading$ = (): Observable<boolean> =>
    store$.pipe(
        map(state => selectors.CREATE_DAYOFF_LOADING(state)),
        distinctUntilChanged()
);

export function _resetCurrentDayOff(): void {
    store.dispatch(actions.resetCurrentDayOff());
}


export const _resetDayOffs = (): void => {
    store.dispatch(
        actions.resetDayOffs(),
    );
};

export const _setDefaultSiteId = (siteId: ISiteId): void => {
    store.dispatch(
        actions.setDefaultSiteId(siteId),
    );
};

export class DayOffStateService {
    public static getFilterDayOffs() {
        store.dispatch(
            actions.getFilterDayOffs(),
        );
    }

    public static getDayOffById(id: number) {
        store.dispatch(
            actions.getDayOffById(id),
        );
    }

}