import { CustomersStateService, _saveCustomer } from '../services/customers.state-service';
import { ICustomer, IFreightPref, IIncoterm, ISaveCustomerReq } from '../interfaces/customer';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { ActivityIndicator } from '../../shared/components/activity-indicator/activity-indicator';
import { AppState } from '../../shared/interfaces/app.state';
import { Button } from '../../shared/components/button/button';
import { Dropdown } from '../../shared/components/dropdown/dropdown';
import { Input } from '../../shared/components/input/input';
import { MultipleDropdown } from '../../shared/components/dropdown/multiple-dropdown';
import { PageTitle } from '../../shared/components/page-title/page-title';
import { SectionTitle } from '../../shared/components/section-title/section-title';
import { SlimButton } from '../../shared/components/button/slim-button';
import { Theme } from '../../shared/styles/theme';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

interface IUrlParams {
    customerId: string;
}
interface Props { }

export const CustomersShipmentPage: React.FC<Props> = (props: Props) => {
    const { customerId } = useParams<IUrlParams>();
    const history = useHistory();

    const freights = useSelector<AppState, IFreightPref[]>(state => state.customers.freights);
    const incoterms = useSelector<AppState, IIncoterm[]>(state => state.customers.incoterms);
    const productCodes = useSelector<AppState, string[]>(state => state.customers.productCodes);
    const customer = useSelector<AppState, ICustomer>(state => state.shared.breadcrumbs.customer);

    const getInitialFreight = () => {
        if (!isEmpty(customer) && customer.freightPreference) {
            let f = freights.find(fr => fr.name === customer.freightPreference);

            return f;
        }

        return {} as IFreightPref;
    };

    const getInitialIncoterm = () => {
        if (!isEmpty(customer)) {
            let inco = incoterms.find(i => i.code === customer.dfltIncoTerm);

            return inco;
        }

        return {} as IIncoterm;
    };

    const [isLoading, setIsLoading] = useState(true);
    const [amount, setAmount] = useState(customer.amountToBePrepaid || '');
    const [productCodesList, setProductCodesList] = useState<any>([]);
    const [palletProductCodesList, setPalletProductCodesList] = useState<any>([]);
    const [palletAmount, setPalletAmount] = useState('');

    const [freight, setFreight] = useState(getInitialFreight());
    const [incoterm, setIncoterm] = useState(getInitialIncoterm());
    const [displayDropdowns, setDisplayDropdowns] = useState(false);

    const [errors, setErrors] = useState({
        freight: '',
        incoterm: '',
        palletProductCodesList: '',
        productCodesList: '',
    });

    /**
     * Used in quantity per product section
     * For each selection, we remove the current product code from the list
     */

    const render = () => {
        if (isLoading) {
            return (
                <ActivityIndicator />
            );
        }

        return (
            <Theme.PageWrapper className='customers-shipment'>
                <Theme.Section>
                    <SlimButton onClick={() => history.goBack()} label={'Back'} />
                </Theme.Section>

                <Theme.SpaceBetween>
                    <PageTitle text={`Configure shipment for ${customer.externalCustomerId}`} />

                    <Button onClick={() => onSubmitClick()}
                        disabled={isButtonDisabled()}
                        label={'Save All'} />
                </Theme.SpaceBetween>

                <Theme.Section width='500px'>
                    <Dropdown options={freights}
                        keyToShow={'name'}
                        value={freight}
                        label={'Freight preferences'}
                        required={true}
                        error={errors.freight}
                        allowClear={true}
                        onChange={freight => handleDropdownChange('freight', freight, setFreight(freight), true)} />
                </Theme.Section>

                <Theme.Section width='500px'>
                    <Dropdown options={incoterms}
                        keyToShow={'code'}
                        value={incoterm}
                        label={'Incoterm'}
                        error={errors.incoterm}
                        allowClear={true}
                        onChange={incoterm => setIncoterm(incoterm)} />
                </Theme.Section>

                {
                    displayDropdowns &&
                    <>
                        <Theme.Section width='500px'>
                            <SectionTitle text={'Amount to get PREPAID'} />

                            <Input type='number'
                                value={amount}
                                minValue={'1'}
                                onChange={amount => setAmount(amount)}
                                label={'Amount'} />

                            <MultipleDropdown label={'Product Codes'}
                                options={productCodes}
                                allowClear={true}
                                value={productCodesList}
                                error={errors.productCodesList}
                                disabled={!amount}
                                showAny={true}
                                onChange={productCodes => handleMultipleDropdownChange('productCodesList', productCodes, setProductCodesList(productCodes), amount)} />
                        </Theme.Section>

                        <Theme.Section width='500px'>
                            <SectionTitle text={'Number of pallets of products to be PREPAID'} />

                            <Input type='number'
                                value={palletAmount}
                                minValue={'1'}
                                onChange={amount => setPalletAmount(amount)}
                                label={'Pallets'} />

                            <MultipleDropdown label={'Product Codes'}
                                options={productCodes}
                                allowClear={true}
                                value={palletProductCodesList}
                                error={errors.palletProductCodesList}
                                disabled={!palletAmount}
                                showAny={true}
                                onChange={productCodes => handleMultipleDropdownChange('palletProductCodesList', productCodes, setPalletProductCodesList(productCodes), palletAmount)} />

                        </Theme.Section>
                    </>
                }

            </Theme.PageWrapper>
        );
    };

    useEffect(() => {
        if (freight && (freight.name === 'PREPAID/PREPAID AND ADD' || freight.name === 'PREPAID/COLLECT')) {
            setDisplayDropdowns(true);
        } else {
            setDisplayDropdowns(false);
        }
    }, [freight]);

    useEffect(() => {
        CustomersStateService.getBreadcrumbCustomer(customerId);
    }, [customerId]);

    useEffect(() => {
        if (!isEmpty(customer)) {
            setIsLoading(false);
            CustomersStateService.getFreights();
            CustomersStateService.getIncoterm();
            CustomersStateService.getProductCodes(customer.externalCustomerId);

            const productCodesForAmount = customer.productCodesForAmmount ? customer.productCodesForAmmount.split(',') : [];
            setAmount(customer.amountToBePrepaid!);
            setProductCodesList([...productCodesForAmount]);

            const productCodesForPallets = customer.productCodesForPalletNr ? customer.productCodesForPalletNr.split(',') : [];
            setPalletAmount(customer.palletNrToBePrepaid!);
            setPalletProductCodesList(productCodesForPallets);
        }
    }, [customer]);

    useEffect(() => {
        if (!amount) {
            setErrors({ ...errors, productCodesList: '' });
            setProductCodesList([]);
        }
    }, [amount]);

    useEffect(() => {
        if (!palletAmount) {
            setErrors({ ...errors, palletProductCodesList: '' });
            setPalletProductCodesList([]);
        }
    }, [palletAmount]);

    useEffect(() => {
        setFreight(getInitialFreight());
        setIncoterm(getInitialIncoterm());
    }, [freights, incoterms]);

    const onSubmitClick = () => {
        let productCodesListString = '';
        let palletProductCodesListString = '';

        if (productCodesList.length === 1 && productCodesList[0] === 'Any') {
            productCodesListString = productCodes.join(',');
        } else {
            productCodesListString = productCodesList.join(',');
        }

        if (palletProductCodesList.length === 1 && palletProductCodesList[0] === 'Any') {
            palletProductCodesListString = productCodes.join(',');
        } else {
            palletProductCodesListString = palletProductCodesList.join(',');
        }

        const newCustomer: ISaveCustomerReq = {
            ...customer,
            isEditMode: true,
            amountToBePrepaid: amount,
            productCodesForAmmount: productCodesListString,
            productCodesForPalletNr: palletProductCodesListString,
            palletNrToBePrepaid: palletAmount,
            freightPreference: freight?.name,
            dfltIncoTerm: incoterm?.code,
        };

        _saveCustomer(newCustomer);
    };

    const isButtonDisabled = () => {
        if (isEmpty(freight)) {
            return true;
        }

        if (freight && displayDropdowns && !amount && !palletAmount) {
            return true;
        }

        if (amount && !productCodesList.length) {
            return true;
        }

        if (palletAmount && !palletProductCodesList.length) {
            return true;
        }

        return false;
    };

    const handleDropdownChange = (key: string, valueObj: any, callback: any, required?: boolean) => {
        callback && callback();

        if (isEmpty(valueObj) && required) {
            setErrors({ ...errors, [key]: 'Required' });
        } else {
            setErrors({ ...errors, [key]: '' });
        }
    };

    const handleMultipleDropdownChange = (key: string, valueObj: any, callback: any, relatedValue: any) => {
        callback && callback();

        if (relatedValue && isEmpty(valueObj)) {
            setErrors({ ...errors, [key]: 'Required' });
        } else {
            setErrors({ ...errors, [key]: '' });
        }
    };

    return render();
};
