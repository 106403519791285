import { IOrderList, IOrderStatus } from "../interfaces/order-list";

import { IOrderListRequest } from "../interfaces/order-list-request";
import { IShipVia } from "../../shared/interfaces/ship-via";
import { SortOrder } from "antd/lib/table/interface";

export class OrderListService {
    public static readonly STATUSES: IOrderStatus[] = [
        { id: 1, name: 'Pending Validation' },
        { id: 2, name: 'Validation Error' },
        { id: 3, name: 'Waiting SOA Confirmation' },
        { id: 4, name: 'Created' },
        { id: 5, name: 'Creation Error' },
        { id: 6, name: 'Archived' },
        { id: 7, name: 'Waiting ERP Confirmation' },
    ];

    public static getCurrentStatus(id: number) {
        return this.STATUSES.filter(status => status.id === id)[0];
    }

    public static getCurrentShipVia(id: number, shipVias: IShipVia[]) {
        return shipVias.filter(sv => sv.id === id)[0];
    }

    public static getRevisedTotalsFromOrderList(orderList: IOrderList) {
        let quantity = 0;
        let unitPrice = 0;

        orderList.customerPoLines.forEach(line => {
            if (line.revisedQuantity) {
                quantity += line.revisedQuantity;
            }

            if (line.revisedUnitPrice) {
                unitPrice += line.revisedUnitPrice;
            }
        });

        return { quantity, unitPrice: unitPrice.toFixed(2) };
    }

    public static getSearchStringFromRequest(request: IOrderListRequest) {
        let searchString = '?';
        const { status, currency, company, customerId, poNumber, priceFrom, priceTo, pageNumber, pageSize, soaId, sortColumn, sortOrder } = request;

        if (status) {
            searchString += `status=${status}&`;
        }

        if (currency) {
            searchString += `currency=${currency}&`;
        }

        if (company) {
            searchString += `company=${company}&`;
        }

        if (customerId) {
            searchString += `customerId=${customerId}&`;
        }

        /** @TODO Check here encodeURIComponent */
        if (poNumber) {
            searchString += encodeURIComponent(`poNumber=${poNumber}&`);
        }

        if (priceFrom) {
            searchString += `priceFrom=${priceFrom}&`;
        }

        if (priceTo) {
            searchString += `priceTo=${priceTo}&`;
        }

        if (soaId) {
            searchString += `soaId=${soaId}&`;
        }

        if (sortColumn) {
            searchString += `sortColumn=${sortColumn}&`;
        }

        if (sortOrder) {
            searchString += `sortOrder=${sortOrder}&`;
        }

        searchString += `pageNumber=${pageNumber}&pageSize=${pageSize}`;

        return searchString;
    }

    public static getSortColumnFromQueryParams(queryParams: any) {
        if (queryParams && queryParams.sortColumn) {
            const order: SortOrder = queryParams.sortOrder && queryParams.sortOrder === '1' ? 'ascend' : 'descend';

            return {
                column: queryParams.sortColumn,
                order,
            };
        }

        return {
            column: '',
            order: null,
        };
    }
}
