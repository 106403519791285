import { Observable, from } from "rxjs";

import { IPoType } from "../interfaces/po-type";
import { IShipVia } from "../interfaces/ship-via";
import { ISiteId } from "../interfaces/site-id";
import { axios } from "../services/axios.service";

export class SharedWebApi {
    public static getPoTypes(): Observable<IPoType[]> {
        return from<Promise<IPoType[]>>(
            axios.get<IPoType[]>('/PoTypes')
                .then(res => res.data),
        );
    }

    public static getShipVia(): Observable<IShipVia[]> {
        return from<Promise<IShipVia[]>>(
            axios.get<IShipVia[]>(`/ShipVia`)
                .then(res => res.data),
        );
    }

    public static getFobs(): Observable<string[]> {
        return from<Promise<string[]>>(
            axios.get<string[]>(`/ErpCustomer/allFobs`)
                .then(res => res.data),
        );
    }

    public static getSiteIds(): Observable<ISiteId[]> {
        return from<Promise<ISiteId[]>>(
            axios.get<ISiteId[]>(`/Site`)
                .then(res => res.data),
        );
    }

    public static getAllPartIds(): Observable<string[]> {
        return from(
            axios.get('/Part/allPartIds')
                .then(res => res.data),
        );
    }

    public static getCountryCodes(): Observable<string[]> {
        return from(
            axios.get('/Country')
                .then(res => res.data),
        );
    }
}
