import * as React from 'react';
import * as styled from './dropdown.style';

import { Select } from 'antd';

interface Props {
    /** Object key to be displayed when selected */
    keyToShow: string;

    options: any[];

    value?: any;

    onChange: (option: any) => void;

    label?: string;

    placeholder?: string;

    error?: string;

    disabled?: boolean;

    required?: boolean;

    allowClear?: boolean;

    mode?: 'multiple';

    placement?: string;

    overrides?: {
        root?: string;
    }
}

export const Dropdown: React.FC<Props> = (props: Props) => {
    const { label, options, error, keyToShow, onChange, value, disabled, required, allowClear, mode, overrides, placement } = props;

    const render = () => {
        return (
            <styled.Wrapper hasError={!!error} overrides={overrides && overrides.root}>

                {/** Label */}
                {
                    label &&
                    <styled.Label>
                        {label}
                        {
                            required && <span>*</span>
                        }
                    </styled.Label>
                }

                <Select showSearch={true}
                    onChange={value => onSelectChange(value)}
                    defaultValue={keyToShow && value ? value[keyToShow] : value}
                    allowClear={allowClear}
                    className={mode ? 'multiple-select' : ''}
                    getPopupContainer={(trigger: any) => trigger.parentNode}
                    mode={mode}
                    dropdownAlign={'bottomRight'}
                    value={keyToShow && value ? value[keyToShow] : value}
                    disabled={disabled ? disabled : false}>
                    {
                        options.map(option =>
                            <Select.Option value={JSON.stringify(option)}
                                key={keyToShow ? option[keyToShow] : option}>
                                {
                                    keyToShow ? option[keyToShow] : option
                                }
                            </Select.Option>
                        )
                    }
                </Select>

                {/** Error */}
                {
                    error &&
                    <styled.Error>
                        {error}
                    </styled.Error>
                }

            </styled.Wrapper>
        );
    };

    const onSelectChange = (value: any) => {
        if (mode) {
            onChange(value);
        } else
        if (value) {
            onChange(JSON.parse(value));
        } else {
            onChange('');
        }
    }

    return render();
};
