import 'jspdf-autotable';

import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import * as pdfMake from "pdfmake/build/pdfmake";

import { AppState } from '../../../shared/interfaces/app.state';
import { Button } from '../../../shared/components/button/button';
import { ButtonTypes } from '../../../shared/components/button/button.utils';
import { ErpShippingInfoLineTable } from '../erp-shipping-info-line-table/erp-shipping-info-line-table';
import { ErpShippingInfoTableUtils } from './erp-shipping-info-table.utils';
import { ExpandableConfig } from 'antd/lib/table/interface';
import { GeckoTable } from '../../../shared/components/gecko-table/gecko-table';
import { IErpShipper } from '../../interfaces/order';
import { ITokenUserRole } from '../../../users/interfaces/token-user-role';
import React from 'react';
import { Theme } from '../../../shared/styles/theme';
import { useSelector } from 'react-redux'

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

// tslint-disabled
interface Props {
    showFilters?: boolean;
    showExport?: boolean;
    showExpandedView?: boolean;
    erpShippers: IErpShipper[];
}

export const ErpShippingInfoTable: React.FC<Props> = (props: Props) => {
    const { showFilters, erpShippers, showExpandedView } = props;

    const tokenUserRole = useSelector<AppState, ITokenUserRole | null>(state => state.users.tokenUserRole);
    const downloadInvoiceLoading = useSelector<AppState, boolean>(state => state.orders.downloadInvoiceLoading);

    const render = () => {
        return (
            <>
                <Theme.SpaceBetween>
                    {
                        showFilters &&
                        <Button type={ButtonTypes.PRIMARY}
                            label={'Filters'}
                            disabled={true} />
                    }
                </Theme.SpaceBetween>

                <GeckoTable dataSource={{ data: erpShippers }}
                    columns={ErpShippingInfoTableUtils.shipperColumns(tokenUserRole)}
                    expandable={showExpandedView ? expandable() : undefined}
                    filters={[]}
                    isLoading={downloadInvoiceLoading}
                    rowKey={(shipper: IErpShipper) => shipper.id + shipper.erpOrderHeaderId}
                    tableId={'erpShippingInfo'} />
            </>
        );
    };

    const expandable = (): ExpandableConfig<IErpShipper> => {
        return {
            expandedRowRender: (erpShipper: IErpShipper) => <ErpShippingInfoLineTable lines={erpShipper.erpShipperLines} />,
            expandedRowClassName: () => 'table-expanded-row',
        };
    };

    return render();
};
