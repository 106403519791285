import { colors } from '../../styles/colors';
import { fonts } from '../../styles/fonts';
import styled from 'styled-components';

interface InputProps {
    hasError: boolean;
}

interface WrapperProps {
    overrides?: string;
}

export const Wrapper = styled.div<WrapperProps>`
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    width: 100%;
    margin-bottom: 5px;

    ${props => props.overrides};
`;

export const InputValue = styled.input<InputProps>`
    width: 100%;
    height: 48px;
    border-radius: 4px;
    font-size: 14px;
    color: ${colors.$darkBlue};
    background-color: ${colors.$white};
    outline: none;
    padding-left: 16px;
    padding-right: 16px;
    line-height: 1.14;
    border: 1px solid ${props => getInputBorderColor(props)};
    transition: 0.4s;

    &:hover, &:focus {
        background-color: ${colors.$white};
        box-shadow: 0 3px 20px 0 rgba(101, 152, 184, 0.3);
    }

   &:disabled {
        background-color: ${colors.$disabled};
        color: black;

        &:hover, &:focus {
            cursor: not-allowed;
            box-shadow: none;
        }
   }
`;

export const Label = styled.div`
    font-size: 12px;
    font-weight: bold;
    font-family: ${fonts.proxima};
    color: ${colors.$darkBlue};
    line-height: 1.17;
    margin-bottom: 5px;
`;

export const Error = styled.div`
    margin-top: 5px;
    font-size: 12px;
    font-weight: bold;
    font-family: ${fonts.proxima};
    color: ${colors.$alert};
    line-height: 1.17;
    width: 180px;
`;

// ===== UTILS =====

function getInputBorderColor(props: InputProps) {
    if (props.hasError) {
        return colors.$alert;
    }

    return colors.$blue;
}
