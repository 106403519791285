import { IAzureADFunctionProps } from "react-aad-msal";
import { NewEntryPage } from "../pages/new-entry.page";
import React from 'react';
import { Route } from "react-router";

export const foeRoutes = (azureAd: IAzureADFunctionProps) => {
    return [
        <Route exact={true}
            path={'/fast-order-entry/new-order'}
            key={'foe'}
            render={props => <NewEntryPage {...props} {...azureAd} /> } />,
    ];
};
