import * as styles from './order-list-filters.style';

import { IFilterObjectData, IOrderListFilters } from '../../interfaces/order-list';
import React, { useEffect, useState } from 'react';

import { AppState } from '../../../shared/interfaces/app.state';
import { Button } from '../../../shared/components/button/button';
import { ButtonTypes } from '../../../shared/components/button/button.utils';
import { CURRENCIES } from '../../../shared/services/utils.service';
import { Dropdown } from '../../../shared/components/dropdown/dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICompany } from '../../../companies/interfaces/company';
import { ICurrency } from '../../../shared/interfaces/currency';
import { ICustomer } from '../../../customers/interfaces/customer';
import { IFilterSoa } from '../../../soa/interfaces/soa';
import { IOrderListRequest } from '../../interfaces/order-list-request';
import { Input } from '../../../shared/components/input/input';
import { OrderListFiltersUtils } from './order-list-filters.utils';
import { OrderListService } from '../../services/order-list.service';
import { OrderListStateService } from '../../services/order-list-state.service';
import { SectionTitle } from '../../../shared/components/section-title/section-title';
import { colors } from '../../../shared/styles/colors';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

interface Props {
    params: IOrderListRequest;
    soas: IFilterSoa[];
    isAdmin?: boolean;
}

export const OrderListFilters: React.FC<Props> = (props: Props) => {
    const { params, soas, isAdmin } = props;

    const history = useHistory();

    // store
    // const filters = useSelector<AppState, IOrderListFilters>(state => state.orderList.filters);
    // const orderLists = useSelector<AppState, IOrderList[]>(state => state.orderList.orderLists);
    const companyBreadcrumb = useSelector<AppState, ICompany>(state => state.shared.breadcrumbs.company);
    const customerBreadcrumb = useSelector<AppState, ICustomer>(state => state.shared.breadcrumbs.customer);

    const filterCompanies = useSelector<AppState, IFilterObjectData[]>(state => state.companies.filters.companies);
    const filterExternalCustomerIds = useSelector<AppState, IFilterObjectData[]>(state => state.customers.filters.externalCustomerIds);

    // state
    const [status, setStatus] = useState(OrderListFiltersUtils.getStatusInitValue(params.status));
    const [currency, setCurrency] = useState(OrderListFiltersUtils.getCurrencyInitValue(params.currency));
    const [company, setCompany] = useState(OrderListFiltersUtils.getCompanyInitValue(filterCompanies, params.company, companyBreadcrumb, isAdmin));
    const [externalCustomerId, setExternalCustomerId] = useState(OrderListFiltersUtils.getExternalCustomerIdInitValue(filterExternalCustomerIds, params.customerId,customerBreadcrumb, isAdmin));
    const [poNumber, setPoNumber] = useState(OrderListFiltersUtils.getPoNumberInitValue(params.poNumber));
    const [soa, setSoa] = useState(OrderListFiltersUtils.getFilterSoaInitValue(soas, params.soaId));

    const [inputs, setInputs] = useState({
        quantityMin: '',
        quantityMax: '',
        shippedDateFrom: null,
        shippedDateTo: null,
        orderDateFrom: null,
        orderDateTo: null,
        expectedDateFrom: null,
        expectedDateTo: null,
        priceFrom: params.priceFrom || '',
        priceTo: params.priceTo || '',
    });

    const render = () => {
        return (
            <styles.Wrapper>
                <styles.Row>
                    <FontAwesomeIcon icon={faTimes}
                        cursor='pointer'
                        color={colors.$darkBlue}
                        size='2x'
                        onClick={() => onCloseClick()} />
                </styles.Row>

                <SectionTitle text={'Filters'} />

                <styles.FormWrapper>
                    {/** Order Status */}
                    <styles.Row>
                        <Dropdown label={'Status'}
                            keyToShow={'name'}
                            allowClear={true}
                            options={OrderListService.STATUSES}
                            onChange={status => setStatus(status)}
                            value={status} />
                    </styles.Row>

                    {/** Currency */}
                    <styles.Row>
                        <Dropdown label={'Currency'}
                            options={CURRENCIES}
                            keyToShow={'name'}
                            allowClear={true}
                            onChange={currency => setCurrency(currency)}
                            value={currency}
                        />
                    </styles.Row>

                    {/** SOAs */}
                    <styles.Row>
                        <Dropdown label={'SOA'}
                            options={soas}
                            keyToShow={'value'}
                            allowClear={true}
                            value={soa}
                            onChange={soa => setSoa(soa)} />
                    </styles.Row>

                    {/** Company Name */}
                    <styles.Row>
                        <Dropdown label={'Company'}
                            options={filterCompanies}
                            keyToShow={'value'}
                            allowClear={true}
                            value={company}
                            disabled={isAdmin ? isAdmin : false}
                            onChange={company => setCompany(company)} />
                    </styles.Row>

                    {/** External Customer ID */}
                    <styles.Row>
                        <Dropdown label={'Customer ID'}
                            options={filterExternalCustomerIds}
                            keyToShow={'value'}
                            allowClear={true}
                            disabled={isAdmin ? isAdmin : false}
                            value={externalCustomerId}
                            onChange={externalCustomerId => setExternalCustomerId(externalCustomerId)} />
                        {/* <Input label={'Customer ID'}
                            value={externalCustomerId}
                            disabled={isAdmin ? isAdmin : false}
                            onChange={externalCustomerId => setExternalCustomerId(externalCustomerId)} /> */}
                    </styles.Row>

                    {/** PO Number */}
                    <styles.Row>
                        {/* <Search value={poNumber}
                            onChange={poNumber => setPoNumber(poNumber)}
                            onSearch={onPoNumberSearch}
                            data={poNumbers}
                            showKey={''}
                            searchAfter={3}
                            label={'PO Number'} /> */}
                        <Input label={'PO Number'}
                            value={poNumber}
                            onChange={poNumber => setPoNumber(poNumber)} />
                    </styles.Row>

                    {/** Quantity */}
                    {/* <styles.Row>
                        <styles.RowTitle>
                            Quantity
                        </styles.RowTitle>

                        <styles.RowBody>
                            <styles.RowColumn paddingRight='10px'>
                                <Input label={'Min'}
                                    value={inputs.quantityMin}
                                    onChange={min => setInputs({ ...inputs, quantityMin: min })}
                                />
                            </styles.RowColumn>

                            <styles.RowColumn paddingLeft='10px'>
                                <Input label={'Max'}
                                    value={inputs.quantityMax}
                                    onChange={max => setInputs({ ...inputs, quantityMax: max })}
                                />
                            </styles.RowColumn>
                        </styles.RowBody>
                    </styles.Row> */}

                    {/** Shipped Date */}
                    {/* <styles.Row>
                        <styles.RowTitle>
                            Shipped Date
                        </styles.RowTitle>

                        <styles.RowBody>
                            <styles.RowColumn paddingRight='10px'>
                                <DateSelector value={inputs.shippedDateFrom}
                                    onChange={value => setInputs({ ...inputs, shippedDateFrom: value })}
                                    label={'From'}
                                />
                            </styles.RowColumn>

                            <styles.RowColumn paddingLeft='10px'>
                                <DateSelector label={'To'}
                                    value={inputs.shippedDateTo}
                                    onChange={value => setInputs({ ...inputs, shippedDateTo: value })}
                                />
                            </styles.RowColumn>
                        </styles.RowBody>
                    </styles.Row> */}

                    {/** Order Date */}
                    {/* <styles.Row>
                        <styles.RowTitle>
                            Order Date
                        </styles.RowTitle>

                        <styles.RowBody>
                            <styles.RowColumn paddingRight='10px'>
                                <DateSelector value={inputs.orderDateFrom}
                                    onChange={value => setInputs({ ...inputs, orderDateFrom: value })}
                                    label={'From'}
                                />
                            </styles.RowColumn>

                            <styles.RowColumn paddingLeft='10px'>
                                <DateSelector label={'To'}
                                    value={inputs.orderDateTo}
                                    onChange={value => setInputs({ ...inputs, orderDateTo: value })}
                                />
                            </styles.RowColumn>
                        </styles.RowBody>
                    </styles.Row> */}

                    {/** Expected Date */}
                    {/* <styles.Row>
                        <styles.RowTitle>
                            Expected Date
                        </styles.RowTitle>

                        <styles.RowBody>
                            <styles.RowColumn paddingRight='10px'>
                                <DateSelector value={inputs.expectedDateFrom}
                                    onChange={value => setInputs({ ...inputs, expectedDateFrom: value })}
                                    label={'From'}
                                />
                            </styles.RowColumn>

                            <styles.RowColumn paddingLeft='10px'>
                                <DateSelector label={'To'}
                                    value={inputs.expectedDateTo}
                                    onChange={value => setInputs({ ...inputs, expectedDateTo: value })}
                                />
                            </styles.RowColumn>
                        </styles.RowBody>
                    </styles.Row> */}

                    {/** Price */}
                    <styles.Row>
                        <styles.RowTitle>
                            Price
                        </styles.RowTitle>

                        <styles.RowBody>
                            <styles.RowColumn paddingRight='10px'>
                                <Input label={'From'}
                                    value={inputs.priceFrom}
                                    onChange={priceFrom => setInputs({ ...inputs, priceFrom })} />

                            </styles.RowColumn>

                            <styles.RowColumn paddingLeft='10px'>
                                <Input label={'To'}
                                    value={inputs.priceTo}
                                    onChange={priceTo => setInputs({ ...inputs, priceTo })} />
                            </styles.RowColumn>

                        </styles.RowBody>
                    </styles.Row>

                    <styles.Row>
                        <styles.RowBody>
                            <Button type={ButtonTypes.PRIMARY}
                                onClick={() => onApplyClick()}
                                label={'Apply Filters'} />

                            <Button type={ButtonTypes.SECONDARY}
                                onClick={() => onClearClick()}
                                label={'Clear filters'} />
                        </styles.RowBody>
                    </styles.Row>

                </styles.FormWrapper>
            </styles.Wrapper>
        );
    };

    useEffect(() => {
        const filters: IOrderListFilters = {
            status,
            currency,
            company: company.value,
            externalCustomerId: externalCustomerId.value,
            poNumber,
            priceTo: inputs.priceTo,
            priceFrom: inputs.priceFrom,
        };

        OrderListStateService.setFilters(filters);
    }, [status, currency, company, externalCustomerId, poNumber, inputs.priceTo, inputs.priceFrom]);

    const onCloseClick = () => {
        OrderListStateService.toggleFilters(false);
    };

    const onClearClick = () => {
        if (!isAdmin) {
            setCompany({} as IFilterObjectData);
            setExternalCustomerId({} as IFilterObjectData);
        }

        setStatus(null);
        setPoNumber('');
        setCurrency({} as ICurrency);
        setSoa({} as IFilterSoa);
        setInputs({
            ...inputs,
            priceFrom: '',
            priceTo: '',
        });

        OrderListStateService.setFilters({} as IOrderListFilters);
    };

    const onApplyClick = () => {
        let searchString = '?';

        if (status) {
            searchString += `status=${status.id}&`;
        }

        if (currency && currency.name) {
            searchString += `currency=${currency.name}&`;
        }

        if (company && company.id) {
            searchString += `company=${company.id}&`;
        }

        if (externalCustomerId && externalCustomerId.id) {
            searchString += `customerId=${externalCustomerId.id}&`;
        }

        if (poNumber) {
            searchString += `poNumber=${encodeURIComponent(poNumber)}&`;
        }

        if (inputs.priceFrom) {
            searchString += `priceFrom=${inputs.priceFrom}&`;
        }

        if (inputs.priceTo) {
            searchString += `priceTo=${inputs.priceTo}&`;
        }

        if (soa && soa.id) {
            searchString += `soaId=${soa.id}&`;
        }

        searchString += `pageNumber=1&pageSize=20`;

        history.push({
            pathname: history.location.pathname,
            search: searchString,
        });

        OrderListStateService.toggleFilters(false);
    };

    return render();
};
