import { IHeaderTile } from "../../shared/components/header-tiles/header-tile.interface";
import { ROLES } from "../../users/interfaces/roles";
import { store } from "../../shared/services/state.service";

export const SUBHEADER_TILES = (): IHeaderTile[] => {
    const role = store.getState().users.tokenUserRole;

    const isOrderLineDisabled = () => {
        if (role) {
            if (role.role === ROLES.Client || role.role === ROLES.ClientFinance) {
                return true;
            }

            return false;
        }

        return false;
    };

    return [
        {
            path: "/orders/orders",
            label: "Orders"
        },
        {
            path: "/orders/invoice",
            label: "Invoice"
        },
        {
            path: "/orders/order-line",
            label: "Order Line",
            disabled: isOrderLineDisabled(),
        },
        {
            path: "/orders/timeline",
            label: "Timeline"
        }
    ];
}