import { catchError, concatMap, map } from "rxjs/operators";

import { Action } from "../../shared/interfaces/shared";
import { ActionsObservable } from "redux-observable";
import { FoeActions } from "./foe.actions";
import { FoeWebApi } from "../webapi/foe.webapi";
import { IFoeOrder } from "../interfaces/foe";
import { SnackbarTypes } from "../../shared/components/snackbar/snackback.utils";
import { _setSnackbar } from "../../shared/services/shared.state-service";
import { of } from "rxjs";

export const createFoe$ = (action$: ActionsObservable<Action<IFoeOrder>>) =>
    action$.ofType(FoeActions.CREATE_FOE_REQ).pipe(
        map(action => action.payload!),
        concatMap(order => FoeWebApi.createFoe(order).pipe(
            map(_response => {
                _setSnackbar({
                    isOpen: true,
                    message: 'Successfully created order',
                    type: SnackbarTypes.SUCCESS,
                });

                return FoeActions.createFoeOk('Success');
            }),
            catchError(err => {
                _setSnackbar({
                    isOpen: true,
                    message: err ? err.description : 'An error occured. Try again later',
                    type: SnackbarTypes.ERROR,
                });

                return of(FoeActions.createFoeFail(err.response && err.response.data));
            }),
        )),
    );
