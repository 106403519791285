import * as companiesEpics from '../../companies/state/companies.epics';
import * as customersEpics from '../../customers/state/customers.epics';
import * as foeEpics from '../../foe/state/foe.epics';
import * as notificationsEpics from '../../notifications/state/notifications.epics';
import * as orderLineEpics from '../../order-line/state/order-line.epics';
import * as orderListEpics from '../../order-list/state/order-list.epics';
import * as ordersConfirmationEpics from '../../orders-confirmation/state/orders-confirmation.epics';
import * as ordersEpics from '../../orders/state/orders.epics';
import * as sharedEpics from './shared.epics';
import * as soaEpics from '../../soa/state/soa.epics';
import * as dayOffEpics from '../../day-off/state/day-off.epics';
import * as usersEpics from '../../users/state/users.epics';

import { Epic, combineEpics } from 'redux-observable';

import { Action } from '../interfaces/shared';
import { AppState } from '../interfaces/app.state';

export const appEpics: Epic<Action<AppState>, any> = combineEpics(
    ...getEpicsArr(companiesEpics),
    ...getEpicsArr(customersEpics),
    ...getEpicsArr(notificationsEpics),
    ...getEpicsArr(orderListEpics),
    ...getEpicsArr(orderLineEpics),
    ...getEpicsArr(ordersEpics),
    ...getEpicsArr(ordersConfirmationEpics),
    ...getEpicsArr(usersEpics),
    ...getEpicsArr(soaEpics),
    ...getEpicsArr(sharedEpics),
    ...getEpicsArr(foeEpics),
    ...getEpicsArr(dayOffEpics),
);

/** Converting from import object to array. */
function getEpicsArr(epics: any): any[] {
    let epicsArr: any[] = [];
    let epicsNames = Object.keys(epics);

    // Array is needed for combineEpics.
    epicsNames.forEach(epicName =>
        epicsArr.push(epics[epicName]),
    );

    return epicsArr;
}
