import * as actions from '../state/customers.actions';
import * as selectors from '../state/customers.selectors';

import { ICustomer, ICustomerInventoryShippingAddress, ISaveCustomerReq } from "../interfaces/customer";
import { distinctUntilChanged, map } from 'rxjs/operators';
import { store, store$ } from "../../shared/services/state.service";

import { Observable } from "rxjs";

/** @TODO add other methods inside the class */
export class CustomersStateService {
    public static getBreadcrumbCustomer(id: string): void {
        store.dispatch(
            actions.getBreadcrumbCustomerReq(id),
        );
    }

    public static setBreadcrumbCustomer(customer: ICustomer): void {
        store.dispatch(
            actions.setBreadcrumbCustomer(customer),
        );
    }

    public static setErpCustomerIds(ids: string[]): void {
        store.dispatch(
            actions.setErpCustomerIds(ids),
        );
    }

    public static getFilterExternalCustomerIds(): void {
        store.dispatch(
            actions.getFilterExternalCustomerIdsReq(),
        );
    }

    public static getParsers(): void {
        store.dispatch(
            actions.getParsersReq(),
        );
    }

    public static getCustomerIdsFromErpCompany(companyName: string): void {
        store.dispatch(
            actions.getCustomersFromErpCompanyReq(companyName),
        );
    }

    public static getErpCustomerIds(): void {
        store.dispatch(
            actions.getErpCustomerIdsReq(),
        );
    }

    public static toggleErpCustomerIdsLoading(flag: boolean): void {
        store.dispatch(
            actions.toggleErpCustomerIdsLoading(flag),
        );
    }

    public static getProductCodes(externalId: string): void {
        store.dispatch(
            actions.getProductCodesReq(externalId),
        );
    }

    public static getFreights(): void {
        store.dispatch(
            actions.getFreightsReq(),
        );
    }

    public static getIncoterm(): void {
        store.dispatch(
            actions.getIncotermReq(),
        );
    }

    public static getCustomerParts(externalId: string): void {
        store.dispatch(
            actions.getCustomerPartsReq(externalId),
        );
    }

    public static getCustomerDetails(externalId: string): void {
        store.dispatch(
            actions.getCustomerDetailsReq(externalId),
        );
    }

    public static getShippingAddress(externalId: string): void {
        store.dispatch(
            actions.getShippingAddressesReq(externalId),
        );
    }

    public static getCustomerByExternalId(externalId: string): void {
        store.dispatch(
            actions.getCustomerByExternalIdReq(externalId),
        );
    }

    public static getErpCustomersForUser(): void {
        store.dispatch(
            actions.getErpCustomersForUserReq(),
        );
    }

    public static resetCustomerByExternalIdError(): void {
        store.dispatch(
            actions.resetCustomerByExternalIdError(),
        );
    }

    public static toggleInventoryAddressModal(flag: boolean): void {
        store.dispatch(
            actions.toggleSaveInventoryAddressModal(flag),
        );
    }

    public static setCurrentInventoryAddress(address: ICustomerInventoryShippingAddress): void {
        store.dispatch(
            actions.setCurrentInventoryAddress(address),
        );
    }

}

export const customers$ = (): Observable<ICustomer[]> => store$.pipe(
    map(state => selectors.CUSTOMERS(state)),
    distinctUntilChanged(),
);

export const _getCustomersByCompanyName = (name: string): void => {
    store.dispatch(
        actions.getCustomersByCompanyNameReq(name),
    );
};

export const _getCustomersByCompanyId = (id: string | number): void => {
    store.dispatch(
        actions.getCustomersByCompanyIdReq(id),
    );
};


export const _getCustomerById = (id: number): void => {
    store.dispatch(
        actions.getCustomerByIdReq(id),
    );
};

export const _resetCustomers = (): void => {
    store.dispatch(
        actions.resetCustomers(),
    );
};

export const _resetCustomer = (): void => {
    store.dispatch(
        actions.resetCustomer(),
    );
};

export const _toggleCustomersLoading = (flag: boolean): void => {
    store.dispatch(
        actions.toggleCustomersLoading(flag),
    );
};

export const customersLoading$ = (): Observable<boolean> => store$.pipe(
    map(state => selectors.CUSTOMERS_LOADING(state)),
    distinctUntilChanged(),
);

export const customersError$ = (): Observable<string> => store$.pipe(
    map(state => selectors.CUSTOMERS_ERROR(state)),
    distinctUntilChanged(),
);

export const currentCustomer$ = (): Observable<ICustomer> => store$.pipe(
    map(state => selectors.CURRENT_CUSTOMER(state)),
);

export const _setCurrentCustomer = (customer: ICustomer): void => {
    store.dispatch(
        actions.setCurrentCustomer(customer),
    );
};

// ===== DELETE CUSTOMER =====

export const _toggleDeleteCustomerModal = (flag: boolean): void => {
    store.dispatch(
        actions.toggleDeleteCustomerModal(flag),
    );
};

export const _deleteCustomer = (customer: ICustomer): void => {
    store.dispatch(
        actions.deleteCustomerReq(customer),
    );
};

// ===== SAVE CUSTOMER =====

export const _toggleSaveCustomerModal = (flag: boolean): void => {
    store.dispatch(
        actions.toggleSaveCustomerModal(flag),
    );
};

export const _saveCustomer = (request: ISaveCustomerReq): void => {
    store.dispatch(
        actions.saveCustomerReq(request),
    );
};

