import { ButtonWrapper, FormWrapper } from './create-day-off-modal.style';

import { ActivityIndicator } from '../../../shared/components/activity-indicator/activity-indicator';
import { Button } from '../../../shared/components/button/button';
import { IDayOff } from '../../interfaces/day-off';
import { IDayOffFormValues } from '../../interfaces/day-off-form-values';
import { Modal } from '../../../shared/components/modal/modal';
import React from 'react';
import { createDayOffFormConstants as consts } from './create-day-off-modal.constants';
import { isDate, isEmpty } from 'lodash'
import { DateSelector } from '../../../shared/components/date-selector/date-selector';
import moment from 'moment';
import { Dropdown } from '../../../shared/components/dropdown/dropdown';
import { ISiteId } from '../../../shared/interfaces/site-id';
import { _setDefaultSiteId } from '../../services/day-off-state.service';
import { useSelector } from 'react-redux';
import { AppState } from '../../../shared/interfaces/app.state';

interface Props {
    onSubmit: (values: IDayOffFormValues) => void;

    onSubmitEdit?: (values: IDayOff) => void;

    onClose: () => void;
    /** initial values for edit mode */
    currentDayOff: IDayOff;
    /** modal loading */
    loading?: boolean;

    isEditMode?: boolean;
    siteIDs: ISiteId[];
}

/** Create Day Off Modal */
export const CreateDayOffModal: React.FC<Props> = (props: Props) => {
    const { 
        onSubmit,
        onSubmitEdit,
        onClose,
        currentDayOff: currentDayOff,
        loading,
        isEditMode,
        siteIDs
    } = props;

    /** fields values */
    const [inputs, setInputs] = React.useState({
        siteId: (currentDayOff && currentDayOff.siteId) || '',
        day: (currentDayOff && currentDayOff.day && moment(currentDayOff.day)) || null
    });

    const [errors, setErrors] = React.useState({
        siteId: '',
        day: ''
    });

    const handleValidation = (text: string | Date, key: string, type: string, required?: boolean) => {
        switch (type) {
            case 'date': {
                if (!text && required) {
                    setErrors({ ...errors, [key]: 'Required' })
                } else {
                    setErrors({ ...errors, [key]: '' })
                }
                break;
            }
            case 'string': {
                if (isEmpty(text) && required) {
                    setErrors({ ...errors, [key]: 'Required' })
                } else {
                    setErrors({ ...errors, [key]: '' })
                }
            }
        }
    }

    const isSaveButtonDisabled = () => {
        const { siteId, day } = inputs;

        let isDisabled = false;

        if ( !siteId || !day) {
            isDisabled = true
        }

        Object.keys(errors).forEach(key => {
            if ((errors as any)[key]) {
                isDisabled = true;
            }
        });

        return isDisabled;
    };

    const handleSubmit = () => {
            const submitInputs = {
                ...inputs,
                day: inputs.day ? moment(inputs.day).format('MM/DD/YYYY') : '',
            };
            onSubmit(submitInputs)
    }

    const getConfirmedDate = () => {
        if (!isEmpty(currentDayOff)) {
            if (currentDayOff.day && isDate(new Date(currentDayOff.day))) {
                return moment(currentDayOff.day);
            }
        }

        return null;
    };

    const handleDropdownChange = (key: string, valueObj: any, type: string, required?: boolean) => {
        _setDefaultSiteId(valueObj);
        let newInputs = { ...inputs, [key]: valueObj.siteId };

        setInputs(newInputs);

        handleValidation(valueObj, key, type, required);
    };

    const onInputChange = (text: string | Date, key: string, type: string, required?: boolean) => {
        let newInputs = { ...inputs, [key]: text };

        setInputs(newInputs);

        handleValidation(text, key, type, required)
    };

    const getInitialSiteId = () => {
        if (!isEmpty(currentDayOff) && currentDayOff.siteId) {
            let foundSiteId = siteIDs.find(s => s.siteId === currentDayOff.siteId);

            return foundSiteId;
        }

        return {} as ISiteId;
    };

    const [siteId, setSiteId] = React.useState(getInitialSiteId());

    const defaultSiteId = useSelector<AppState, ISiteId | undefined>(state => 
        { 
            if (state.dayOff?.defaultSiteId?.siteId)
            {
                inputs.siteId = state.dayOff?.defaultSiteId?.siteId;
            }
            return state.dayOff?.defaultSiteId
        });
    
    if (loading) {
        return (
            <Modal onClose={() => onClose()} >
                <ActivityIndicator />
            </Modal>
        )
    } else {
        return (
            <Modal onClose={() => onClose()}>
                <FormWrapper>
                     {/** Site ID */}
                     <Dropdown options={siteIDs}
                        keyToShow={'siteId'}
                        label={'Site ID'}
                        required={true}
                        error={errors.siteId}
                        allowClear={true}
                        value = {defaultSiteId}
                        onChange={siteId => handleDropdownChange('siteId', siteId, 'string', true)} />
                    <DateSelector
                                value={inputs.day}
                                label={consts.day}
                                error={errors.day}
                                required={true}
                                onChange={(text: Date) => onInputChange(text, 'day', 'date', true)}
                            />
                    <ButtonWrapper>
                        <Button disabled={isSaveButtonDisabled()} label={'Submit'} onClick={handleSubmit} />
                    </ButtonWrapper>
                </FormWrapper>
            </Modal>
        );
    }
};
