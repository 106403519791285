import * as styles from './bulk-edit-button-modal.style';

import { Button } from '../../../shared/components/button/button';
import { ButtonTypes } from '../../../shared/components/button/button.utils';
import { OrdersStateService } from '../../services/orders-state.service';
import React from 'react';

interface Props {}

export const BulkEditButtonModal: React.FC<Props> = (props: Props) => {
    const render = () => {
        return (
            <styles.Wrapper>
                {/* <Button onClick={() => OrdersStateService.toggleBulkEditModal(true)}>
                    Bulk edition
                </Button> */}
                <Button label={'Bulk Edition'}
                    onClick={() => OrdersStateService.toggleBulkEditModal(true)}
                    type={ButtonTypes.SECONDARY} />
            </styles.Wrapper>
        );
    };

    return render();
};
