import { IApiTableRequest } from '../interfaces/shared';
import { ISnackbar } from '../interfaces/snackbar';
import { SharedActions } from '../state/shared.actions';
import { store } from "./state.service";

export class SharedStateService {
    public static getPoTypes(): void {
        store.dispatch(
            SharedActions.getPoTypesReq(),
        );
    }

    public static setSnackbar(snackbar: ISnackbar): void {
        store.dispatch(
            SharedActions.setSnackbar(snackbar),
        );
    }

    public static getShipVias(): void {
        store.dispatch(
            SharedActions.getShipViaReq(),
        );
    }

    public static setFoundUnauthorizedCall(flag: boolean): void {
        store.dispatch(
            SharedActions.setFoundUnauthorizedCall(flag),
        );
    }

    public static getFobs(): void {
        store.dispatch(
            SharedActions.getFobsReq(),
        );
    }

    public static getSiteIds(): void {
        store.dispatch(
            SharedActions.getSiteIdsReq(),
        );
    }

    public static setBackendNotWorking(flag: boolean): void {
        store.dispatch(
            SharedActions.setBackendNotWorking(flag),
        );
    }

    public static getAllPartIds(): void {
        store.dispatch(
            SharedActions.getAllPartIds(),
        );
    }

    public static setApiTableRequest(req: IApiTableRequest): void {
        store.dispatch(
            SharedActions.setCurrentApiTableRequest(req),
        );
    }

    public static setShouldTableUpdate(flag: boolean): void {
        store.dispatch(
            SharedActions.setShouldTableUpdate(flag),
        );
    }

    public static getCountryCodes(): void {
        store.dispatch(
            SharedActions.getCountryCodes(),
        );
    }

}

// ===== SNACKBAR =====

export function _setSnackbar(snackbar: ISnackbar): void {
    store.dispatch(
        SharedActions.setSnackbar(snackbar),
    );
}
