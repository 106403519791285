import { ICustomer, ICustomerInventoryShippingAddress, IFreightPref, IIncoterm, IParser, ISaveCustomerReq } from '../interfaces/customer';

import { Action } from '../../shared/interfaces/shared';
import { ICustomerDetails } from '../interfaces/customer-details';
import { ICustomerPart } from '../interfaces/customer-parts';
import { IFilterObjectData } from '../../order-list/interfaces/order-list';
import { IShippingAddress } from '../interfaces/shipping-address';

export const GET_CUSTOMERS_BY_COMPANY_NAME_REQ = 'GET_CUSTOMERS_BY_COMPANY_NAME_REQ';
export const getCustomersByCompanyNameReq = (companyName: string): Action<string> => ({
    type: GET_CUSTOMERS_BY_COMPANY_NAME_REQ,
    payload: companyName
});

export const GET_CUSTOMERS_BY_COMPANY_NAME_OK = 'GET_CUSTOMERS_BY_COMPANY_NAME_OK';
export const getCustomersByCompanyNameOk = (customers: ICustomer[]): Action<ICustomer[]> => ({
    type: GET_CUSTOMERS_BY_COMPANY_NAME_OK,
    payload: customers
});

export const GET_CUSTOMERS_BY_COMPANY_NAME_FAIL = 'GET_CUSTOMERS_BY_COMPANY_NAME_FAIL';
export const getCustomersByCompanyNameFail = (error: string): Action<string> => ({
    type: GET_CUSTOMERS_BY_COMPANY_NAME_FAIL,
    payload: error
});

export const GET_CUSTOMERS_BY_COMPANY_ID_REQ = 'GET_CUSTOMERS_BY_COMPANY_ID_REQ';
export const getCustomersByCompanyIdReq = (id: string | number) => ({
    type: GET_CUSTOMERS_BY_COMPANY_ID_REQ,
    payload: id,
});

export const GET_CUSTOMERS_BY_COMPANY_ID_OK = 'GET_CUSTOMERS_BY_COMPANY_ID_OK';
export const getCustomersByCompanyIdOk = (customers: ICustomer[]) => ({
    type: GET_CUSTOMERS_BY_COMPANY_ID_OK,
    payload: customers,
});

export const GET_CUSTOMERS_BY_COMPANY_ID_FAIL = 'GET_CUSTOMERS_BY_COMPANY_ID_FAIL';
export const getCustomersByCompanyIdFail = (error: string) => ({
    type: GET_CUSTOMERS_BY_COMPANY_ID_FAIL,
    payload: error,
});

export const GET_CUSTOMER_BY_ID_REQ = 'GET_CUSTOMER_BY_ID_REQ';
export const getCustomerByIdReq = (id: number) => ({
    type: GET_CUSTOMER_BY_ID_REQ,
    payload: id,
});

export const GET_CUSTOMER_BY_ID_OK = 'GET_CUSTOMER_BY_ID_OK';
export const getCustomerByIdOk = (customer: ICustomer) => ({
    type: GET_CUSTOMER_BY_ID_OK,
    payload: customer,
});

export const GET_CUSTOMER_BY_ID_FAIL = 'GET_CUSTOMER_BY_ID_FAIL';
export const getCustomerByIdFail = (error: string) => ({
    type: GET_CUSTOMER_BY_ID_FAIL,
    payload: error,
});

export const GET_BREADCRUMB_CUSTOMER_REQ = 'GET_BREADCRUMB_CUSTOMER_REQ';
export const getBreadcrumbCustomerReq = (id: string) => ({
    type: GET_BREADCRUMB_CUSTOMER_REQ,
    payload: id,
});

export const GET_BREADCRUMB_CUSTOMER_OK = 'GET_BREADCRUMB_CUSTOMER_OK';
export const getBreadcrumbCustomerOk = (customer: ICustomer) => ({
    type: GET_BREADCRUMB_CUSTOMER_OK,
    payload: customer,
});

export const GET_BREADCRUMB_CUSTOMER_FAIL = 'GET_BREADCRUMB_CUSTOMER_FAIL';
export const getBreadcrumbCustomerFail = (error: string) => ({
    type: GET_BREADCRUMB_CUSTOMER_FAIL,
    payload: error,
});

export const SET_BREADCRUMB_CUSTOMER = 'SET_BREADCRUMB_CUSTOMER';
export const setBreadcrumbCustomer = (customer: ICustomer) => ({
    type: SET_BREADCRUMB_CUSTOMER,
    payload: customer,
});

export const RESET_CUSTOMERS = 'RESET_CUSTOMERS';
export const resetCustomers = () => ({
    type: RESET_CUSTOMERS,
    payload: null
});

export const RESET_CUSTOMER = 'RESET_CUSTOMER';
export const resetCustomer = () => ({
    type: RESET_CUSTOMER,
    payload: null,
});

export const TOGGLE_CUSTOMERS_LOADING = 'TOGGLE_CUSTOMERS_LOADING';
export const toggleCustomersLoading = (flag: boolean) => ({
    type: TOGGLE_CUSTOMERS_LOADING,
    payload: flag
});

export const SET_CURRENT_CUSTOMER = 'SET_CURRENT_CUSTOMER';
export const setCurrentCustomer = (customer: ICustomer) => ({
    type: SET_CURRENT_CUSTOMER,
    payload: customer,
});

export const TOGGLE_DELETE_CUSTOMER_MODAL = 'TOGGLE_DELETE_CUSTOMER_MODAL';
export const toggleDeleteCustomerModal = (flag: boolean) => ({
    type: TOGGLE_DELETE_CUSTOMER_MODAL,
    payload: flag,
});

export const TOGGLE_SAVE_CUSTOMER_MODAL = 'TOGGLE_SAVE_CUSTOMER_MODAL';
export const toggleSaveCustomerModal = (flag: boolean) => ({
    type: TOGGLE_SAVE_CUSTOMER_MODAL,
    payload: flag,
});

export const DELETE_CUSTOMER_REQ = 'DELETE_CUSTOMER_REQ';
export const deleteCustomerReq = (customer: ICustomer) => ({
    type: DELETE_CUSTOMER_REQ,
    payload: customer,
});

export const DELETE_CUSTOMER_OK = 'DELETE_CUSTOMER_OK';
export const deleteCustomerOk = (customer: ICustomer) => ({
    type: DELETE_CUSTOMER_OK,
    payload: customer,
});

export const DELETE_CUSTOMER_FAIL = 'DELETE_CUSTOMER_FAIL';
export const deleteCustomerFail = (error: string) => ({
    type: DELETE_CUSTOMER_FAIL,
    payload: error,
});

export const SAVE_CUSTOMER_REQ = 'SAVE_CUSTOMER_REQ';
export const saveCustomerReq = (request: ISaveCustomerReq) => ({
    type: SAVE_CUSTOMER_REQ,
    payload: request,
});

export const SAVE_CUSTOMER_OK = 'SAVE_CUSTOMER_OK';
export const saveCustomerOk = (customer: ICustomer) => ({
    type: SAVE_CUSTOMER_OK,
    payload: customer,
});

export const SAVE_CUSTOMER_FAIL = 'SAVE_CUSTOMER_FAIL';
export const saveCustomerFail = (error: string) => ({
    type: SAVE_CUSTOMER_FAIL,
    payload: error,
});

export const GET_FILTER_EXTERNAL_CUSTOMER_IDS_REQ = 'GET_FILTER_EXTERNAL_CUSTOMER_IDS_REQ';
export const getFilterExternalCustomerIdsReq = () => ({
    type: GET_FILTER_EXTERNAL_CUSTOMER_IDS_REQ,
    payload: null,
});

export const GET_FILTER_EXTERNAL_CUSTOMER_IDS_OK = 'GET_FILTER_EXTERNAL_CUSTOMER_IDS_OK';
export const getFilterExternalCustomerIdsOk = (arr: IFilterObjectData[]) => ({
    type: GET_FILTER_EXTERNAL_CUSTOMER_IDS_OK,
    payload: arr,
});

export const GET_FILTER_EXTERNAL_CUSTOMER_IDS_FAIL = 'GET_FILTER_EXTERNAL_CUSTOMER_IDS_FAIL';
export const getFilterExternalCustomerIdsFail = (error: string) => ({
    type: GET_FILTER_EXTERNAL_CUSTOMER_IDS_FAIL,
    payload: error,
});

export const GET_PARSERS_REQ = 'GET_CUSTOMER_PARSERS_REQ';
export const getParsersReq = () => ({
    type: GET_PARSERS_REQ,
    payload: null,
});

export const GET_PARSERS_OK = 'GET_PARSERS_OK';
export const getParsersOk = (parsers: IParser[]) => ({
    type: GET_PARSERS_OK,
    payload: parsers,
});

export const GET_PARSERS_FAIL = 'GET_PARSERS_FAIL';
export const getParsersFail = (error: string) => ({
    type: GET_PARSERS_FAIL,
    payload: error,
});

export const GET_CUSTOMERS_FROM_ERP_COMPANY_REQ = 'GET_CUSTOMERS_FROM_ERP_COMPANY_REQ';
export const getCustomersFromErpCompanyReq = (company: string) => ({
    type: GET_CUSTOMERS_FROM_ERP_COMPANY_REQ,
    payload: company,
});

export const GET_CUSTOMERS_FROM_ERP_COMPANY_OK = 'GET_CUSTOMERS_FROM_ERP_COMPANY_OK';
export const getCustomersFromErpCompanyOk = (customers: string[]) => ({
    type: GET_CUSTOMERS_FROM_ERP_COMPANY_OK,
    payload: customers,
});

export const GET_CUSTOMERS_FROM_ERP_COMPANY_FAIL = 'GET_CUSTOMERS_FROM_ERP_COMPANY_FAIL';
export const getCustomersFromErpCompanyFail = (error: string) => ({
    type: GET_CUSTOMERS_FROM_ERP_COMPANY_FAIL,
    payload: error,
});

export const GET_ERP_CUSTOMER_IDS_REQ = 'GET_ERP_CUSTOMER_IDS_REQ';
export const getErpCustomerIdsReq = () => ({
    type: GET_ERP_CUSTOMER_IDS_REQ,
    payload: null,
});

export const GET_ERP_CUSTOMER_IDS_OK = 'GET_ERP_CUSTOMER_IDS_OK';
export const getErpCustomerIdsOk = (ids: string[]) => ({
    type: GET_ERP_CUSTOMER_IDS_OK,
    payload: ids,
});

export const GET_ERP_CUSTOMER_IDS_FAIL = 'GET_ERP_CUSTOMER_IDS_FAIL';
export const getErpCustomerIdsFail = (error: string) => ({
    type: GET_ERP_CUSTOMER_IDS_FAIL,
    payload: error,
});

export const TOGGLE_ERP_CUSTOMER_IDS_LOADING = 'TOGGLE_ERP_CUSTOMER_IDS_LOADING';
export const toggleErpCustomerIdsLoading = (flag: boolean) => ({
    type: TOGGLE_ERP_CUSTOMER_IDS_LOADING,
    payload: flag,
});

export const SET_ERP_CUSTOMER_IDS = 'SET_ERP_CUSTOMER_IDS';
export const setErpCustomerIds = (ids: string[]) => ({
    type: SET_ERP_CUSTOMER_IDS,
    payload: ids,
});

export const GET_PRODUCT_CODES_REQ = 'GET_PRODUCT_CODES_REQ';
export const getProductCodesReq = (externalId: string) => ({
    type: GET_PRODUCT_CODES_REQ,
    payload: externalId,
});

export const GET_PRODUCT_CODES_OK = 'GET_PRODUCT_CODES_OK';
export const getProductCodesOk = (codes: string[]) => ({
    type: GET_PRODUCT_CODES_OK,
    payload: codes,
});

export const GET_PRODUCT_CODES_FAIL = 'GET_PRODUCT_CODES_FAIL';
export const getProductCodesFail = (error: string) => ({
    type: GET_PRODUCT_CODES_FAIL,
    payload: error,
});

export const GET_FREIGHTS_REQ = 'GET_FREIGHTS_REQ';
export const getFreightsReq = () => ({
    type: GET_FREIGHTS_REQ,
    payload: null,
});

export const GET_FREIGHTS_OK = 'GET_FREIGHTS_OK';
export const getFreightsOk = (freights: IFreightPref[]) => ({
    type: GET_FREIGHTS_OK,
    payload: freights,
});

export const GET_FREIGHTS_FAIL = 'GET_FREIGHTS_FAIL';
export const getFreightsFail = (error: string) => ({
    type: GET_FREIGHTS_FAIL,
    payload: error,
});

export const GET_INCOTERM_REQ = 'GET_INCOTERM_REQ';
export const getIncotermReq = () => ({
    type: GET_INCOTERM_REQ,
    payload: null,
});

export const GET_INCOTERM_OK = 'GET_INCOTERM_OK';
export const getIncotermOk = (incoterms: IIncoterm[]) => ({
    type: GET_INCOTERM_OK,
    payload: incoterms,
});

export const GET_INCOTERM_FAIL = 'GET_INCOTERM_FAIL';
export const getIncotermFail = (error: string) => ({
    type: GET_INCOTERM_FAIL,
    payload: error,
});

export const GET_CUSTOMER_PARTS_REQ = 'GET_CUSTOMER_PARTS_REQ';
export const getCustomerPartsReq = (externalId: string) => ({
    type: GET_CUSTOMER_PARTS_REQ,
    payload: externalId,
});

export const GET_CUSTOMER_PARTS_OK = 'GET_CUSTOMER_PARTS_OK';
export const getCustomerPartsOk = (parts: ICustomerPart[]) => ({
    type: GET_CUSTOMER_PARTS_OK,
    payload: parts,
});

export const GET_CUSTOMER_PARTS_FAIL = 'GET_CUSTOMER_PARTS_FAIL';
export const getCustomerPartsFail = (error: string) => ({
    type: GET_CUSTOMER_PARTS_FAIL,
    payload: error,
});

export const GET_CUSTOMER_DETAILS_REQ = 'GET_CUSTOMER_DETAILS_REQ';
export const getCustomerDetailsReq = (externalCustomerId: string) => ({
    type: GET_CUSTOMER_DETAILS_REQ,
    payload: externalCustomerId,
});

export const GET_CUSTOMER_DETAILS_OK = 'GET_CUSTOMER_DETAILS_OK';
export const getCustomerDetailsOk = (details: ICustomerDetails) => ({
    type: GET_CUSTOMER_DETAILS_OK,
    payload: details,
});

export const GET_CUSTOMER_DETAILS_FAIL = 'GET_CUSTOMER_DETAILS_FAIL';
export const getCustomerDetailsFail = (error: string) => ({
    type: GET_CUSTOMER_DETAILS_FAIL,
    payload: error,
});

export const GET_SHIPPING_ADDRESSES_REQ = 'GET_SHIPPING_ADDRESSES_REQ';
export const getShippingAddressesReq = (externalId: string) => ({
    type: GET_SHIPPING_ADDRESSES_REQ,
    payload: externalId,
});

export const GET_SHIPPING_ADDRESSES_OK = 'GET_SHIPPING_ADDRESSES_OK';
export const getShippingAddressesOk = (addresses: IShippingAddress) => ({
    type: GET_SHIPPING_ADDRESSES_OK,
    payload: addresses,
});

export const GET_SHIPPING_ADDRESSES_FAIL = 'GET_SHIPPING_ADDRESSES_FAIL';
export const getShippingAddressesFail = (error: string) => ({
    type: GET_SHIPPING_ADDRESSES_FAIL,
    payload: error,
});

export const GET_CUSTOMER_BY_EXTERNAL_ID_REQ = 'GET_CUSTOMER_BY_EXTERNAL_ID_REQ';
export const getCustomerByExternalIdReq = (externalId: string) => ({
    type: GET_CUSTOMER_BY_EXTERNAL_ID_REQ,
    payload: externalId,
});

export const GET_CUSTOMER_BY_EXTERNAL_ID_OK = 'GET_CUSTOMER_BY_EXTERNAL_ID_OK';
export const getCustomerByExternalIdOk = (customer: ICustomer) => ({
    type: GET_CUSTOMER_BY_EXTERNAL_ID_OK,
    payload: customer,
});

export const GET_CUSTOMER_BY_EXTERNAL_ID_FAIL = 'GET_CUSTOMER_BY_EXTERNAL_ID_FAIL';
export const getCustomerByExternalIdFail = (error: string) => ({
    type: GET_CUSTOMER_BY_EXTERNAL_ID_FAIL,
    payload: error,
});

export const GET_ERP_CUSTOMERS_FOR_USER_REQ = 'GET_ERP_CUSTOMERS_FOR_USER_REQ';
export const getErpCustomersForUserReq = () => ({
    type: GET_ERP_CUSTOMERS_FOR_USER_REQ,
    payload: null,
});

export const GET_ERP_CUSTOMERS_FOR_USER_OK = 'GET_ERP_CUSTOMER_FOR_USER_OK';
export const getErpCustomersForUserOk = (arr: ICustomerDetails[]) => ({
    type: GET_ERP_CUSTOMERS_FOR_USER_OK,
    payload: arr,
});

export const GET_ERP_CUSTOMERS_FOR_USER_FAIL = 'GET_ERP_CUSTOMERS_FOR_USER_FAIL';
export const getErpCustomersForUserFail = (error: string) => ({
    type: GET_ERP_CUSTOMERS_FOR_USER_FAIL,
    payload: error,
});

export const RESET_CUSTOMER_BY_EXTERNAL_ID_ERROR = 'RESET_CUSTOMER_BY_EXTERNAL_ID_ERROR';
export const resetCustomerByExternalIdError = () => ({
    type: RESET_CUSTOMER_BY_EXTERNAL_ID_ERROR,
    payload: null,
});

export const TOGGLE_SAVE_INVENTORY_ADDRESS_MODAL = 'TOGGLE_SAVE_INVENTORY_ADDRESS_MODAL';
export const toggleSaveInventoryAddressModal = (flag: boolean) => ({
    type: TOGGLE_SAVE_INVENTORY_ADDRESS_MODAL,
    payload: flag,
});

export const SET_CURRENT_INVENTORY_ADDRESS = 'SET_CURRENT_INVENTORY_ADDRESS';
export const setCurrentInventoryAddress = (address: ICustomerInventoryShippingAddress) => ({
    type: SET_CURRENT_INVENTORY_ADDRESS,
    payload: address,
});
