import { IApiTableRequest } from "../interfaces/shared";
import { ICompany } from "../../companies/interfaces/company";
import { ICustomer } from "../../customers/interfaces/customer";
import { IOrderList } from "../../order-list/interfaces/order-list";
import { SharedState } from "../interfaces/shared.state";

export const sharedInitState: SharedState = {
    sample: 'Hello, world',
    poTypes: [],
    snackbar: {
        type: null,
        isOpen: false,
        message: '',
    },
    breadcrumbs: {
        company: {} as ICompany,
        customer: {} as ICustomer,
        orderList: {} as IOrderList,
    },
    shipVias: [],
    foundUnauthorizedCall: false,
    fobs: [],
    siteIds: [],
    backendNotWorking: false,
    allPartIds: [],
    apiTableRequest: {} as IApiTableRequest,
    shouldTableUpdate: false,
    countryCodes: [],
};
