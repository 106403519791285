import { IFoeOrder } from "../interfaces/foe";
import { axios } from "../../shared/services/axios.service";
import { from } from "rxjs";

export class FoeWebApi {
    public static createFoe(order: IFoeOrder) {
        return from(
            axios.post('/CustomerPoHeader/foe', { ...order })
                .then(res => res.data)
        );
    }
}
