import * as React from 'react';

import { Link, useHistory } from 'react-router-dom';
import { _resetAoeBreadcrumbs, refineBreadcrumbs } from '../../../aoe/services/aoe-state.service';

import { AppState } from '../../interfaces/app.state';
import { Breadcrumb } from 'antd';
import { IBreadcrumb } from '../../interfaces/shared';
import { ICompany } from '../../../companies/interfaces/company';
import { ICustomer } from '../../../customers/interfaces/customer';
import { IOrderList } from '../../../order-list/interfaces/order-list';
import { Theme } from '../../styles/theme';
import { useSelector } from 'react-redux';

interface Props {
    breadcrumbs: IBreadcrumb[];
}

export const Breadcrumbs: React.FC<Props> = (_props: Props) => {
    const [breadcrumbs, setBreadcrumbs] = React.useState<IBreadcrumb[]>([]);

    // state
    const currentCompany = useSelector<AppState, ICompany>(state => state.shared.breadcrumbs.company);
    const currentCustomer = useSelector<AppState, ICustomer>(state => state.shared.breadcrumbs.customer);
    const currentOrderList = useSelector<AppState, IOrderList>(state => state.shared.breadcrumbs.orderList);

    const history = useHistory();

    const render = () => {
        if (!breadcrumbs.length) {
            return <></>;
        }

        return (
            <Theme.PageWrapper>
                <Breadcrumb separator='>'>
                    {
                        !!breadcrumbs.length &&
                        breadcrumbs.map((bc, index) =>
                            <Breadcrumb.Item key={index}>
                                <Link to={bc.path} onClick={() => refineBreadcrumbs(bc)}>
                                    {bc.label}
                                </Link>
                            </Breadcrumb.Item>
                        )
                    }
                </Breadcrumb>
            </Theme.PageWrapper>
        );
    };

    React.useEffect(() => {
        return () => {
            _resetAoeBreadcrumbs();
        };
    }, []);

    React.useEffect(() => {
        createBreadcrumbs();
    }, [history.location]);

    const createBreadcrumbs = () => {
        const pathMapper: any = {
            'companies': 'Companies',
            'customerIds': 'Company',
            'purchaseorder-list': 'CustomerID',
            'purchaseorder-lines': 'Order List'
        };

        const entityMapper: any = {
            'customerIds': currentCompany,
            'purchaseorder-list': currentCustomer,
            'purchaseorder-lines': currentOrderList,
        };

        const accessorMapper: any = {
            'customerIds': 'name',
            'purchaseorder-list': 'externalCustomerId',
            'purchaseorder-lines': 'poNumber',
        };

        let paths = history.location.pathname
            .split('/')
            .filter(i => i)
            .filter((_, index) => index !== 0);

        let modules: string[] = [];
        let moduleIds: string[] = [];
        let latestPath: string = '/admin/companies';
        let currentBreadcrumbs: IBreadcrumb[] = [];

        // for the moment, display breadcrumbs only if user enters companies page
        if (paths[0] === 'companies') {
            currentBreadcrumbs = [...currentBreadcrumbs, {
                label: 'Companies',
                path: latestPath,
            }];

            for (let i = 0; i < paths.length; i++) {
                if (i % 2 === 0) {
                    modules = [...modules, paths[i]];
                } else {
                    moduleIds = [...moduleIds, paths[i]];
                }
            }

            if (moduleIds.length) {
                moduleIds.forEach((id, index) => {
                    let currentPath = `${latestPath}/${id}/${modules[index + 1]}`;
                    const moduleName = modules[index + 1];
                    let breadcrumb: IBreadcrumb = {
                        path: currentPath,
                        label: pathMapper[moduleName] + ' ' + entityMapper[moduleName][accessorMapper[moduleName]],
                    };
                    latestPath = currentPath;

                    currentBreadcrumbs = [...currentBreadcrumbs, breadcrumb];
                });
            }

            setBreadcrumbs(currentBreadcrumbs);
        } else {
            setBreadcrumbs([]);
        }
    };

    return render();
};
