import * as styled from './faq.page.style';

import { Document, Page, pdfjs } from 'react-pdf';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PageWrapper } from '../../../shared/styles/page-wrapper';
import React from 'react';
import { SectionTitle } from '../../../shared/components/section-title/section-title';
import { colors } from '../../../shared/styles/colors';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';

const defaultExcelTemplate = require('../../../shared/files/Purchase_order_template.xlsx');
const customerPortalExplanation = require('../../../shared/files/Customer_Portal_explanation_v2.jpg');

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface Props {}

export const FAQPage: React.FC<Props> = (props: Props) => {
    const render = () => {
        return (
            <PageWrapper>
                <styled.FaqSection>
                    <SectionTitle text={'Default Excel Template'} />

                    <a href={defaultExcelTemplate} download={'Purchase Order Template.xlsx'}>
                        Download from here &nbsp;
                        <FontAwesomeIcon icon={faFileExcel}
                            size={'1x'}
                            color={colors.$excel} />
                    </a>
                </styled.FaqSection>

                <styled.FaqSection>
                    <SectionTitle text={'Validation rules'} />

                    <div>Initial validation </div>

                    <p>Each of the above fields is analyzed with Gecko&rsquo;s business rules:</p>
                    <ul>
                        <li>
                        <p><strong>PO Number</strong>- check if &quot;PO Number&quot; is already present for the Customer in DataHub - the &ldquo;<strong>validatePoNumber</strong>&ldquo; DataHub endpoint is used</p>
                        </li>
                        <li>
                        <p><strong>PO Date</strong> -check the date format and fill in the &quot;Revised PO Date&quot;</p>
                        </li>
                        <li>
                        <p><strong>Customer Requested Date</strong> - check the date format if the &quot;Customer Requested Date&quot; was received in the PO and fill in &quot;Revised Customer Requested Date (Promise Del Date)&quot;, by default it&rsquo;s filled with &quot;PO Received Date&quot; + Fix Lead-Time from the general setting. This date is adjusted based on the customer settings if the customer is sending in the order they receive date or not.</p>
                        </li>
                        <li>
                        <p><strong>Ship Via</strong> - check if ship via was received in the PO and set the &quot;Revised Ship Via&quot; field based on FOB setting.</p>
                        </li>
                        <li>
                        <p><strong>FOB</strong> - it&rsquo;s used the &ldquo;Freight preference&rdquo; setting from the customer shipment configuration. In the case of &ldquo;PREPAID/ PREPAID AND ADD&ldquo; or &ldquo;PREPAID/ COLLECT&ldquo;, the final value is calculated based on the order details.</p>
                        </li>
                        <li>
                        <p><strong>Ship To Address</strong> - if we receive a new Shipping address for the order, the address is validated with the Google address normalization API, with this API are extracted the parts of the address like City, Zip code,&hellip; If an existing address is used, the parts of the address are extracted from the last confirmed order. If no matching location in Visual, a new ship to location is added in ERP.</p>
                        </li>
                        <li>
                        <p><strong>Part ID</strong> - check if &quot;Part ID&quot; is valid. If no Part Id or no valid id in the order we try to find the good one based on the Customer Part Id.</p>
                        </li>
                        <li>
                        <p><strong>Customer Part ID</strong> - If no Customer Part Id in the order we try to find based on Customer Part Id.</p>
                        </li>
                        <li>
                        <p><strong>Quantity</strong> - check if the quantity received in the &quot;PO&quot; is below the &quot;MOQ&quot; value. Fill in the &quot;Revised Quantity&quot; field as follows:</p>

                        <ol>
                            <li>
                            <p>If the quantity is below the &quot;MOQ&quot; value, fill in the &quot;MOQ&quot; value by default</p>
                            </li>
                            <li>
                            <p>If the quantity is equal/greater than the &quot;MOQ&quot; value, adjust the received quantity to be multiple of &ldquo;MOQ&ldquo; or &ldquo;Box Quantity&ldquo;</p>
                            </li>
                        </ol>
                        </li>
                        <li>
                        <p><strong>Unit price</strong> - check if the unit price received in the &quot;PO&quot; is correct and fill in the &quot;Revised Unit Price&quot; field with the correct value. If the received price was adjusted based on customer price or no valid customer price, the proper validation error is thrown.</p>
                        </li>
                        <li>
                        <p><strong>Confirmed Date (Desired Ship Date)</strong> - Is calculated based on many business rules.</p>
                        </li>
                    </ul>


                    <div>Confirm PO Validation </div>

                    <ul>
                        <li>
                        <p><strong>PO Number</strong> - check if &quot;PO Number&quot; is already present for the Customer in DataHub - the &ldquo;<strong>validatePoNumber</strong>&ldquo; DataHub endpoint is used</p>
                        </li>
                        <li>
                        <p><strong>Revised PO Date</strong> - check if not empty</p>
                        </li>
                        <li>
                        <p><strong>Confirmed Date (Desired Ship Date)</strong> - check if not empty</p>
                        </li>
                        <li>
                        <p><strong>Part ID</strong> - check if it&#39;s valid for that Customer</p>
                        </li>
                        <li>
                        <p><strong>Revised Quantity</strong> - check if not empty</p>
                        </li>
                        <li>
                        <p><strong>Revised Unit Price</strong> - check if not empty</p>
                        </li>
                    </ul>


                </styled.FaqSection>

                <styled.FaqSection>
                    <SectionTitle text={'Description of functioning'} />
                    <img src={customerPortalExplanation} alt="Description of functioning"></img>
                </styled.FaqSection>

            </PageWrapper>
        );
    };

    return render();
};
