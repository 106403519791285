import { IAttachRoleReq, IRole, IUserRole } from "../interfaces/roles";

import { IApiTableRequest } from "../../shared/interfaces/shared";
import { ICurrentUser } from "../interfaces/current-user";
import { ISetting } from "../interfaces/settings";
import { ITokenUserRole } from "../interfaces/token-user-role";

export class UsersActions {
    public static readonly SET_CURRENT_USER = 'SET_CURRENT_USER';
    public static setCurrentUser(user: ICurrentUser) {
        return {
            type: this.SET_CURRENT_USER,
            payload: user,
        };
    }

    public static readonly SET_TOKEN = 'SET_TOKEN';
    public static setToken(token: string) {
        return {
            type: this.SET_TOKEN,
            payload: token,
        };
    }

    public static readonly GET_ROLES_REQ = 'GET_ROLES_REQ';
    public static getRolesReq() {
        return {
            type: this.GET_ROLES_REQ,
            payload: null,
        };
    }

    public static readonly GET_ROLES_OK = 'GET_ROLES_OK';
    public static getRolesOk(roles: IRole[]) {
        return {
            type: this.GET_ROLES_OK,
            payload: roles,
        };
    }

    public static readonly GET_ROLES_FAIL = 'GET_ROLES_FAIL';
    public static getRolesFail(error: string) {
        return {
            type: this.GET_ROLES_FAIL,
            payload: error,
        };
    }

    public static readonly ATTACH_ROLE_REQ = 'ATTACH_ROLE_REQ';
    public static attachRoleReq(req: IAttachRoleReq) {
        return {
            type: this.ATTACH_ROLE_REQ,
            payload: req,
        };
    }

    public static readonly ATTACH_ROLE_OK = 'ATTACH_ROLE_OK';
    public static attachRoleOk() {
        return {
            type: this.ATTACH_ROLE_OK,
            payload: null,
        };
    }

    public static readonly ATTACH_ROLE_FAIL = 'ATTACH_ROLE_FAIL';
    public static attachRoleFail(error: string) {
        return {
            type: this.ATTACH_ROLE_FAIL,
            payload: error,
        };
    }

    public static readonly GET_USER_PERMISSION_REQ = 'GET_USER_PERMISSION_REQ';
    public static getUserPermissionReq(params: IApiTableRequest) {
        return {
            type: this.GET_USER_PERMISSION_REQ,
            payload: params,
        };
    }

    public static readonly GET_USER_PERMISSION_OK = 'GET_USER_PERMISSION_OK';
    public static getUserPermissionOk(roles: IUserRole[]) {
        return {
            type: this.GET_USER_PERMISSION_OK,
            payload: roles,
        };
    }

    public static readonly GET_USER_PERMISSION_FAIL = 'GET_USER_PERMISSION_FAIL';
    public static getUserPermissionFail(error: string) {
        return {
            type: this.GET_USER_PERMISSION_FAIL,
            payload: error,
        };
    }

    public static readonly SET_CURRENT_USER_ROLE = 'SET_CURRENT_USER_ROLE';
    public static setCurrentUserRole(role: IUserRole) {
        return {
            type: this.SET_CURRENT_USER_ROLE,
            payload: role,
        };
    }

    public static readonly TOGGLE_CHANGE_USER_ROLE_MODAL = 'TOGGLE_CHANGE_USER_ROLE_MODAL';
    public static toggleChangeUserRoleModal(flag: boolean) {
        return {
            type: this.TOGGLE_CHANGE_USER_ROLE_MODAL,
            payload: flag,
        };
    }

    public static readonly GET_USER_ROLE_REQ = 'GET_USER_ROLE_REQ';
    public static getUserRoleReq() {
        return {
            type: this.GET_USER_ROLE_REQ,
            payload: null,
        };
    }

    public static readonly GET_USER_ROLE_OK = 'GET_USER_ROLE_OK';
    public static getUserRoleOk(role: ITokenUserRole) {
        return {
            type: this.GET_USER_ROLE_OK,
            payload: role,
        };
    }

    public static readonly GET_USER_ROLE_FAIL = 'GET_USER_ROLE_FAIL';
    public static getUserRoleFail(error: string) {
        return {
            type: this.GET_USER_ROLE_FAIL,
            payload: error,
        };
    }

    public static readonly GET_USER_ROLE_BY_ID_REQ = 'GET_USER_ROLE_BY_ID_REQ';
    public static getUserRoleByIdReq(id: number) {
        return {
            type: this.GET_USER_ROLE_BY_ID_REQ,
            payload: id,
        };
    }

    public static readonly GET_USER_ROLE_BY_ID_OK = 'GET_USER_ROLE_BY_ID_OK';
    public static getUserRoleByIdOk(role: IUserRole) {
        return {
            type: this.GET_USER_ROLE_BY_ID_OK,
            payload: role,
        };
    }

    public static readonly GET_USER_ROLE_BY_ID_FAIL = 'GET_USER_ROLE_BY_ID_FAIL';
    public static getUserRoleByIdFail(error: string) {
        return {
            type: this.GET_USER_ROLE_BY_ID_FAIL,
            payload: error,
        };
    }

    public static readonly GET_ADMIN_SETTINGS_REQ = 'GET_ADMIN_SETTINGS_REQ';
    public static getAdminSettingsReq() {
        return {
            type: this.GET_ADMIN_SETTINGS_REQ,
            payload: null,
        };
    }

    public static readonly GET_ADMIN_SETTINGS_OK = 'GET_ADMIN_SETTINGS_OK';
    public static getAdminSettingsOk(settings: ISetting[]) {
        return {
            type: this.GET_ADMIN_SETTINGS_OK,
            payload: settings,
        };
    }

    public static readonly GET_ADMIN_SETTINGS_FAIL = 'GET_ADMIN_SETTINGS_FAIL';
    public static getAdminSettingsFail(error: string) {
        return {
            type: this.GET_ADMIN_SETTINGS_FAIL,
            payload: error,
        };
    }

    public static readonly SAVE_ADMIN_SETTINGS_REQ = 'SAVE_ADMIN_SETTINGS_REQ';
    public static saveAdminSettingsReq(settings: ISetting[]) {
        return {
            type: this.SAVE_ADMIN_SETTINGS_REQ,
            payload: settings,
        };
    }

    public static readonly SAVE_ADMIN_SETTINGS_OK = 'SAVE_ADMIN_SETTINGS_OK';
    public static saveAdminSettingsOk() {
        return {
            type: this.SAVE_ADMIN_SETTINGS_OK,
            payload: null,
        };
    }

    public static readonly SAVE_ADMIN_SETTINGS_FAIL = 'SAVE_ADMIN_SETTINGS_FAIL';
    public static saveAdminSettingsFail(error: string) {
        return {
            type: this.SAVE_ADMIN_SETTINGS_FAIL,
            payload: error,
        };
    }

}
