import * as actions from '../state/soa.actions';
import * as selectors from '../state/soa.selectors';

import { distinctUntilChanged, map, skipWhile } from 'rxjs/operators';
import { store, store$ } from '../../shared/services/state.service';

import { ISoa } from '../interfaces/soa';
import { ISoaFormValues } from '../interfaces/soa-form-values'
import { Observable } from 'rxjs';

/** GET SOAS */

export const soas$ = (): Observable<ISoa[]> =>
    store$.pipe(
        map(state => selectors.SOAS(state)),
        skipWhile(soas => !soas),
        distinctUntilChanged()
    );

export const _getSoas = (): void => {
    store.dispatch(actions.getSoasReq());
};

export const getSoasLoading$ = (): Observable<boolean> =>
    store$.pipe(
        map(state => selectors.SOAS_LOADING(state)),
        distinctUntilChanged()
    );

export const _toggleGetSoasLoading = (flag: boolean): void => {
    store.dispatch(actions.toggleGetSoasLoading(flag));
};


/** DELETE SOA */

export function _toggleDeleteSoaModal(flag: boolean): void {
    store.dispatch(actions.toggleDeleteModal(flag));
}
export function _setDeletedSoa(soa: ISoa): void {
    store.dispatch(actions.setDeletedSoa(soa));
}

export const _deleteSoa = (id: number): void => {
    store.dispatch(actions.deleteSoaByIdReq(id));
}

export const _toggleDeleteSoaLoading = (flag: boolean): void => {
    store.dispatch(actions.toggleDeleteSoaLoading(flag));
};

export const deleteSoaLoading$ = (): Observable<boolean> =>
    store$.pipe(
        map(state => selectors.DELETE_SOA_LOADING(state)),
        distinctUntilChanged()
    );




/** CREATE SOA */

export function _toggleCreatetSoaModal(flag: boolean): void {
    store.dispatch(actions.toggleCreateModal(flag));
}


export function _createSoa(soa: ISoaFormValues): void {
    store.dispatch(actions.createSoaReq(soa));
}

export const _toggleCreateSoaLoading = (flag: boolean): void => {
    store.dispatch(actions.toggleCreateSoaLoading(flag));
};

export const createSoaLoading$ = (): Observable<boolean> =>
    store$.pipe(
        map(state => selectors.CREATE_SOA_LOADING(state)),
        distinctUntilChanged()
    );





/** EDIT SOA */

export function _setCurrentSoa(soa: ISoa): void {
    store.dispatch(actions.setCurrentSoa(soa));
}

export function _resetCurrentSoa(): void {
    store.dispatch(actions.resetCurrentSoa());
}

export function _editSoa(soa: ISoa): void {
    store.dispatch(actions.editSoaReq(soa));
}

export const _toggleEditSoaLoading = (flag: boolean): void => {
    store.dispatch(actions.toggleEditSoaLoading(flag));
};

export const editSoaLoading$ = (): Observable<boolean> =>
    store$.pipe(
        map(state => selectors.EDIT_SOA_LOADING(state)),
        distinctUntilChanged()
    );


export const _resetSoas = (): void => {
    store.dispatch(
        actions.resetSoas(),
    );
};

export class SoaStateService {
    public static getFilterSoas() {
        store.dispatch(
            actions.getFilterSoas(),
        );
    }

    public static getSoaById(id: number) {
        store.dispatch(
            actions.getSoaById(id),
        );
    }

}
