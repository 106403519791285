import { AppState } from '../../shared/interfaces/app.state';
import { ITokenUserRole } from '../interfaces/token-user-role';
import React from 'react';
import { SectionTitle } from '../../shared/components/section-title/section-title';
import { Theme } from '../../shared/styles/theme';
import { useSelector } from 'react-redux';

interface Props {}

export const NoAccessPage: React.FC<Props> = (_props: Props) => {
    const tokenUserRole = useSelector<AppState, ITokenUserRole | null>(state => state.users.tokenUserRole);

    const render = () => {
        return (
            <Theme.PageWrapper>
                {
                    (tokenUserRole && tokenUserRole.status === 2) &&
                    <SectionTitle text={'Your account was rejected. Please contact your Sales Operation Agent.'} />
                }

                {
                    (tokenUserRole && tokenUserRole.status === 1) &&
                    <SectionTitle text={'Your account is being processed... please wait.'} />
                }
            </Theme.PageWrapper>
        );
    };

    return render();
};
