import { FilterMappersService } from "../../filters/services/filter-mappers.service";
import { FilterOptions } from "../interfaces/filters";
import { IApiTableRequest } from "../interfaces/shared";
import { IGetOrderRequest } from "../../orders/interfaces/order";
import { IOrderListRequest } from "../../order-list/interfaces/order-list-request";

export class FiltersService {
    public static getApiFilterSearchString(value: string | number, name: string, filterCounter: number, filterOption: FilterOptions = FilterOptions.IsEqualTo) {
        return `Filter[${filterCounter}].Name=${name}&Filter[${filterCounter}].ValueString=${value.toString()}&Filter[${filterCounter}].FilterOption=${filterOption}`;
    }

    public static getOrdersApiQuerySearch(request: IOrderListRequest) {
        let qs = '';
        let counter = 0;
        const { priceFrom, priceTo, company, currency, customerId, status, poNumber, sortColumn, sortOrder, soaId } = request;

        if (priceFrom) {
            qs += this.getApiFilterSearchString(priceFrom, 'TotalAmount', counter, FilterOptions.IsGreaterThan);
            qs += '&';
            counter++;
        }

        if (priceTo) {
            qs += this.getApiFilterSearchString(priceTo, 'TotalAmount', counter, FilterOptions.IsLessThan);
            qs += '&';
            counter++;
        }

        if (company) {
            qs += this.getApiFilterSearchString(company, 'CompanyId', counter);
            qs += '&';
            counter++;
        }

        if (currency) {
            qs += this.getApiFilterSearchString(currency, 'Currency', counter);
            qs += '&';
            counter++;
        }

        if (customerId) {
            qs += this.getApiFilterSearchString(customerId, 'CustomerId', counter);
            qs += '&';
            counter++;
        }

        if (status) {
            qs += this.getApiFilterSearchString(status, 'OrderStatus', counter);
            qs += '&';
            counter++;
        }

        if (poNumber) {
            qs += this.getApiFilterSearchString(poNumber, 'PoNumber', counter);
            qs += '&';
            counter++;
        }

        if (soaId) {
            qs += this.getApiFilterSearchString(soaId, 'SoaId', counter);
            qs += '&';
            counter++;
        }

        if (sortColumn) {
            const capitalizedColumnName = sortColumn.charAt(0).toUpperCase() + sortColumn.slice(1);

            qs += `Sorting.ColumnName=${capitalizedColumnName}&Sorting.Order=${sortOrder}&`;
        } else {
            qs += `Sorting.ColumnName=OrderDate&Sorting.Order=2&`;
        }

        return qs;
    }

    public static getErpOrdersApiQuerySearch(request: IGetOrderRequest) {
        let qs = '';
        const { sortColumn, sortOrder } = request;

        // transform to separate function
        if (sortColumn) {
            const capitalizedColumnName = sortColumn.charAt(0).toUpperCase() + sortColumn.slice(1);

            qs += `Sorting.ColumnName=${capitalizedColumnName}&Sorting.Order=${sortOrder}&`;
        } else {
            qs += `Sorting.ColumnName=OrderDate&Sorting.Order=2&`;
        }

        return qs;
    }

    public static getApiQuerySearch(params: IApiTableRequest) {
        let qs = '';
        let counter = 0;

        const getSortColumn = () => {
            if (params.sortColumn) {
                return params.sortColumn.charAt(0).toUpperCase() + params.sortColumn.slice(1);
            }

            if (params.sortColumn === null) {
                return '';
            }

            return 'OrderDate';
        };

        const pageNumber = params.pageNumber || 1;
        const pageSize = params.pageSize || 20;
        const sortColumn = getSortColumn();
        const sortOrder = params.sortOrder ? params.sortOrder : 2;

        params.filters && params.filters.forEach(filter => {
            qs += this.getApiFilterSearchString(
                encodeURIComponent(filter.value),
                FilterMappersService.nameMapper[filter.key],
                counter,
                filter.option,
            );

            qs += '&';
            counter++;
        });

        qs += `PageNumber=${pageNumber}&PageSize=${pageSize}&`;

        if (sortColumn) {
            qs += `Sorting.ColumnName=${sortColumn}&Sorting.Order=${sortOrder}`;
        }

        return qs;
    }
}
