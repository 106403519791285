import { Button } from '../../../shared/components/button/button';
import { ButtonTypes } from '../../../shared/components/button/button.utils';
import { ErpOrderLineTableUtils } from './erp-order-line-table.utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GeckoTable } from '../../../shared/components/gecko-table/gecko-table';
import { IErpOrderLine } from '../../interfaces/order';
import { IRowMenu } from '../../../shared/interfaces/row-menu';
import { OrdersStateService } from '../../services/orders-state.service';
import { ROLES } from '../../../users/interfaces/roles';
import React from 'react';
import { Theme } from '../../../shared/styles/theme';
import { UsersService } from '../../../users/services/users.service';
import { colors } from '../../../shared/styles/colors';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';

interface Props {
    showFilters?: boolean;
    showExport?: boolean;
    orderLines: IErpOrderLine[];
}

export const ErpOrderLineTable: React.FC<Props> = (props: Props) => {
    const { orderLines, showFilters, showExport } = props;

    const render = () => {
        return (
            <>
                <Theme.SpaceBetween>
                    {
                        showExport &&
                        <Button type={ButtonTypes.SECONDARY}
                            label={'EXPORT'}
                            icon={<FontAwesomeIcon icon={faFileAlt} size='lg' color={colors.$darkBlue} />}
                            disabled={true} />
                    }

                    {
                        showFilters &&
                        <Button type={ButtonTypes.PRIMARY}
                            label={'Filters'}
                            disabled={true} />
                    }
                </Theme.SpaceBetween>

                <GeckoTable dataSource={{ data: orderLines }}
                    columns={ErpOrderLineTableUtils.orderLineColumns}
                    filters={[]}
                    rowMenu={row => menuItems(row)}
                    showRowMenu={UsersService.authorizeDisplay([ROLES.Admin, ROLES.SOA])}
                    tableId={'erp-order-line-table'} />

                {/* <Table dataSource={orderLines}
                    columns={ErpOrderLineTableUtils.orderLineColumns}
                    pagination={false}
                    rowClassName={'blue'}
                    rowKey={'id'} /> */}
            </>
        );
    };

    const menuItems = (line: IErpOrderLine): IRowMenu[] => {
        if (UsersService.authorizeDisplay([ROLES.Admin, ROLES.SOA])) {
            return [{
                label: 'Update Order Line',
                onClick: () => onEditClick(line),
                disabled: line.statusCode === 'Clsd Short' || line.statusCode === 'Shipped',
                // hide: UsersService.authorizeDisplay([ROLES.Admin, ROLES.SOA]),
            }];
        }

        return [];
    };

    const onEditClick = (line: IErpOrderLine) => {
        OrdersStateService.toggleEditErpOrderLine(true);
        OrdersStateService.setCurrentErpOrderLine(line);
    };

    return render();
};
