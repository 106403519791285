import * as React from 'react';
import * as styled from './order-list.page.style';

import { FilterOptions, IApiTableFilter } from '../../shared/interfaces/filters';
import { IApiTableRequest, IBreadcrumb } from '../../shared/interfaces/shared';
import { IFreightPref, IIncoterm } from '../../customers/interfaces/customer';
import { OrderListStateService, _createOrderList, _deleteOrderList, _editOrderList, _resetCurrentOrderList, _setCurrentOrderList, _toggleCreateOrderListModal, _toggleDeleteOrderListModal } from '../services/order-list-state.service'
import { useHistory, useLocation, useParams } from 'react-router';

import { AppState } from '../../shared/interfaces/app.state';
import { AuthService } from '../../auth/services/auth.service';
import { BackendError } from '../../shared/components/backend-error/backend-error';
import { CompaniesStateService } from '../../companies/services/companies.state-service';
import { ConfirmationModal } from '../../shared/components/confirmation-modal/confirmation-modal'
import { CustomersStateService } from '../../customers/services/customers.state-service';
import { FILTERS } from '../../filters/interfaces/filters';
import { GeckoTable } from '../../shared/components/gecko-table/gecko-table';
import { IAzureADFunctionProps } from 'react-aad-msal';
import { ICountryCode } from '../../shared/interfaces/country-code';
import { IFilterSoa } from '../../soa/interfaces/soa';
import { IOrderList } from '../interfaces/order-list'
import { IOrderListRequest } from '../interfaces/order-list-request';
import { IPagination } from '../../shared/interfaces/pagination';
import { IRowMenu } from '../../shared/interfaces/row-menu';
import { IShipVia } from '../../shared/interfaces/ship-via';
import { ISiteId } from '../../shared/interfaces/site-id';
import { OrderListFilters } from '../components/filters/order-list-filters';
import { OrderListService } from '../services/order-list.service';
import { OrderListTableUtils } from '../components/order-list-table/order-list-table.utils';
import { PageTitle } from '../../shared/components/page-title/page-title';
import { PageWrapper } from '../../shared/styles/page-wrapper';
import { SaveHTMLOrderListModal } from '../components/save-order-list-modal/save-html-order-list-modal';
import { SaveOrderListModal } from '../components/save-order-list-modal/save-order-list-modal'
import { SharedStateService } from '../../shared/services/shared.state-service';
import { SortingOrder } from '../../shared/interfaces/sorting';
import { _toggleArchiveOrderModal } from '../../order-line/services/order-line.state-service';
import { addBreadcrumb } from '../../aoe/services/aoe-state.service';
import { isEmpty } from 'lodash'
import qs from 'qs';
import { useSelector } from 'react-redux';

interface IUrlParams {
    companyId: string;
    customerId: string;
}
interface Props extends IAzureADFunctionProps {
    isAdmin: boolean;
}

const orderListFilters = [FILTERS.SOA, FILTERS.COMPANY, FILTERS.CUSTOMER_ID, FILTERS.PO_NUMBER, FILTERS.CURRENCY, FILTERS.STATUS, FILTERS.PRICE];
const orderListAdminFilters = [FILTERS.SOA, FILTERS.PO_NUMBER, FILTERS.CURRENCY, FILTERS.STATUS,  FILTERS.PRICE];

const _OrderListPage: React.FC<Props> = (props: Props) => {
    const { isAdmin } = props;

    // state
    const [isEditMode, setIsEditMode] = React.useState<boolean>(false);

    const getOrderListsLoading = useSelector<AppState, boolean>(state => state.orderList.getOrderListsLoading);

    const createOrderListModal = useSelector<AppState>(state => state.orderList.modals.create);
    const createOrderListLoading = useSelector<AppState, boolean>(state => state.orderList.createOrderList.loading);

    const currentOrderList = useSelector<AppState, IOrderList>(state => state.orderList.editOrderList.currentOrderList);
    const editOrderListLoading = useSelector<AppState, boolean>(state => state.orderList.editOrderList.loading);

    const deletedOrderList = useSelector<AppState, IOrderList>(state => state.orderList.deleteOrderList.deletedOrderList);
    const deleteOrderListModal = useSelector<AppState, boolean>(state => state.orderList.modals.delete);
    const deleteOrderListLoading = useSelector<AppState, boolean>(state => state.orderList.deleteOrderList.loading);
    const getOriginalOrderLoading = useSelector<AppState, boolean>(state => state.orderList.document.loading);
    const shipVias = useSelector<AppState, IShipVia[]>(state => state.shared.shipVias);
    const fobs = useSelector<AppState, string[]>(state => state.shared.fobs);
    const orderListValidationLoading = useSelector<AppState, boolean>(state => state.orderList.validationLoading);
    const showFilters = useSelector<AppState, boolean>(state => state.orderList.showFilters);
    const pagination = useSelector<AppState, IPagination<IOrderList>>(state => state.orderList.pagination);
    const viewMode = useSelector<AppState, boolean>(state => state.orderList.viewMode);
    const filterSoas = useSelector<AppState, IFilterSoa[]>(state => state.orderList.filterSoas);
    const siteIDs = useSelector<AppState, ISiteId[]>(state => state.shared.siteIds);
    const archiveModal = useSelector<AppState, boolean>(state => state.orderLine.modals.archiveOrder);
    const countryCodes = useSelector<AppState, ICountryCode[]>(state => state.shared.countryCodes);
    const freights = useSelector<AppState, IFreightPref[]>(state => state.customers.freights);
    const incoterms = useSelector<AppState, IIncoterm[]>(state => state.customers.incoterms);

    const urlParams: IUrlParams = useParams();

    const location = useLocation();
    const history = useHistory();
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

    const [_currentParams, setCurrentParams] = React.useState({} as IApiTableRequest);

    const sortColumn = OrderListService.getSortColumnFromQueryParams(queryParams);

    const initOrderListTableReq: IApiTableRequest = {
        pageSize: 20,
        pageNumber: 1,
        sortColumn: 'id',
        sortOrder: SortingOrder.Desc,
        filters: [{
            key: 'status',
            option: FilterOptions.IsNotEqualTo,
            value: '6',
        }],
    };

    const initAdminOrderListTableReq: IApiTableRequest = {
        pageSize: 20,
        pageNumber: 1,
        sortColumn: 'OrderDate',
        sortOrder: SortingOrder.Desc,
        filters: [{
            key: 'status',
            option: FilterOptions.IsNotEqualTo,
            value: '6',
        }, {
            key: 'customerId',
            option: FilterOptions.IsEqualTo,
            value: urlParams.customerId,
        }],
    };

    const render = () => {

        return (
            <PageWrapper>
                <PageTitle text={"Purchase Order List"} overrides={styled.OrderListPageTitleOverrides} />

                <GeckoTable dataSource={pagination}
                    isLoading={getOrderListsLoading || getOriginalOrderLoading}
                    showPagination={true}
                    filters={isAdmin ? orderListAdminFilters : orderListFilters}
                    apiCall={apiCall}
                    // onRow={onRow}
                    tableId={'ordersAoe'}
                    showRowMenu={true}
                    rowMenu={row => rowMenu(row)}
                    defaultSortColumn={'OrderDate'}
                    actions={{ refresh: true, add: isAdmin, filter: true }}
                    onAddClick={() => _toggleCreateOrderListModal(true)}
                    initConfig={isAdmin ? initAdminOrderListTableReq : initOrderListTableReq}
                    columns={OrderListTableUtils.columns(false, sortColumn)} />

                {
                    deleteOrderListModal &&
                    <ConfirmationModal
                        onClose={() => _toggleDeleteOrderListModal(false)}
                        onConfirm={() => _deleteOrderList(deletedOrderList)}
                        loading={deleteOrderListLoading}
                    />
                }

                {
                    createOrderListModal &&
                    renderSaveModal()
                }

                {
                    showFilters &&
                    <OrderListFilters params={queryParams as unknown as IOrderListRequest}
                        isAdmin={isAdmin}
                        soas={filterSoas} />
                }

                {
                    archiveModal &&
                    <ConfirmationModal onClose={() => _toggleArchiveOrderModal(false)}
                        title={'Are you sure you want to archive this PO?'}
                        confirmationButtonText={'Archive'}
                        onConfirm={() => OrderListStateService.archivePo(currentOrderList.id)} />
                }

                <BackendError />
            </PageWrapper>
        );
    };

    // GET SOAs for filtering
    React.useEffect(() => {
        AuthService.checkAuth(history);

        SharedStateService.getShipVias();
        SharedStateService.getSiteIds();
        SharedStateService.getCountryCodes();
        // should move to shared
        CustomersStateService.getFreights();
        CustomersStateService.getIncoterm();
    }, []);

    React.useEffect(() => {
        if (!isEmpty(currentOrderList)) {
            setIsEditMode(true);
        } else {
            setIsEditMode(false);
        }
    }, [currentOrderList]);

    React.useEffect(() => {
        if (isAdmin) {
            CompaniesStateService.getBreadcrumbCompany(urlParams.companyId);
            CustomersStateService.getBreadcrumbCustomer(urlParams.customerId);
        }

    }, [urlParams.companyId, urlParams.customerId, isAdmin]);

    const rowMenu = (orderList: IOrderList): IRowMenu[] => [{
        label: 'View Order Details',
        onClick: () => onRowClick(orderList),
    }, {
        label: 'View Order Header',
        onClick: () => OrderListTableUtils.handleView(orderList),
        disabled: orderList.orderStatus !== 4,
    }, {
        label: 'Archive PO',
        onClick: () => {
            _toggleArchiveOrderModal(true);
            _setCurrentOrderList(orderList);
        },
        disabled: orderList.orderStatus === 4 || orderList.orderStatus === 7 || orderList.orderStatus === 6,
    }];

    const apiCall = (params: IApiTableRequest) => {
        let filters: IApiTableFilter[] = [];

        if (params.filters) {
            filters = [ ...params.filters ];
        }

        if (isAdmin) {
            filters.push({
                key: FILTERS.CUSTOMER_ID,
                value: urlParams.customerId as string,
                option: FilterOptions.IsEqualTo,
            });
        }

        // Search for Status filter
        // If it's not found, it means that no filter related to status was applied
        // and we need to apply the default filter (don't show "archived" POs)
        let foundStatusFilter = false;

        filters.forEach(f => {
            if (f.key === FILTERS.STATUS) {
                foundStatusFilter = true;
            }
        });

        if (!foundStatusFilter) {
            filters.push({
                key: FILTERS.STATUS,
                value: '6',
                option: FilterOptions.IsNotEqualTo,
            });
        }

        let copyParams = {
            ...params,
            filters,
        };

        setCurrentParams(copyParams);

        OrderListStateService.getAllOrderLists(copyParams);
    };

    const onRowClick = (orderList: IOrderList) => {
        const { pathname } = history.location;
        const path = `${pathname}/${orderList.id}/purchaseorder-lines`;
        const breadcrumb: IBreadcrumb = {
            path,
            label: `Order List: ${orderList.id}`,
        };

        addBreadcrumb(breadcrumb);

        OrderListStateService.setBreadcrumbOrderList(orderList);

        history.push(path);
    };

    const renderSaveModal = () => {
        if (currentOrderList && currentOrderList.typeId === 1) {
            return (
                <SaveHTMLOrderListModal
                    orderListValidationLoading={orderListValidationLoading}
                    onSubmit={_createOrderList}
                    currentOrderList={currentOrderList && currentOrderList}
                    isEditMode={isEditMode}
                    onSubmitEdit={_editOrderList}
                    viewMode={viewMode}
                    shipVias={shipVias}
                    onClose={() => _resetCurrentOrderList()}
                    loading={editOrderListLoading ? editOrderListLoading : createOrderListLoading}
                    externalCustomerId={currentOrderList.externalCustomerId}
                    siteIds={siteIDs}
                    countryCodes={countryCodes}
                    onValidateClick={() => onValidateClick()}
                />
            );
        }

        return (
            <SaveOrderListModal
                orderListValidationLoading={orderListValidationLoading}
                onSubmit={_createOrderList}
                currentOrderList={currentOrderList && currentOrderList}
                isEditMode={isEditMode}
                shipVias={shipVias}
                onSubmitEdit={_editOrderList}
                viewMode={viewMode}
                onClose={() => _resetCurrentOrderList()}
                loading={editOrderListLoading ? editOrderListLoading : createOrderListLoading}
                externalCustomerId={currentOrderList.externalCustomerId}
                fobs={freights}
                incoterms={incoterms}
                siteIds={siteIDs}
                countryCodes={countryCodes}
                onValidateClick={() => onValidateClick()}
            />
        );
    };

    const onValidateClick = () => {
        const { id } = currentOrderList;

        OrderListStateService.toggleValidationLoading(true);
        OrderListStateService.validateOrderList(id.toString());
    };

    return render();
};

export const OrderListPage = _OrderListPage;
