import * as companiesActions from './companies.actions';
import * as companiesWebapi from '../webapi/companies.webapi';

import { Action, IApiTableRequest } from "../../shared/interfaces/shared";
import { ICompany, ISaveCompanyReq } from "../interfaces/company";
import { _getCompanies, _toggleCompaniesLoading, _toggleDeleteCompanyModal, _toggleSaveCompanyModal } from '../services/companies.state-service';
import { catchError, concatMap, map, tap } from "rxjs/operators";

import { ActionsObservable } from "redux-observable";
import { Errors } from '../../shared/services/errors.service';
import { SharedStateService } from '../../shared/services/shared.state-service';
import { SnackbarTypes } from '../../shared/components/snackbar/snackback.utils';
import { of } from "rxjs";
import { store } from '../../shared/services/state.service';

export const saveCompany$ = (action$: ActionsObservable<Action<ISaveCompanyReq>>) =>
    action$.ofType(companiesActions.SAVE_COMPANY_REQ).pipe(
        map(action => action.payload!),
        concatMap(req => {
            if (req.isEditMode) {
                const id = req.id;
                delete req.isEditMode;

                return companiesWebapi.editCompany(req, id!).pipe(
                    map(company => {
                        _toggleSaveCompanyModal(false);
                        let apiTableRequest: IApiTableRequest = store.getState().shared.apiTableRequest;

                        _getCompanies(apiTableRequest);
                        SharedStateService.setSnackbar({
                            message: `Company with the name "${req.name}" was updated.`,
                            type: SnackbarTypes.SUCCESS,
                            isOpen: true,
                        });

                        return companiesActions.saveCompanyOk(company);
                    }),
                    catchError(error => {
                        let err = error.response.data;

                        SharedStateService.setSnackbar({
                            message: err.description,
                            type: SnackbarTypes.ERROR,
                            isOpen: true,
                        });

                        return of(companiesActions.saveCompanyFail(err));
                    }),
                );
            }

            return companiesWebapi.createCompany(req).pipe(
                map(company => {
                    _toggleSaveCompanyModal(false);
                    _getCompanies({ pageNumber: 1, pageSize: 20, filters: [] });
                    SharedStateService.setSnackbar({
                        message: `Company with the name "${company.name}" was created.`,
                        type: SnackbarTypes.SUCCESS,
                        isOpen: true,
                    });

                    return companiesActions.saveCompanyOk(company);
                }),
                catchError(error => {
                    const err = error.response.data;

                    SharedStateService.setSnackbar({
                        isOpen: true,
                        message: err.description,
                        type: SnackbarTypes.ERROR,
                    });

                    return of(companiesActions.saveCompanyFail(error.response.data));
                }),
            );
        }),
    );

export const getCompanies$ = (action$: ActionsObservable<Action<IApiTableRequest>>) =>
    action$.ofType(companiesActions.GET_COMPANIES_REQ).pipe(
        tap(() => _toggleCompaniesLoading(true)),
        map(action => action.payload!),
        concatMap(req => companiesWebapi.getCompanies(req).pipe(
            tap(() => _toggleCompaniesLoading(false)),
            map(companies => companiesActions.getCompaniesOk(companies)),
            catchError(error => {
                _toggleCompaniesLoading(false);

                return of(companiesActions.getCompaniesFail(error.response && error.response.data))
            }),
        )),
    );

export const deleteCompany$ = (action$: ActionsObservable<Action<ICompany>>) =>
    action$.ofType(companiesActions.DELETE_COMPANY_REQ).pipe(
        map(action => action.payload!),
        concatMap(company => companiesWebapi.deleteCompany(company).pipe(
            map(company => {
                _getCompanies({ pageNumber: 1, pageSize: 20, filters: [] });
                _toggleDeleteCompanyModal(false);
                SharedStateService.setSnackbar({
                    isOpen: true,
                    message: 'Company successfully deleted',
                    type: SnackbarTypes.SUCCESS,
                });

                return companiesActions.deleteCompanyOk(company);
            }),
            catchError(error => {
                const err = error.response && error.response.data;

                SharedStateService.setSnackbar({
                    isOpen: true,
                    message: (err && err.description) || Errors.COMMON,
                    type: SnackbarTypes.ERROR,
                });

                return of(companiesActions.deleteCompanyFail(err));
            }),
        )),
    );

export const getCompanyById$ = (action$: ActionsObservable<Action<string>>) =>
    action$.ofType(companiesActions.GET_COMPANY_BY_ID_REQ).pipe(
        map(action => action.payload!),
        concatMap(id => {
            return companiesWebapi.getCompanyById(id).pipe(
                map(company => companiesActions.getCompanyByIdOk(company)),
                catchError(err => of(companiesActions.getCompanyByIdFail(err.response && err.response.data))),
            );
        }),
    );

export const getBreadcrumbCompany$ = (action$: ActionsObservable<Action<string>>) =>
    action$.ofType(companiesActions.GET_BREADCRUMB_COMPANY_REQ).pipe(
        map(action => action.payload!),
        concatMap(id => companiesWebapi.getCompanyById(id).pipe(
            map(company => companiesActions.getBreadcrumbCompanyOk(company)),
            catchError(error => of(companiesActions.getBreadcrumbCompanyFail(error.response.data))),
        )),
    );

export const getFilterCompanies$ = (action$: ActionsObservable<Action<null>>) =>
    action$.ofType(companiesActions.GET_FILTER_COMPANIES_REQ).pipe(
        map(action => action.payload),
        concatMap(() => companiesWebapi.getFilterCompanies().pipe(
            map(companies => companiesActions.getFilterCompaniesOk(companies)),
            catchError(err => of(companiesActions.getFilterCompaniesFail(err.response && err.response.data))),
        )),
    );

export const getErpCompanies$ = (action$: ActionsObservable<Action<null>>) =>
    action$.ofType(companiesActions.GET_ERP_COMPANIES_REQ).pipe(
        map(action => action.payload),
        concatMap(() => companiesWebapi.getErpCompanies().pipe(
            map(companies => companiesActions.getErpCompaniesOk(companies)),
            catchError(err => of(companiesActions.getErpCompaniesFail(err.response && err.response.data))),
        )),
    );
