import { IOrderList, IUpdateAddressReq } from '../../interfaces/order-list';
import React, { useState } from 'react';

import { Button } from '../../../shared/components/button/button';
import { ButtonWrapper } from '../save-order-list-modal/save-order-list-modal.style';
import { Dropdown } from '../../../shared/components/dropdown/dropdown';
import { ICountryCode } from '../../../shared/interfaces/country-code';
import { Input } from '../../../shared/components/input/input';
import { Modal } from '../../../shared/components/modal/modal';
import { isEmpty } from 'lodash';

interface Props {
    onSubmit: (req: IUpdateAddressReq) => void;
    orderList: IOrderList;
    onClose: () => void;
    countryCodes: ICountryCode[];
}

export const UpdateAddressModal: React.FC<Props> = (props: Props) => {
    const { onSubmit, orderList, onClose, countryCodes } = props;

    const [inputs, setInputs] = useState({
        revisedShipToAddressLine1: orderList.revisedShipToAddressLine1 || '',
        revisedShipToAddressLine2: orderList.revisedShipToAddressLine2 || '',
        revisedShipToCity: orderList.revisedShipToCity || '',
        revisedShipToState: orderList.revisedShipToState || '',
        revisedShipToCountry: orderList.revisedShipToCountry || '',
        revisedShipToZipCode: orderList.revisedShipToZipCode || '',
    });

    const [customErrors, setCustomErrors] = useState({
        revisedShipToAddressLine1: '',
        revisedShipToAddressLine2: '',
        revisedShipToCity: '',
        revisedShipToState: '',
        revisedShipToCountry: '',
        revisedShipToZipCode: '',
    });

    const getInitRevisedShipToCountry = () => {
        if (!isEmpty(orderList) && orderList.revisedShipToCountry) {
            const foundCountry = countryCodes.find(c => c.code === orderList.revisedShipToCountry);

            return foundCountry;
        }

        return {} as ICountryCode;
    };

    const [revisedShipToCountry, setRevisedShipToCountry] = useState(getInitRevisedShipToCountry());

    const render = () => {
        return (
            <Modal onClose={onClose}>
                <Input
                    error={customErrors.revisedShipToAddressLine1}
                    value={inputs.revisedShipToAddressLine1}
                    maxLength={50}
                    // onInternalError={onInternalInputErrors}
                    inputId={'revisedShipToAddressLine1'}
                    label={"Revised Ship To Address Line 1"}
                    onChange={(text: string) => onInputChange(text.toUpperCase(), 'revisedShipToAddressLine1', 'string')}
                />

                <Input
                    error={customErrors.revisedShipToAddressLine2}
                    value={inputs.revisedShipToAddressLine2}
                    maxLength={50}
                    // onInternalError={onInternalInputErrors}
                    inputId={'revisedShipToAddressLine2'}
                    label={"Revised Ship To Address Line 2"}
                    onChange={(text: string) => onInputChange(text.toUpperCase(), 'revisedShipToAddressLine2', 'string')}
                />

                <Input
                    error={customErrors.revisedShipToCity}
                    value={inputs.revisedShipToCity}
                    label={"Revised Ship To City"}
                    maxLength={30}
                    // onInternalError={onInternalInputErrors}
                    inputId={'revisedShipToCity'}
                    onChange={(text: string) => onInputChange(text.toUpperCase(), 'revisedShipToCity', 'string')}
                />

                <Input
                    error={customErrors.revisedShipToState}
                    value={inputs.revisedShipToState}
                    maxLength={30}
                    // onInternalError={onInternalInputErrors}
                    inputId={'revisedShipToState'}
                    label={"Revised Ship To State"}
                    onChange={(text: string) => onInputChange(text.toUpperCase(), 'revisedShipToState', 'string')}
                />

                <Input
                    error={customErrors.revisedShipToZipCode}
                    value={inputs.revisedShipToZipCode}
                    maxLength={10}
                    label={"Revised Ship To Zipcode"}
                    // onInternalError={onInternalInputErrors}
                    inputId={'revisedShipToZipcode'}
                    onChange={(text: string) => onInputChange(text.toUpperCase(), 'revisedShipToZipCode', 'string')}
                />

                <Dropdown
                    error={customErrors.revisedShipToCountry}
                    keyToShow={'code'}
                    value={revisedShipToCountry}
                    label={"Revised Ship To Country"}
                    options={countryCodes}
                    required={true}
                    allowClear={true}
                    onChange={code => setRevisedShipToCountry(code)} />

                <ButtonWrapper>
                    <Button disabled={isSaveButtonDisabled()}
                        label={'Submit'}
                        onClick={() => onSubmit({
                            ...inputs,
                            revisedShipToCountry: revisedShipToCountry!.code,
                            id: orderList.id,
                        })} />
                </ButtonWrapper>
            </Modal>
        );
    };

    const onInputChange = (text: string | Date | number, key: string, type: string) => {
        let newInputs = { ...inputs, [key]: text };

        setInputs(newInputs);

        handleValidation(text, key, type);
    };

    const handleValidation = (text: string | Date | number, key: string, type: string) => {
        switch (type) {
            case 'date': {
                if (!text) {
                    setCustomErrors({ ...customErrors, [key]: 'Required' })
                } else {
                    setCustomErrors({ ...customErrors, [key]: '' })
                }
                break;
            }

            case 'string': {
                if (isEmpty(text)) {
                    setCustomErrors({ ...customErrors, [key]: 'Required' })
                } else {
                    setCustomErrors({ ...customErrors, [key]: '' })
                }
                break;
            }

            default: {
                setCustomErrors({ ...customErrors, [key]: '' })
            }
        }
    }

    const isSaveButtonDisabled = () => {
        if (isEmpty(revisedShipToCountry)) {
            return true;
        }

        return false;
    };

    return render();
};
