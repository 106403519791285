import { IAzureADFunctionProps } from 'react-aad-msal';
import React from 'react';
import { foeRoutes } from '../routes/foe.routes';

interface Props extends IAzureADFunctionProps {}

export const FoePage: React.FC<Props> = (props: Props) => {
    const render = () => {
        return (
            <React.Fragment>
                {foeRoutes(props)}
            </React.Fragment>
        );
    };

    return render();
};
