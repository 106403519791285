import * as React from 'react';

import { CompaniesPage } from '../../companies/pages/companies.page';
import { CustomersInventoryPage } from '../../customers/pages/customers-inventory.page';
import { CustomersPage } from '../../customers/pages/customers.page';
import { CustomersShipmentPage } from '../../customers/pages/customers-shipment.page';
import { IAzureADFunctionProps } from 'react-aad-msal';
import { OrderLinePage } from '../../order-line/pages/order-line.page';
import { OrderListPage } from '../../order-list/pages/order-list.page';
import { Route } from 'react-router';
import { SOAPage } from '../../soa/pages/soa.page';
import { DayOffPage } from '../../day-off/pages/day-off.page';
import { SettingsPage } from '../../users/pages/settings.page';
import { UserRolesPage } from '../../users/pages/user-roles.page';

export const adminRoutes = (azureAd: IAzureADFunctionProps) => {
    return [
        <Route exact={true}
            path={'/admin/soa'}
            key={'soa'}
            render={props => <SOAPage {...props} {...azureAd} />} />,

        <Route exact={true}
            path={'/admin/companies'}
            key={'companies'}
            render={props => <CompaniesPage {...props} {...azureAd} />} />,

        // Customers
        <Route exact={true}
            path='/admin/companies/:companyId/customerIds'
            key={'customers'}
            render={props => <CustomersPage {...props} {...azureAd} />} />,

        // Customers Shipping
        <Route exact={true}
            path='/admin/shipment/:customerId'
            key={'customers-shipment'}
            render={props => <CustomersShipmentPage {...props} {...azureAd} /> } />,

        // Customers Inventory PO
        <Route exact={true}
            path='/admin/inventory/:customerId'
            key={'customers-inventory'}
            render={props => <CustomersInventoryPage {...props} {...azureAd} /> } />,

        // Order List
        <Route exact={true}
            path='/admin/companies/:companyId/customerIds/:customerId/purchaseorder-list'
            key={'orderList'}
            render={props => <OrderListPage {...props} {...azureAd} isAdmin={true} />} />,

        //  Order Line
        <Route exact={true}
            path='/admin/companies/:companyId/customerIds/:customerId/purchaseorder-list/:orderListId/purchaseorder-lines'
            key={'orderLine'}
            render={props => <OrderLinePage {...props} {...azureAd} />} />,

        // User Roles
        <Route exact={true}
            path='/admin/userRoles'
            key={'user-roles'}
            render={props => <UserRolesPage {...props} {...azureAd} /> } />,

        // days off
        <Route exact={true}
        path='/admin/daysOff'
        key={'daysoff'}
        render={props => <DayOffPage {...props} {...azureAd} />} />,

        // Settings
        <Route exact={true}
            path='/admin/settings'
            key={'admin-settings'}
            render={props => <SettingsPage {...props} {...azureAd} />} />,

        // Default path
        // <Redirect from='/admin'
        //     key={'admin'}
        //     exact={true}
        //     to='/admin/soa' />,
    ];
};
