export const config = {
    appId: '7a948eba-c652-4eae-ac12-83b980c64c41',
    authority: 'https://login.microsoftonline.com/f193f6f5-62be-492d-897d-776cde125585/',
    // redirectUri: 'http://localhost:3000',
    redirectUri: 'https://app-dev.customer.geckoalliance.com',
    apiUrl: 'https://api-dev.customer.geckoalliance.com',
    scopes: ['api://e1fa5d4f-3048-4737-a2d2-1c9ab167e129/General.Scope'],
    isAC: false,
    b2c: {
        tenant: "geckob2cdev.onmicrosoft.com",
        scopes: ['https://geckob2cdev.onmicrosoft.com/api/General.Scope'],
        signInPolicy: "B2C_1A_SignIn",
        applicationID: "b3d4b4e5-ca10-4ae8-bafd-68114159b552",
        instance: `https://geckob2cdev.b2clogin.com/`,
    },
};
