import { ButtonWrapper, FormColumn, FormRow, FormWrapper } from './save-order-list-modal.style';
import { IFreightPref, IIncoterm } from '../../../customers/interfaces/customer';
import { IOrderList, IOrderListError, IOrderListSaveReq } from '../../interfaces/order-list';
import React, { useEffect } from 'react';
import { get, isDate, isEmpty } from 'lodash';

import { ActivityIndicator } from '../../../shared/components/activity-indicator/activity-indicator';
import { Button } from '../../../shared/components/button/button';
import { CURRENCIES } from '../../../shared/services/utils.service';
import { DateSelector } from '../../../shared/components/date-selector/date-selector'
import { Dropdown } from '../../../shared/components/dropdown/dropdown'
import { ICountryCode } from '../../../shared/interfaces/country-code';
import { IShipVia } from '../../../shared/interfaces/ship-via';
import { ISiteId } from '../../../shared/interfaces/site-id';
import { Input } from '../../../shared/components/input/input';
import { Modal } from '../../../shared/components/modal/modal';
import { OrderListService } from '../../services/order-list.service';
import { SharedService } from '../../../shared/services/shared.service';
import moment from 'moment';

interface Props {
    /**function for submit */
    onSubmit: (values: IOrderListSaveReq) => void;
    /** function for edit submit */
    onSubmitEdit?: (values: IOrderListSaveReq) => void;

    onValidateClick: () => void;

    /** function to close the modal */
    onClose: () => void;
    /** initial values for edit mode */
    currentOrderList: IOrderList;
    /** modal loading */
    loading?: boolean;
    /** boolean to check is edit mode */
    isEditMode?: boolean;

    externalCustomerId: string;

    getAllOrderList?: boolean;

    shipVias: IShipVia[];

    errors?: IOrderListError[];

    fobs: IFreightPref[];

    orderListValidationLoading: boolean;

    viewMode?: boolean;

    siteIds: ISiteId[];

    countryCodes: ICountryCode[];

    incoterms: IIncoterm[];
}

/** Create Order List Modal */
export const SaveOrderListModal: React.FC<Props> = (props: Props) => {
    const { onClose, currentOrderList, loading, onSubmit, isEditMode, onSubmitEdit, externalCustomerId, getAllOrderList, shipVias, errors, fobs, orderListValidationLoading, viewMode, siteIds, countryCodes, incoterms } = props;

    const getConfirmedDate = () => {
        if (!isEmpty(currentOrderList)) {
            if (currentOrderList!.confirmedDate && isDate(new Date(currentOrderList!.confirmedDate))) {
                return moment(currentOrderList!.confirmedDate);
            }

            if (currentOrderList!.poDate) {
                return moment(currentOrderList!.poDate);
            }
        }

        return null;
    };

    const getRevisedFob = () => {
        if (!isEmpty(currentOrderList)) {
            if (currentOrderList.revisedFob) {
                return currentOrderList.revisedFob;
            }

            return '';
        }

        return '';
    };

    const getRevisedShipVia = () => {
        if (!isEmpty(currentOrderList)) {
            if (currentOrderList.revisedShipViaText) {
                let shipViaFound = shipVias.filter(sv => sv.code === currentOrderList.revisedShipViaText)[0];

                return shipViaFound;
            }
        }

        return {} as IShipVia;
    };

    const getSiteId = () => {
        if (!isEmpty(currentOrderList)) {
            if (currentOrderList.siteId) {
                let siteIdFound = siteIds.filter(si => si.siteId === currentOrderList.siteId)[0];

                return siteIdFound;
            }
        }

        return {} as ISiteId;
    };

    const getInitRevisedShipToCountry = () => {
        if (!isEmpty(currentOrderList) && currentOrderList.revisedShipToCountry) {
            const foundCountry = countryCodes.find(c => c.code === currentOrderList.revisedShipToCountry);

            return foundCountry;
        }

        return {} as ICountryCode;
    };

    const getInitialFob = () => {
        if (!isEmpty(currentOrderList) && currentOrderList.revisedFob) {
            const foundFob = fobs.find(f => f.name === currentOrderList.revisedFob);

            return foundFob;
        }

        return {} as IFreightPref;
    };

    const getInitialIncoterm = () => {
        if (!isEmpty(currentOrderList) && currentOrderList.incoterm) {
            const foundIncoterm = incoterms.find(i => i.code === currentOrderList.incoterm);

            return foundIncoterm;
        }

        return {} as IIncoterm;
    };

    /** fields values */
    const [inputs, setInputs] = React.useState({
        customerId: (currentOrderList && currentOrderList.customerId) || '',
        externalCustomerId: externalCustomerId,
        poNumber: (currentOrderList && currentOrderList.poNumber) || '',
        poDate: (currentOrderList && currentOrderList.poDate) || '',
        revisedPoDate: (currentOrderList && currentOrderList.revisedPoDate && moment(currentOrderList.revisedPoDate)) || null,
        customerRequestedDate: (currentOrderList && currentOrderList.customerRequestedDate) || '',
        confirmedDate: getConfirmedDate(),
        revisedFob: getRevisedFob(),
        revisedCustomerRequestedDate: (currentOrderList && currentOrderList.revisedCustomerRequestedDate && moment(currentOrderList.revisedCustomerRequestedDate)) || null,
        shipViaText: (currentOrderList && currentOrderList.shipViaText) || '',
        orderDate: (currentOrderList && currentOrderList.orderDate) || '',
        orderStatus: (currentOrderList && OrderListService.getCurrentStatus(currentOrderList.orderStatus)) || OrderListService.STATUSES[0],
        orderStatusDate: currentOrderList && currentOrderList.orderStatusDate ? moment(currentOrderList.orderStatusDate) : '',
        // shipDate: (currentOrderList && currentOrderList.shipDate && new Date(currentOrderList.shipDate)) || '',
        fob: (currentOrderList && currentOrderList.fob) || '',
        shipToAddress: (currentOrderList && currentOrderList.shipToAddress) || '',
        billTo: (currentOrderList && currentOrderList.billTo) || '',
        shipToFullName: (currentOrderList && currentOrderList.shipToFullName) || '',
        revisedShipToFullName: (currentOrderList && currentOrderList.revisedShipToFullName) || '',
        shipToCompanyName: (currentOrderList && currentOrderList.shipToCompanyName) || '',
        revisedShipToCompanyName: (currentOrderList && currentOrderList.revisedShipToCompanyName) || '',
        revisedShipToCountry: (currentOrderList && currentOrderList.revisedShipToCountry) || '',
        revisedShipToAddressLine1: (currentOrderList && currentOrderList.revisedShipToAddressLine1) || '',
        revisedShipToAddressLine2: (currentOrderList && currentOrderList.revisedShipToAddressLine2) || '',
        revisedShipToCity: (currentOrderList && currentOrderList.revisedShipToCity) || '',
        revisedShipToState: (currentOrderList && currentOrderList.revisedShipToState) || '',
        revisedShipToZipCode: (currentOrderList && currentOrderList.revisedShipToZipCode) || '',
        incoterm: (currentOrderList && currentOrderList.incoterm) || '',
    });

    const [currency, setCurrency] = React.useState(
        SharedService.getCurrency(!isEmpty(currentOrderList) ? currentOrderList!.currency : null)
    );

    const [siteId, setSiteId] = React.useState(
        getSiteId(),
    );

    const [revisedShipViaTextObj, setRevisedShipViaTextObj] = React.useState(getRevisedShipVia());
    const [revisedShipToCountryCode, setRevisedShipToCountryCode] = React.useState(getInitRevisedShipToCountry());
    const [revisedFob, setRevisedFob] = React.useState(getInitialFob());
    const [incoterm, setIncoterm] = React.useState(getInitialIncoterm());
    const [revisedShipViaText, setRevisedShipViaText] = React.useState(currentOrderList && currentOrderList.revisedShipViaText || '');

    const [customErrors, setCustomErrors] = React.useState({
        externalCustomerId: '',
        poNumber: '',
        poDate: '',
        revisedPoDate: '',
        customerRequestedDate: '',
        confirmedDate: '',
        shipViaText: '',
        shipVia: '',
        revisedCustomerRequestedDate: '',

        siteId: '',
        fob: '',
        currency: '',
        orderDate: '',
        orderStatus: '',
        // shipDate: '',
        earlyShippingDate: '',
        orderStatusDate: '',
        revisedDate: '',
        revisedFob: '',
        shipToAddress: '',
        billTo: '',
        incoterm: '',

        shipToFullName: '',
        revisedShipToFullName: '',
        shipToCompanyName: '',
        revisedShipToCompanyName: '',
        revisedShipToCountry: '',
        revisedShipToAddressLine1: '',
        revisedShipToAddressLine2: '',
        revisedShipToCity: '',
        revisedShipToState: '',
        revisedShipToZipCode: '',
    });

    const [internalErrors, setInternalErrors] = React.useState({});

    const render = () => {
        if (loading || orderListValidationLoading) {
            return (
                <Modal onClose={() => onClose()} >
                    <ActivityIndicator />
                </Modal>
            );
        } else {
            return (
                <Modal onClose={() => onClose()}>
                    <FormWrapper>
                        <FormRow>
                            <FormColumn paddingRight="10px">
                                <Input
                                    error={customErrors.externalCustomerId}
                                    value={inputs.externalCustomerId}
                                    disabled={true}
                                    label={"External Customer ID"}
                                    onChange={(text: string) => onInputChange(text, 'externalCustomerId', 'string', false)}
                                />

                            </FormColumn>

                            <FormColumn paddingLeft="10px">
                                {
                                    currentOrderList.typeId !== 1 &&
                                    <Input
                                        error={customErrors.poNumber}
                                        value={inputs.poNumber}
                                        label={"Po Number"}
                                        disabled={viewMode}
                                        maxLength={250}
                                        onInternalError={onInternalInputErrors}
                                        inputId={'poNumber'}
                                        required={true}
                                        onChange={(text: string) => onInputChange(text, 'poNumber', 'string', true)}
                                    />
                                }

                                {
                                    currentOrderList.typeId === 1 &&
                                    <Input error={customErrors.shipToAddress}
                                        value={inputs.shipToAddress}
                                        label='Ship To'
                                        disabled={viewMode || true}
                                        isTextArea={true}
                                        required={true}
                                        maxLength={255}
                                        onInternalError={onInternalInputErrors}
                                        inputId={'shipToAddress'}
                                        onChange={(text: string) => onInputChange(text, 'shipToAddress', 'string', true)} />
                                }
                            </FormColumn>

                        </FormRow>

                        <FormRow>
                            {
                                currentOrderList.typeId !== 1 &&
                                <FormColumn paddingRight='10px'>
                                    <Input
                                        disabled={true}
                                        error={customErrors.poDate}
                                        value={inputs.poDate}
                                        label={"Customer PO Date (Order Date)"}
                                        onChange={(text: string) => onInputChange(text, 'poDate', 'date', false)}
                                    />
                                </FormColumn>
                            }

                            <FormColumn paddingLeft='10px'>
                                <DateSelector
                                    error={customErrors.revisedPoDate}
                                    disabled={viewMode}
                                    value={inputs.revisedPoDate}
                                    label={"Revised PO Date"}
                                    required={true}
                                    onChange={(text: string) => onInputChange(text, 'revisedPoDate', 'date', true)}
                                />
                            </FormColumn>
                        </FormRow>

                        <FormRow>
                            <FormColumn paddingRight="10px">
                                <DateSelector
                                    error={customErrors.orderDate}
                                    value={moment(inputs.orderDate)}
                                    format={'MM/DD/YYYY'}
                                    label={"PO Received Date"}
                                    disabled={true}
                                    onChange={(text: Date) => onInputChange(text, 'orderDate', 'date', false)}
                                />
                            </FormColumn>

                            <FormColumn paddingLeft="10px">
                                <DateSelector
                                    error={customErrors.confirmedDate}
                                    value={inputs.confirmedDate}
                                    label={"Confirmed Date (Desired Ship Date)"}
                                    disabled={viewMode}
                                    required={true}
                                    onChange={(text: Date) => onInputChange(text, 'confirmedDate', 'date', true)}
                                />
                            </FormColumn>
                        </FormRow>

                        <FormRow>
                            <FormColumn paddingRight="10px">
                                {/* CustomerRequestedDate */}
                                <Input error={customErrors.customerRequestedDate}
                                    label={'\n Customer Requested Date'}
                                    value={inputs.customerRequestedDate}
                                    disabled={true} />
                            </FormColumn>

                            <FormColumn paddingLeft='10px'>
                                <DateSelector
                                    error={customErrors.revisedCustomerRequestedDate}
                                    value={inputs.revisedCustomerRequestedDate}
                                    disabled={viewMode}
                                    required={true}
                                    label={"Revised Customer Requested Date (Promise Del Date)"}
                                    onChange={(text: Date) => onInputChange(text, 'revisedCustomerRequestedDate', 'date', true)}
                                />
                            </FormColumn>
                        </FormRow>

                        <FormRow>
                            <FormColumn paddingRight='10px'>
                                <Input
                                    error={customErrors.shipViaText}
                                    value={inputs.shipViaText}
                                    label={'Ship Via'}
                                    maxLength={100}
                                    onInternalError={onInternalInputErrors}
                                    inputId={'shipVia'}
                                    disabled={true}
                                    onChange={(text: string) => onInputChange(text, 'shipViaText', 'string', false)}
                                />
                            </FormColumn>

                            <FormColumn paddingLeft="10px">
                                {/* <Dropdown
                                    allowClear={true}
                                    error={customErrors.shipVia}
                                    keyToShow={'code'}
                                    value={revisedShipViaTextObj}
                                    label={'Revised Ship Via'}
                                    disabled={viewMode}
                                    options={shipVias}
                                    onChange={(sp: IShipVia) => setRevisedShipViaTextObj(sp)}
                                /> */}
                                <Input
                                    value={revisedShipViaText}
                                    label={'Revised Ship Via'}
                                    maxLength={100}
                                    onInternalError={onInternalInputErrors}
                                    inputId={'shipVia'}
                                    onChange={(text: string) => setRevisedShipViaText(text.toUpperCase())}
                                />
                            </FormColumn>
                        </FormRow>

                        <FormRow>
                            <FormColumn paddingRight="10px">
                                <Dropdown
                                    allowClear={true}
                                    error={customErrors.incoterm}
                                    keyToShow={'code'}
                                    value={incoterm}
                                    label={'Incoterm'}
                                    disabled={viewMode}
                                    options={incoterms}
                                    onChange={incoterm => handleDropdownChange('incoterm', incoterm, () => setIncoterm(incoterm), false)}
                                />

                            </FormColumn>

                            <FormColumn paddingLeft='10px'>
                                <Dropdown
                                    error={customErrors.revisedFob}
                                    options={fobs}
                                    keyToShow={'name'}
                                    value={revisedFob}
                                    label={"Revised FOB"}
                                    disabled={viewMode}
                                    required={true}
                                    allowClear={true}
                                    onChange={fob => handleDropdownChange('revisedFob', fob, () => setRevisedFob(fob), true)}
                                />
                            </FormColumn>
                        </FormRow>

                        <FormRow>
                            <FormColumn paddingRight="10px">
                                <Dropdown error={customErrors.currency}
                                    keyToShow={'name'}
                                    value={currency}
                                    options={CURRENCIES}
                                    label={'Currency'}
                                    disabled={true}
                                    onChange={currency => setCurrency(currency)} />
                            </FormColumn>

                            <FormColumn paddingLeft='10px'>
                                <Dropdown
                                    error={customErrors.orderStatus}
                                    keyToShow={"name"}
                                    value={inputs.orderStatus}
                                    label={"Order Status"}
                                    disabled={true}
                                    options={OrderListService.STATUSES}
                                    onChange={(text: string) => onInputChange(text, 'orderStatus', 'string', false)} />
                            </FormColumn>

                        </FormRow>

                        <FormRow>
                            <Input error={customErrors.shipToAddress}
                                isTextArea={true}
                                value={inputs.shipToAddress}
                                label='Ship To Address'
                                disabled={viewMode || true}
                                required={true}
                                onChange={(text: string) => onInputChange(text, 'shipToAddress', 'string')} />
                        </FormRow>

                        {/* <FormRow>
                            <FormColumn paddingRight='10px'>
                                <Input
                                    error={customErrors.shipToFullName}
                                    value={inputs.shipToFullName}
                                    disabled={true}
                                    label={"Ship To Full Name"}
                                    onChange={(text: string) => onInputChange(text, 'shipToFullName', 'string')}
                                />
                            </FormColumn>

                            <FormColumn paddingLeft='10px'>
                                <Input
                                    error={customErrors.revisedShipToFullName}
                                    value={inputs.revisedShipToFullName}
                                    disabled={viewMode}
                                    label={"Revised Ship To Full Name"}
                                    onChange={(text: string) => onInputChange(text, 'revisedShipToFullName', 'string')}
                                />
                            </FormColumn>
                        </FormRow> */}

                        <FormRow>
                            <FormColumn paddingRight='10px'>
                                <Input
                                    error={customErrors.shipToCompanyName}
                                    value={inputs.shipToCompanyName}
                                    disabled={true}
                                    label={"Ship To Company Name"}
                                    onChange={(text: string) => onInputChange(text, 'shipToCompanyName', 'string')}
                                />
                            </FormColumn>

                            <FormColumn paddingLeft='10px'>
                                <Input
                                    error={customErrors.revisedShipToCompanyName}
                                    value={inputs.revisedShipToCompanyName}
                                    maxLength={100}
                                    onInternalError={onInternalInputErrors}
                                    inputId={'revisedShipToCompanyName'}
                                    disabled={viewMode}
                                    label={"Revised Ship To Company Name"}
                                    onChange={(text: string) => onInputChange(text.toUpperCase(), 'revisedShipToCompanyName', 'string')}
                                />
                            </FormColumn>
                        </FormRow>

                        <FormRow>
                            <FormColumn paddingRight='10px'>
                                <Input
                                    error={customErrors.revisedShipToAddressLine1}
                                    value={inputs.revisedShipToAddressLine1}
                                    disabled={viewMode}
                                    maxLength={50}
                                    onInternalError={onInternalInputErrors}
                                    inputId={'revisedShipToAddressLine1'}
                                    label={"Revised Ship To Address Line 1"}
                                    onChange={(text: string) => onInputChange(text.toUpperCase(), 'revisedShipToAddressLine1', 'string')}
                                />
                            </FormColumn>

                            <FormColumn paddingLeft='10px'>
                                <Input
                                    error={customErrors.revisedShipToAddressLine2}
                                    value={inputs.revisedShipToAddressLine2}
                                    disabled={viewMode}
                                    maxLength={50}
                                    onInternalError={onInternalInputErrors}
                                    inputId={'revisedShipToAddressLine2'}
                                    label={"Revised Ship To Address Line 2"}
                                    onChange={(text: string) => onInputChange(text.toUpperCase(), 'revisedShipToAddressLine2', 'string')}
                                />
                            </FormColumn>
                        </FormRow>

                        <FormRow>
                            <FormColumn paddingRight='10px'>
                                <Input
                                    error={customErrors.revisedShipToCity}
                                    value={inputs.revisedShipToCity}
                                    disabled={viewMode}
                                    maxLength={30}
                                    onInternalError={onInternalInputErrors}
                                    inputId={'revisedShipToCity'}
                                    label={"Revised Ship To City"}
                                    onChange={(text: string) => onInputChange(text.toUpperCase(), 'revisedShipToCity', 'string')}
                                />
                            </FormColumn>

                            <FormColumn paddingLeft='10px'>
                                <Input
                                    error={customErrors.revisedShipToState}
                                    value={inputs.revisedShipToState}
                                    disabled={viewMode}
                                    onInternalError={onInternalInputErrors}
                                    inputId={'revisedShipToState'}
                                    maxLength={30}
                                    label={"Revised Ship To State"}
                                    onChange={(text: string) => onInputChange(text.toUpperCase(), 'revisedShipToState', 'string')}
                                />
                            </FormColumn>
                        </FormRow>

                        <FormRow>
                            <FormColumn paddingRight='10px'>
                                <Input
                                    error={customErrors.revisedShipToZipCode}
                                    value={inputs.revisedShipToZipCode}
                                    disabled={viewMode}
                                    maxLength={10}
                                    inputId={'revisedShipToZipCode'}
                                    onInternalError={onInternalInputErrors}
                                    label={"Revised Ship To Zipcode"}
                                    onChange={(text: string) => onInputChange(text.toUpperCase(), 'revisedShipToZipCode', 'string')}
                                />
                            </FormColumn>

                            <FormColumn paddingLeft='10px'>
                                <Dropdown
                                    error={customErrors.revisedShipToCountry}
                                    keyToShow={'code'}
                                    value={revisedShipToCountryCode}
                                    label={"Revised Ship To Country"}
                                    options={countryCodes}
                                    required={true}
                                    disabled={viewMode}
                                    allowClear={true}
                                    onChange={code => handleDropdownChange('revisedShipToCountry', code, () => setRevisedShipToCountryCode(code), true)} />
                                {/* <Input
                                    error={customErrors.revisedShipToCountry}
                                    value={inputs.revisedShipToCountry}
                                    disabled={viewMode}
                                    maxLength={50}
                                    inputId={'revisedShipToCountry'}
                                    onInternalError={onInternalInputErrors}
                                    label={"Revised Ship To Country"}
                                    onChange={(text: string) => onInputChange(text.toUpperCase(), 'revisedShipToCountry', 'string')}
                                /> */}
                            </FormColumn>

                                {/* <Input
                                    error={customErrors.siteId}
                                    value={inputs.siteId}
                                    label={"Site ID"}
                                    disabled={viewMode}
                                    required={true}
                                    onChange={(text: string) => onInputChange(text, 'siteId', 'string')}
                                /> */}
                        </FormRow>

                        <FormRow>
                            <FormColumn paddingRight='10px'>
                                <Dropdown
                                    error={customErrors.siteId}
                                    keyToShow={"siteId"}
                                    value={siteId}
                                    label={"Site ID"}
                                    options={siteIds}
                                    required={true}
                                    disabled={viewMode}
                                    onChange={siteId => setSiteId(siteId)} />
                            </FormColumn>
                        </FormRow>

                        <ButtonWrapper>
                            <Button disabled={isSaveButtonDisabled()} label={'Update the order'} onClick={handleSubmit} />

                            {/* <Button label={'Validate'} onClick={() => onValidateClick()} /> */}
                        </ButtonWrapper>
                    </FormWrapper>
                </Modal>
            );
        }
    };

    useEffect(() => {
        // it has ValidationError status
        if (currentOrderList && currentOrderList.orderStatus === 2) {
            let errorObj: any = {};

            if (errors && errors.length) {
                errors.forEach(err => {
                    Object.keys(customErrors).forEach(key => {
                        if (key.toLowerCase() === err.fieldName.toLowerCase()) {
                            errorObj[key] = err.errorMessage;
                        }
                    });
                });
            }

            setCustomErrors({ ...customErrors, ...errorObj });
        }
    }, [errors]);

    const onInternalInputErrors = (flag: boolean, key?: string) => {
        if (key) {
            if (flag) {
                setInternalErrors({ ...internalErrors, [key]: flag });
            } else {
                let copy: any = { ...internalErrors };
                delete copy[key];

                setInternalErrors(copy);
            }
        }
    };

    const isSaveButtonDisabled = () => {
        if (viewMode) {
            return true;
        }

        if (!isEmpty(internalErrors)) {
            return true;
        }

        if (
            !inputs.poNumber || !siteId ||
            isEmpty(currency) || isEmpty(revisedFob) ||
            !inputs.revisedPoDate || !inputs.orderStatusDate ||
            !inputs.orderDate || !inputs.revisedCustomerRequestedDate
        ) {
            return true;
        }

        return false;
    };

    const handleValidation = (text: string | Date | number, key: string, type: string, required?: boolean) => {
        switch (type) {
            case 'date': {
                if (!text && required) {
                    setCustomErrors({ ...customErrors, [key]: 'Required' })
                } else {
                    setCustomErrors({ ...customErrors, [key]: '' })
                }
                break;
            }

            case 'string': {
                if (isEmpty(text) && required) {
                    setCustomErrors({ ...customErrors, [key]: 'Required' })
                } else {
                    setCustomErrors({ ...customErrors, [key]: '' })
                }
                break;
            }

            default: {
                setCustomErrors({ ...customErrors, [key]: '' })
            }
        }

    }

    const handleDropdownChange = (key: string, valueObj: any, callback: any, required?: boolean) => {
        callback && callback();

        if (isEmpty(valueObj) && required) {
            setCustomErrors({ ...customErrors, [key]: 'Required' });
        } else {
            setCustomErrors({ ...customErrors, [key]: '' });
        }
    };

    const onInputChange = (text: string | Date | number, key: string, type: string, required?: boolean) => {
        let newInputs = { ...inputs, [key]: text };

        setInputs(newInputs);

        handleValidation(text, key, type, required);
    };
// @TODO remove update from order list
    const handleSubmit = () => {
        const editValues = {
            ...inputs,
            poDate: inputs.poDate ? moment(inputs.poDate).format('MM/DD/YYYY') : null,
            orderDate: moment(inputs.orderDate).format('MM/DD/YYYY'),
            customerRequestedDate: inputs.poDate ? moment(inputs.customerRequestedDate).format('MM/DD/YYYY') : null,
            orderStatus: get(inputs.orderStatus, 'id'),
            orderStatusDate: moment(inputs.orderStatusDate).format('MM/DD/YYYY'),
            // shipDate: moment(inputs.shipDate).format('MM/DD/YYYY'),
            id: currentOrderList && currentOrderList.id,
            revisedPoDate: inputs.revisedPoDate ? moment(inputs.revisedPoDate).format('MM/DD/YYYY') : null,
            confirmedDate: moment(inputs.confirmedDate).format('MM/DD/YYYY'),
            // revisedShipViaText: (!isEmpty(revisedShipViaTextObj) && revisedShipViaTextObj.code) || '',
            revisedShipViaText,
            currency: currency.name,
            // earlyShippingDate: moment(inputs.earlyShippingDate).format('MM/DD/YYYY'),
            updatedDate: currentOrderList && currentOrderList.updatedDate,
            updatedBy: currentOrderList && currentOrderList.updatedBy,
            getAllOrderList: getAllOrderList,
            siteId: !isEmpty(siteId) ? siteId.siteId : '',
            revisedShipToCountry: !isEmpty(revisedShipToCountryCode) ? revisedShipToCountryCode!.code : '',
            revisedFob: !isEmpty(revisedFob) ? revisedFob!.name : '',
            incoterm: !isEmpty(incoterm) ? incoterm!.code : '',
        };

        if (isEditMode && currentOrderList && onSubmitEdit) {
            onSubmitEdit(editValues);
        } else {
            onSubmit(editValues);
        }
    }

    return render();
};
