import { catchError, concatMap, map } from "rxjs/operators";

import { Action } from "../interfaces/shared";
import { ActionsObservable } from "redux-observable";
import { SharedActions } from './shared.actions';
import { SharedWebApi } from '../webapi/shared.webapi';
import { of } from "rxjs";

export const getPoTypes$ = (actions$: ActionsObservable<Action<null>>) =>
    actions$.ofType(SharedActions.GET_PO_TYPES_REQ).pipe(
        map(action => action.payload),
        concatMap(() => SharedWebApi.getPoTypes().pipe(
            map(poTypes => SharedActions.getPoTypesOk(poTypes)),
            catchError(err => of(SharedActions.getPoTypesFail(err))),
        )),
    );

export const getShipVia$ = (actions$: ActionsObservable<Action<null>>) =>
    actions$.ofType(SharedActions.GET_SHIPVIA_REQ).pipe(
        map(action => action.payload),
        concatMap(() => SharedWebApi.getShipVia().pipe(
            map(shipVias => SharedActions.getShipViaOk(shipVias)),
            catchError(err => of(SharedActions.getShipViaFail(err.response && err.response.data))),
        )),
    );

export const getFobs = (action$: ActionsObservable<Action<null>>) =>
    action$.ofType(SharedActions.GET_FOBS_REQ).pipe(
        map(action => action.payload),
        concatMap(() => SharedWebApi.getFobs().pipe(
            map(fobs => SharedActions.getFobsOk(fobs)),
            catchError(err => of(SharedActions.getFobsFail(err.response && err.response.data))),
        )),
    );

export const getSiteIds$ = (action$: ActionsObservable<Action<null>>) =>
    action$.ofType(SharedActions.GET_SITE_IDS_REQ).pipe(
        map(action => action.payload),
        concatMap(() => SharedWebApi.getSiteIds().pipe(
            map(siteIds => SharedActions.getSiteIdsOk(siteIds)),
            catchError(err => of(SharedActions.getSiteIdsFail(err.response && err.response.data))),
        )),
    );

export const getAllPartIds$ = (action$: ActionsObservable<Action<null>>) =>
    action$.ofType(SharedActions.GET_ALL_PART_IDS_REQ).pipe(
        map(action => action.payload),
        concatMap(() => SharedWebApi.getAllPartIds().pipe(
            map(partIds => SharedActions.getAllPartIdsOk(partIds)),
            catchError(err => of(SharedActions.getAllPartIdsFail(err.response && err.response.data))),
        )),
    );

export const getCountryCodes$ = (action$: ActionsObservable<Action<null>>) =>
    action$.ofType(SharedActions.GET_COUNTRY_CODES_REQ).pipe(
        map(action => action.payload),
        concatMap(() => SharedWebApi.getCountryCodes().pipe(
            map(codes => SharedActions.getCountryCodesOk(codes)),
            catchError(err => of(SharedActions.getCountryCodesFail(err.response && err.response.data))),
        )),
    );
