import * as React from "react";

import { AdminPage } from "./admin/pages/admin.page";
import { AoePage } from "./aoe/pages/aoe.page";
import { FoePage } from "./foe/pages/foe.page";
import { IAzureADFunctionProps } from "react-aad-msal";
import { OrdersRouter } from "./orders/routes/order-router";
import { Route } from "react-router";
import { Switch } from "react-router-dom";
import { authRoutes } from "./auth/routes/auth.routes";
import { homepageRoutes } from "./homepage/routes/homepage.routes";
import { notificationsRoutes } from "./notifications/routes/notifications.routes";
import { ordersConfirmationRoutes } from "./orders-confirmation/routes/orders-confirmation.routes";
import { usersRoutes } from "./users/routes/users.routes";

interface Props { }

export const AppRoutes: React.FunctionComponent<Props> = (_props: Props) => {
    return (
        <Switch>
            {renderRoutes({} as any)}
        </Switch>
    );
};

function renderRoutes(azureAd: IAzureADFunctionProps) {
    return (
        <>
            {authRoutes(azureAd)}

            {homepageRoutes(azureAd)}

            {ordersConfirmationRoutes()}

            {notificationsRoutes(azureAd)}

            {usersRoutes()}

            <Route
                path="/orders"
                key="orders"
                render={props => <OrdersRouter {...props} {...azureAd} />}
            />

            <Route
                path="/automated-order-entry"
                key="automated-order-entry"
                render={props => <AoePage {...props} {...azureAd} />}
            />

            <Route path='/admin'
                key={'admin'}
                render={props => <AdminPage {...props} {...azureAd} />} />

            <Route path='/fast-order-entry'
                key={'foe'}
                render={props => <FoePage {...props} {...azureAd} />} />
        </>
    );
}
