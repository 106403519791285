import * as React from 'react';
import * as styled from './dropdown.style';

import { Select } from 'antd';
import { isArray } from 'lodash';

interface Props {
    options: any[];

    value?: any;

    onChange: (option: any) => void;

    label?: string;

    placeholder?: string;

    error?: string;

    disabled?: boolean;

    required?: boolean;

    allowClear?: boolean;

    showAny?: boolean;
}

export const MultipleDropdown: React.FC<Props> = (props: Props) => {
    const { label, options, error, onChange, value, disabled, required, allowClear, showAny } = props;

    const [innerOptions, setInnerOptions] = React.useState(options);

    const render = () => {
        return (
            <styled.Wrapper hasError={!!error}>

                {/** Label */}
                {
                    label &&
                    <styled.Label>
                        {label}
                        {
                            required && <span>*</span>
                        }
                    </styled.Label>
                }

                <Select showSearch={true}
                    onChange={value => onSelectChange(value)}
                    getPopupContainer={(trigger: any) => trigger.parentNode}
                    defaultValue={value}
                    allowClear={allowClear}
                    className={'multiple-select'}
                    mode={'multiple'}
                    value={value}
                    disabled={disabled ? disabled : false}>
                    {
                        innerOptions.map(option =>
                            <Select.Option value={option}
                                key={option}>
                                {option}
                            </Select.Option>
                        )
                    }
                </Select>

                {/** Error */}
                {
                    error &&
                    <styled.Error>
                        {error}
                    </styled.Error>
                }

            </styled.Wrapper>
        );
    };

    React.useEffect(() => {
        if (showAny && options.length) {
            let newOptions = ['Any', ...options];

            setInnerOptions(newOptions);
        }
    }, [showAny, options]);

    React.useEffect(() => {
        if (showAny && isArray(value) && !value.length) {
            onSelectChange(['Any']);
        }
    }, [showAny, value]);

    React.useEffect(() => {
        if (isArray(value) && value.length > 1 && value[0] === 'Any') {
            let copyValue = [...value];
            copyValue.splice(0, 1);

            onSelectChange(copyValue);
        }

        if (isArray(value) && value.length === options.length && value[0] !== 'Any') {
            onSelectChange(['Any']);
        }
    }, [value]);

    const onSelectChange = (value: any) => {
        if (value) {
            onChange(value);
        } else {
            onChange('');
        }
    }

    return render();
};
