import React, { useEffect, useState } from 'react';

import { Input } from '../input/input';
import { Tag } from 'antd';

interface Props {
    values: string[];
    onChange?: (value: string) => void;
    label?: string;
    onTagClose: (tag: string) => void;
    required?: boolean;
}

export const MultipleInputValues: React.FC<Props> = (props: Props) => {
    const { values, onChange, label, onTagClose, required } = props;

    const [newValue, setNewValue] = useState('');

    const onKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            onChange && onChange(e.target.value);
            setNewValue('');
        }
    };

    const onInnetTagClose = (e: any, value: string) => {
        e.preventDefault();
        onTagClose(value);
    };

    const render = () => {
        return (
            <div className='multiple-input-values'>
                <Input type={'text'}
                    onKeyDown={e => onKeyDown(e)}
                    value={newValue}
                    label={label + (required ? '*' : '')}
                    onChange={value => setNewValue(value)} />

                <div className='content'>
                    {
                        values && values.map((v: string, index: number) =>
                            <Tag closable={true}
                                key={index}
                                onClose={e => onInnetTagClose(e, v)}>
                                    {v}
                            </Tag>
                        )
                    }
                </div>
            </div>
        );
    };

    return render();
};
