import { IFilterSoa, ISoa } from '../interfaces/soa';

import { Action } from '../../shared/interfaces/shared';
import { ISoaFormValues } from '../interfaces/soa-form-values'

export const RESET_SOAS = 'RESET_SOAS';
export const resetSoas = () => ({
    type: RESET_SOAS,
    payload: null
});

/** GET SOAS */

export const TOGGLE_CREATE_SOA_MODAL = 'TOGGLE_CREATE_SOA_MODAL';
export const toggleCreateModal = (flag: boolean): Action<boolean> => ({
    type: TOGGLE_CREATE_SOA_MODAL,
    payload: flag
});

export const GET_SOAS_REQ = 'GET_SOAS_REQ';
export const getSoasReq = (): Action<null> => ({
    type: GET_SOAS_REQ,
    payload: null
});

export const GET_SOAS_OK = 'GET_SOAS_OK';
export const getSoasOk = (soas: ISoa[]) => ({
    type: GET_SOAS_OK,
    payload: soas
});

export const GET_SOAS_FAIL = 'GET_SOAS_FAIL';
export const getSoasFail = (error: string) => ({
    type: GET_SOAS_FAIL,
    payload: error
});

export const TOGGLE_GET_SOAS_LOADING = 'TOGGLE_GET_SOAS_LOADING';
export const toggleGetSoasLoading = (flag: boolean) => ({
    type: TOGGLE_GET_SOAS_LOADING,
    payload: flag
});

/** DELETE SOA */

export const TOGGLE_DELETE_MODAL = 'TOGGLE_DELETE_MODAL';
export const toggleDeleteModal = (flag: boolean): Action<boolean> => ({
    type: TOGGLE_DELETE_MODAL,
    payload: flag
});

export const SET_DELETED_SOA = 'SET_DELETED_SOA';
export const setDeletedSoa = (soa: ISoa): Action<ISoa> => ({
    type: SET_DELETED_SOA,
    payload: soa
});

export const DELETE_SOA_BY_ID_REQ = 'DELETE_SOA_BY_ID_REQ';
export const deleteSoaByIdReq = (soaId: number): Action<number> => ({
    type: DELETE_SOA_BY_ID_REQ,
    payload: soaId
});

export const DELETE_SOA_BY_ID_OK = 'DELETE_SOA_BY_ID_OK';
export const deleteSoaByIdOK = (response: string): Action<string> => ({
    type: DELETE_SOA_BY_ID_OK,
    payload: response
});

export const DELETE_SOA_BY_ID_FAIL = 'DELETE_SOA_BY_ID_FAIL';
export const deleteSoaByIdFail = (error: string): Action<string> => ({
    type: DELETE_SOA_BY_ID_FAIL,
    payload: error
});

export const TOGGLE_DELETE_SOA_LOADING = 'TOGGLE_DELETE_SOA_LOADING';
export const toggleDeleteSoaLoading = (flag: boolean): Action<boolean> => ({
    type: TOGGLE_DELETE_SOA_LOADING,
    payload: flag
});


/** CREATE SOA*/

export const CREATE_SOA_REQ = 'CREATE_SOA_REQ';
export const createSoaReq = (soa: ISoaFormValues): Action<ISoaFormValues> => ({
    type: CREATE_SOA_REQ,
    payload: soa
});

export const CREATE_SOA_OK = 'CREATE_SOA_OK';
export const createSoaOk = (response: string): Action<string> => ({
    type: CREATE_SOA_OK,
    payload: response
});

export const CREATE_SOA_FAIL = 'CREATE_SOA_FAIL';
export const createSoaFail = (error: string): Action<string> => ({
    type: CREATE_SOA_FAIL,
    payload: error
});

export const TOGGLE_CREATE_SOA_LOADING = 'TOGGLE_CREATE_SOA_LOADING';
export const toggleCreateSoaLoading = (flag: boolean): Action<boolean> => ({
    type: TOGGLE_CREATE_SOA_LOADING,
    payload: flag
});

/**EDIT SOA */

export const SET_CURRENT_SOA = 'SET_CURRENT_SOA';
export const setCurrentSoa = (soa: ISoa): Action<ISoa> => ({
    type: SET_CURRENT_SOA,
    payload: soa
});

export const RESET_CURRENT_SOA = 'RESET_CURRENT_SOA';
export const resetCurrentSoa = (): Action<null> => ({
    type: RESET_CURRENT_SOA
});

export const EDIT_SOA_REQ = 'EDIT_SOA_REQ';
export const editSoaReq = (soa: ISoa): Action<ISoa> => ({
    type: EDIT_SOA_REQ,
    payload: soa
});

export const EDIT_SOA_OK = 'EDIT_SOA_OK';
export const editSoaOk = (response: string): Action<string> => ({
    type: EDIT_SOA_OK,
    payload: response
});

export const EDIT_SOA_FAIL = 'EDIT_SOA_FAIL';
export const editSoaFail = (error: string): Action<string> => ({
    type: EDIT_SOA_FAIL,
    payload: error
});

export const TOGGLE_EDIT_SOA_LOADING = 'TOGGLE_EDIT_SOA_LOADING';
export const toggleEditSoaLoading = (flag: boolean): Action<boolean> => ({
    type: TOGGLE_EDIT_SOA_LOADING,
    payload: flag
});

export const GET_FILTER_SOAS_REQ = 'GET_FILTER_SOAS_REQ';
export const getFilterSoas = (): Action<null> => ({
    type: GET_FILTER_SOAS_REQ,
    payload: null,
});

export const GET_FILTER_SOAS_OK = 'GET_FILTER_SOAS_OK';
export const getFilterSoasOk = (soas: IFilterSoa[]): Action<IFilterSoa[]> => ({
    type: GET_FILTER_SOAS_OK,
    payload: soas,
});

export const GET_FILTER_SOAS_FAIL = 'GET_FILTER_SOAS_FAIL';
export const getFilterSoasFail = (error: string): Action<string> => ({
    type: GET_FILTER_SOAS_FAIL,
    payload: error,
});

export const GET_SOA_BY_ID_REQ = 'GET_SOA_BY_ID_REQ';
export const getSoaById = (id: number) => ({
    type: GET_SOA_BY_ID_REQ,
    payload: id,
});

export const GET_SOA_BY_ID_OK = 'GET_SOA_BY_ID_OK';
export const getSoaByIdOk = (soa: ISoa) => ({
    type: GET_SOA_BY_ID_OK,
    payload: soa,
});

export const GET_SOA_BY_ID_FAIL = 'GET_SOA_BY_ID_FAIL';
export const getSoaByIdFail = (error: string) => ({
    type: GET_SOA_BY_ID_FAIL,
    payload: error,
});
