import React, { useEffect, useState } from 'react';

import { IOrderList } from '../../interfaces/order-list';
import { OrderListExcelDocument } from '../order-list-excel-document/order-list-excel-document';
import { OrderListHTMLDocument } from '../order-list-html-document/order-list-html-document';
import { Theme } from '../../../shared/styles/theme';

interface Props {
    blob: any;
    orderList: IOrderList;
}

/**
 * Wrapper that decides what type of document to show
 * Can be one of the following
 * HTML, PDF (coming soon), EXCEL
 */
export const OrderListDocument: React.FC<Props> = (props: Props) => {
    const { blob, orderList } = props;

    const [PDFUrl, setPDFUrl] = useState('');

    const render = () => {
        // Excel
        if (orderList.typeId === 3) {
            return <OrderListExcelDocument blob={blob} orderList={orderList} />;
        }

        // HTML
        if (orderList.typeId === 1) {
            return <OrderListHTMLDocument blob={blob} orderList={orderList} />;
        }

        // PDF
        return (
            <Theme.Center>
                <iframe src={PDFUrl} width='1460' height='700' />
            </Theme.Center>
        );
    };

    useEffect(() => {
        if (orderList.typeId !== 1 && orderList.typeId !== 3) {
            const newBlob = new Blob([blob.blob], {
                type: 'application/pdf',
            });
            const exportUrl = URL.createObjectURL(newBlob);

            setPDFUrl(exportUrl);
        }
    }, [blob]);

    return render();
};
