import * as styles from './order-line-footer.style';

import { Button } from 'antd';
import { ButtonTypes } from '../../../shared/components/button/button.utils';
import { Button as GeckoButton } from '../../../shared/components/button/button';
import { IOrderList } from '../../../order-list/interfaces/order-list';
import { OrderListStateService } from '../../../order-list/services/order-list-state.service';
import React from 'react';
import { Theme } from '../../../shared/styles/theme';
import { _toggleArchiveOrderModal } from '../../services/order-line.state-service';

interface Props {
    orderList: IOrderList;
    confirmButtonDisabled?: boolean;
}

export const OrderLineFooter: React.FC<Props> = (props: Props) => {
    const { orderList, confirmButtonDisabled } = props;

    const render = () => {
        return (
            <styles.BottomContainer>
                <div>
                    {
                        displayArchiveButton() &&
                        <Button danger={true} style={{ marginRight: 10 }}
                            onClick={() => _toggleArchiveOrderModal(true)}>
                            Archive this PO
                        </Button>
                    }

                    {
                        displayConfirmCustomerOrderButton() &&
                        <>
                            <GeckoButton type={ButtonTypes.PRIMARY}
                                // disabled={!orderLines.length}
                                disabled={confirmButtonDisabled}
                                label={'Validate Order'}
                                onClick={() => onValidateOrderClick()} />

                            <GeckoButton type={ButtonTypes.PRIMARY}
                                overrides={{ root: 'margin-left: 10px' }}
                                disabled={confirmButtonDisabled}
                                label={'Confirm Order'}
                                onClick={() => onConfirmOrderClick()} />
                        </>
                    }
                </div>

                {
                    (confirmButtonDisabled && orderList.orderStatus !== 6) &&
                    <Theme.ErrorMessage>
                        This order has no lines. You cannot confirm it.
                    </Theme.ErrorMessage>
                }
                </styles.BottomContainer>
        );
    };

    const onValidateOrderClick = () => {
        const { id } = orderList;

        OrderListStateService.confirmCustomerOrder(id);
    };

    /**
     * Display button only if the PO is in:
     * 1. Waiting Confirmation (id === 3)
     * 2. Validation Error (id === 2)
     * 3. Creation Error (id === 5)
     */
     const displayConfirmCustomerOrderButton = (): boolean => {
        const { orderStatus } = orderList;

        if (orderStatus === 3 || orderStatus === 2 || orderStatus === 5) {
            return true;
        }

        return false;
    };

    /**
     * Display Archive button only if
     * PO doesn't have "Created" status or "Archived" status
     */
    const displayArchiveButton = (): boolean => {
        if (orderList.orderStatus !== 4 && orderList.orderStatus !== 7 && orderList.orderStatus !== 6) {
            return true;
        }

        return false;
    };

    const onConfirmOrderClick = () => {
        const { id } = orderList;

        OrderListStateService.sendToErp(id);
    };

    return render();
};
