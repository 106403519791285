import styled from 'styled-components';

export const IconWrapper = styled.svg.attrs({
    version: '1.1',
    xmlns: 'http://www.w3.org/2000/svg',
    xmlnsXlink: 'http://www.w3.org/1999/xlink',
})``;

export const Svg = styled(IconWrapper)`
    display: flex;
    align-items: center;
    justify-content: center;
`;
