import * as styles from './orders.page.style';

import { ICompleteOrder, IErpOrderLine, IOrder } from '../interfaces/order';
import React, { useEffect, useState } from 'react';
import { faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useLocation } from 'react-router';

import { AppState } from '../../shared/interfaces/app.state';
import { AuthService } from '../../auth/services/auth.service';
import { BackendError } from '../../shared/components/backend-error/backend-error';
import { DateService } from '../../shared/services/date.service';
import { EditErpOrderLineModal } from '../components/edit-erp-order-line-modal/edit-erp-order-line-modal';
import { EditOrderModal } from '../components/edit-order-modal/edit-order-modal';
import { ExpandableConfig } from 'antd/lib/table/interface';
import { FILTERS } from '../../filters/interfaces/filters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GeckoTable } from '../../shared/components/gecko-table/gecko-table';
import { GeckoTableService } from '../../shared/components/gecko-table/gecko-table-service';
import { IApiTableRequest } from '../../shared/interfaces/shared';
import { IPagination } from '../../shared/interfaces/pagination';
import { IRowMenu } from '../../shared/interfaces/row-menu';
import { OrderDetails } from '../components/order-details/order-details';
import { OrdersStateService } from '../services/orders-state.service';
import { PageTitle } from '../../shared/components/page-title/page-title';
import { PageWrapper } from '../../shared/styles/page-wrapper';
import { ROLES } from '../../users/interfaces/roles';
import { SortingOrder } from '../../shared/interfaces/sorting';
import { UsersService } from '../../users/services/users.service';
import qs from 'qs';
import { useSelector } from 'react-redux';

interface Props {}

/**
 * Orders that are retrieved from the ERP
 * which are different from the orders that are in AOE module
 */
export const OrdersPage: React.FC<Props> = (_props: Props) => {
    // state
    const [rowHovered] = useState({});

    // store
    const pagination = useSelector<AppState, IPagination<IOrder>>(state => state.orders.pagination);
    const isTableLoading = useSelector<AppState, boolean>(state => state.orders.loading);
    const completeOrder = useSelector<AppState, ICompleteOrder>(state => state.orders.currentCompleteOrder);
    const editOrderModal = useSelector<AppState, boolean>(state => state.orders.modals.editOrder);
    const currentOrder = useSelector<AppState, IOrder>(state => state.orders.order);
    const editErpOrderLineModal = useSelector<AppState, boolean>(state => state.orders.modals.editErpOrderLine);
    const currentErpOrderLine = useSelector<AppState, IErpOrderLine>(state => state.orders.currentErpOrderLine);

    const history = useHistory();
    const location = useLocation();
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

    const initOrdersTableConfig: IApiTableRequest = {
        pageSize: 20,
        pageNumber: 1,
        sortColumn: 'OrderDate',
        sortOrder: SortingOrder.Desc,
        filters: [],
    };
    const filters = [FILTERS.ORDER_ID, FILTERS.ERP_PONUMBER, FILTERS.ERP_COMPANY, FILTERS.ERP_CUSTOMER_ID, FILTERS.ERP_STATUS, FILTERS.ORDER_DATE, FILTERS.SHIPPED_DATE, FILTERS.CONFIRMED_DATE, FILTERS.CURRENCY, FILTERS.ERP_TOTAL_COST];
    const sortColumn = GeckoTableService.getSortColumnFromQueryParams(queryParams);

    const render = () => {
        return (
            <PageWrapper>
                <PageTitle text={'All Orders'} />

                <GeckoTable dataSource={pagination}
                    isLoading={isTableLoading}
                    showPagination={true}
                    actions={{ filter: true, refresh: true }}
                    showRowMenu={true}
                    rowMenu={row => menuItems(row)}
                    expandable={expandable()}
                    filters={filters}
                    columns={columns(rowHovered)}
                    apiCall={apiCall}
                    defaultSortColumn={'OrderDate'}
                    initConfig={initOrdersTableConfig}
                    tableId={'ordersTable'} />

                {
                    editOrderModal && <EditOrderModal order={currentOrder}
                        handleSubmit={OrdersStateService.editOrder} />
                }

                {
                    editErpOrderLineModal && <EditErpOrderLineModal line={currentErpOrderLine}
                        customerId={completeOrder.customerId}
                        orderId={currentOrder.orderId}
                        handleSubmit={OrdersStateService.editErpOrderLine} />
                }

                <BackendError />
            </PageWrapper>
        );
    };

    useEffect(() => {
        AuthService.checkAuth(history);

        return () => {
            OrdersStateService.resetCurrentCompleteOrder();
        };
    }, []);

    const apiCall = (params: IApiTableRequest) => {
        OrdersStateService.getOrders(params);
    };

    const expandable = (): ExpandableConfig<IOrder> => {
        return {
            expandedRowRender: () => <OrderDetails order={completeOrder} />,
            onExpand: onRowExpand,
            expandedRowKeys: [completeOrder.id],
            expandedRowClassName: () => 'table-expanded-row',
            expandIcon: ({ expanded, onExpand, record }) => expanded ? (
                <FontAwesomeIcon icon={faMinusSquare} onClick={(e: any) => onExpand(record, e)} style={{ fontSize: 18, cursor: 'pointer' }} />
            ) : (
                <FontAwesomeIcon icon={faPlusSquare} onClick={(e: any) => onExpand(record, e)} style={{ fontSize: 18, cursor: 'pointer' }} />
            ),
        };
    };

    // utils

    const onRowExpand = (expanded: boolean, order: IOrder) => {
        if (expanded) {
            OrdersStateService.getOrderDetails(order.id);
        } else {
            OrdersStateService.resetCurrentCompleteOrder();
        }
    };

    const columns = (_currentRow: any) => {
        return [{
            title: 'Order Number',
            dataIndex: 'orderId',
            key: 'orderId',
            defaultSortOrder: GeckoTableService.getOrderFromInitColumn('orderId', sortColumn),
            sorter: true,
            width: 140,
        }, {
            title: 'PO Number',
            dataIndex: 'poNumber',
            key: 'poNumber',
            sorter: true,
            width: 140,
        }, {
            title: 'Company Name',
            dataIndex: 'customerName',
            key: 'customerName',
            sorter: true,
            width: 150,
        }, {
            title: 'Customer ID',
            dataIndex: 'customerId',
            key: 'customerId',
            sorter: true,
            width: 130,
        }, {
            title: 'Status Code',
            dataIndex: 'statusCode',
            key: 'statusCode',
            sorter: true,
            width: 130,
        }, {
            title: 'Order Date',
            dataIndex: 'orderDate',
            key: 'orderDate',
            render: (_text: string, order: IOrder) =>
                DateService.formatDate(order.orderDate),
            sorter: true,
            width: 110,
        }, {
            title: 'Last Shipped Date',
            dataIndex: 'lastShippedDate',
            key: 'lastShippedDate',
            render: (_text: string, order: IOrder) =>
                DateService.formatDate(order.lastShippedDate),
            sorter: true,
            width: 160,
        }, {
            title: 'Confirmed Date',
            dataIndex: 'confirmedDate',
            key: 'confirmedDate',
            render: (_text: string, order: IOrder) =>
                DateService.formatDate(order.confirmedDate),
            sorter: true,
            width: 130,
        }, {
            title: 'Currency',
            dataIndex: 'currency',
            key: 'currency',
            sorter: true,
        }, {
            title: 'Total Cost',
            dataIndex: 'totalCost',
            key: 'totalCost',
            render: (_text: string, order: IOrder) =>
                <styles.TotalPrice>{order.totalCost ? order.totalCost.toFixed(2) : 0}</styles.TotalPrice>,
            sorter: true,
            width: 100,
        }];
    }

    const menuItems = (order: IOrder): IRowMenu[] => {
        return [{
            label: 'View Order Details',
            onClick: () => history.push(`/orders/order-details/${order.id}`),
        }, {
            label: 'Update Order',
            onClick: () => onEditOrderClick(order),
            disabled: true,
            hide: !UsersService.authorizeDisplay([ROLES.Admin, ROLES.SOA]),
        }];
    };

    const onEditOrderClick = (order: IOrder) => {
        OrdersStateService.toggleEditOrderModal(true);
        OrdersStateService.setCurrentOrder(order);
    };

    return render();
};
