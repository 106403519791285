import * as React from 'react';

import {
    _createDayOff,
    _deleteDayOff,
    _getDayOffs,
    _resetCurrentDayOff,
    _resetDayOffs,
    _toggleCreatetDayOffModal,
    _toggleDeleteDayOffModal,
} from '../services/day-off-state.service';

import { ActivityIndicator } from '../../shared/components/activity-indicator/activity-indicator';
import { AppState } from '../../shared/interfaces/app.state';
import { AuthService } from '../../auth/services/auth.service';
import { BackendError } from '../../shared/components/backend-error/backend-error';
import { ConfirmationModal } from '../../shared/components/confirmation-modal/confirmation-modal';
import { CreateDayOffModal } from '../components/create-day-off-modal/create-day-off-modal';
import { GeckoTable } from '../../shared/components/gecko-table/gecko-table';
import { IAzureADFunctionProps } from 'react-aad-msal';
import { IDayOff } from '../interfaces/day-off';
import { PageTitle } from '../../shared/components/page-title/page-title';
import { PageWrapper } from '../../shared/styles/page-wrapper';
import { ROLES } from '../../users/interfaces/roles';
import { DayOffPageUtils } from './day-off.page.utils';
import { UsersService } from '../../users/services/users.service';
import { _resetAoeBreadcrumbs } from '../../aoe/services/aoe-state.service';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { ISiteId } from '../../shared/interfaces/site-id';
import { SharedStateService } from '../../shared/services/shared.state-service';

interface Props extends IAzureADFunctionProps { }

const _DayOffPage: React.FC<Props> = (_props: Props) => {
    const deletedDayOff = useSelector<AppState, IDayOff>(state => state.dayOff.deleteDayOff.deletedDayOff);
    const deleteDayOffModal = useSelector<AppState>(state => state.dayOff.deleteDayOffModal);
    const createDayOffModal = useSelector<AppState>(state => state.dayOff.createDayOffModal);
    const currentDayOff = useSelector<AppState, IDayOff>(state => state.dayOff.editDayOff.currentDayOff);
    const dayOffs = useSelector<AppState, IDayOff[]>(state => state.dayOff.dayOffs);
    const getDayOffsLoading = useSelector<AppState, boolean>(state => state.dayOff.getDayOffsLoading);
    const createDayOffLoading = useSelector<AppState, boolean>(state => state.dayOff.createDayOff.loading);
    const deleteDayOffLoading = useSelector<AppState, boolean>(state => state.dayOff.deleteDayOff.loading);
    const siteIds = useSelector<AppState, ISiteId[]>(state => state.shared.siteIds);

    const history = useHistory();

    const displayAddButton = UsersService.authorizeDisplay([ROLES.Admin]);

    const onPageInit = React.useCallback(() => {
        AuthService.checkAuth(history);
        _resetDayOffs();
        _resetAoeBreadcrumbs();

        _getDayOffs();
    }, [history]);

    React.useEffect(() => {
        onPageInit();
        SharedStateService.getSiteIds();

        return () => {
        };
    }, []);

    const getSiteId = (siteId: number): string | undefined => {
        return siteIds.find(site => site.id === siteId)?.siteId;
    };

    const render = () => {
        if (getDayOffsLoading) {
            return <ActivityIndicator />;
        }

        return (
            <PageWrapper>
                <PageTitle text={'Days Off'} />

                <GeckoTable dataSource={{ data: dayOffs }}
                    columns={DayOffPageUtils.tableColumns}
                    showRowMenu={true}
                    rowMenu={dayOff => DayOffPageUtils.rowMenu(dayOff)}
                    tableId={'day-offs'}
                    onAddClick={() => _toggleCreatetDayOffModal(true)}
                    filters={[]}
                    actions={{ add: displayAddButton }} />

                {
                    deleteDayOffModal &&
                    <ConfirmationModal
                        onClose={() => _toggleDeleteDayOffModal(false)}
                        onConfirm={() => _deleteDayOff(deletedDayOff.id)}
                        loading={deleteDayOffLoading}
                    />
                }

                {
                    createDayOffModal &&
                    <CreateDayOffModal
                        currentDayOff={currentDayOff && currentDayOff}
                        isEditMode={false}
                        onSubmit={_createDayOff}
                        onSubmitEdit={_createDayOff}
                        onClose={() => _toggleCreatetDayOffModal(false)}
                        loading={createDayOffLoading}
                        siteIDs={siteIds}
                    />
                }

                <BackendError />
            </PageWrapper>
        );
    };

    return render();
};

export const DayOffPage = _DayOffPage;
