import * as msal from '@azure/msal-browser';

import { config } from '../../auth/components/auth-provider/config';

const { b2c } = config;
const signInAuthority = `${b2c.instance}${b2c.tenant}/${b2c.signInPolicy}`;

const msalConfigB2B = {
    auth: {
        clientId: config.appId,
        authority: config.authority,
        redirectUri: config.redirectUri,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
        secureCookies: false,
    },
};

const b2cConfig = {
    msalConfigB2C: {
        auth: {
            clientId: config.b2c.applicationID,
            authority: signInAuthority,
            knownAuthorities: [b2c.instance],
            redirectUri: config.redirectUri,
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: false,
            secureCookies: false,
        },
    },
    apiConfig: {
        b2cScopes: [],
        webApiUri: config.redirectUri,
    },
    loginRequest: {
        scopes: ['openid', 'offline_access'],
    },
    tokenRequest: {
        scopes: [],
    }
};

const provider = localStorage.getItem('provider');
const isB2C = provider === 'b2c';
const currentProvider = provider === 'b2b' ? msalConfigB2B : b2cConfig.msalConfigB2C;

export const MSAL = new msal.PublicClientApplication(currentProvider);

export const getToken = async () => {
    let account = MSAL.getAllAccounts()[0];

    if (account) {
        const silentRequest = {
            scopes: isB2C ? b2c.scopes : config.scopes,
            account,
            forceRefresh: false,
        };
        const request = {
            scopes: isB2C ? b2c.scopes : config.scopes,
            loginHint: account.username,
        };

        try {
            let tokenResponse = await MSAL.acquireTokenSilent(silentRequest);

            return tokenResponse;
        } catch (err) {
            if (err instanceof msal.InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                return MSAL.acquireTokenRedirect(request);
            }
        }
    }

    return null;
};

export const getAccountName = () => MSAL.getAllAccounts()[0];
