import './index.css';

import App from './App';
import { MSAL } from './shared/services/msal';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { store } from './shared/services/state.service';

/**
 * After the user logs in from microsoft interface,
 * this promise handler will check if the token was created
 * and redirect the user to homepage
 */
MSAL.handleRedirectPromise()
    .then(tokenResponse => {
        if (tokenResponse) {
            window.location.href = '/';
        }

        localStorage.setItem('appLoading', JSON.stringify(false));
    })
    .catch(err => {
        localStorage.setItem('appLoading', JSON.stringify(false));
    });

ReactDOM.render(
        <Provider store={store}>
            <React.StrictMode>
                <Router>
                    <App />
                </Router>
            </React.StrictMode>
        </Provider>
    ,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
