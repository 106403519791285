import { colors } from '../../shared/styles/colors';
import styled from 'styled-components';

interface ITableContainerWrapper {
    showDoc: boolean;
}

export const OrderLineContainer = styled.div`
    margin-bottom: 50px;
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 50px;
`;

export const PoLinesContainer = styled.div``;

export const BellContainer = styled.div`
    position: relative;
`;

export const ErrorNumber = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 5px;
    /* background-color: ${colors.$alert}; */
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -5px;
    right: -5px;
`;

export const ErrorNumberText = styled.div`
    color: ${colors.$alert};
    font-size: 12px;
    font-weight: 900;
`;

export const TableContainer = styled.div<ITableContainerWrapper>``;
