import { IDayOffState } from '../interfaces/day-off-state';
import { IDayOff } from '../interfaces/day-off';

export const dayOffInitState: IDayOffState = {
    dayOff: {} as IDayOff,
    deleteDayOffModal: false,
    createDayOffModal: false,
    getDayOffsLoading: false,
    dayOffs: [] as IDayOff[],
    createDayOff: {
        loading: false,
        response: '',
        error: '',
    },
    deleteDayOff: {
        deletedDayOff: {} as IDayOff,
        loading: false,
        response: '',
        error: ''
    },
    editDayOff: {
        currentDayOff: {} as IDayOff,
        loading: false,
        response: '',
        error: ''
    }
};
