import { FilterOptions, IApiTableFilter } from "../../interfaces/filters";
import { IApplyClick, IQueryStringValue, IUrlParamsObject } from "./gecko-table.interface";

import { FILTERS } from "../../../filters/interfaces/filters";
import { IApiTableRequest } from "../../interfaces/shared";
import { ISortColumn } from "../../../order-list/interfaces/order-list";
import { SortOrder } from "antd/lib/table/interface";
import { SortingOrder } from "../../interfaces/sorting";
import qs from 'qs';

export class GeckoTableService {
    public static getQueryStringFromObject(objArr: IQueryStringValue[]) {
        let newQs = '';

        objArr.forEach(obj => {
            newQs += `${obj.key}=${obj.value}&`;
        });

        return newQs;
    }

    public static clearQueryString(currentQueryString: string) {
        let qsParsed = qs.parse(currentQueryString, { ignoreQueryPrefix: true });
        let newQs = '';

        if (qsParsed.pageNumber) {
            newQs += `pageNumber=${qsParsed.pageNumber}&`;
        } else {
            newQs += `pageNumber=1&`;
        }

        if (qsParsed.pageSize) {
            newQs += `pageSize=${qsParsed.pageSize}&`;
        } else {
            newQs += `pageSize=20&`;
        }

        return newQs;
    }

    public static getSortingOrder(type: string) {
        if (type === 'ascend' || type === '1') {
            return SortingOrder.Asc;
        }

        if (type === 'descend' || type === '2') {
            return SortingOrder.Desc;
        }

        return SortingOrder.None;
    }

    public static getFiltersState(filters: FILTERS[]) {
        let obj: any = {};

        filters.forEach(filter => {
            obj[`${filter}`] = '';
        });

        return obj;
    }

    public static getUrlParamsFromTableRequestByTableId(data: IApiTableRequest, tableId: string): string {
        const { pageNumber, pageSize, filters, sortColumn, sortOrder } = data;
        let qs = '';

        qs += `${tableId}_pageNumber=${pageNumber}&${tableId}_pageSize=${pageSize}&`;

        qs += `${tableId}_sortColumn=${sortColumn}&${tableId}_sortOrder=${sortOrder}&`;

        filters && filters.forEach((filter, index) => {
            qs += `${tableId}_filterNames[${index}]=${filter.key}&${tableId}_values[${index}]=${filter.value}&${tableId}_options[${index}]=${filter.option}&`;
        });

        return qs;
    }

    public static getCurrentFiltersFromArray(filtersArr: IApiTableFilter[]) {
        let filters = {} as IApplyClick;

        filtersArr && filtersArr.forEach(filter => {
            if (filter.option !== FilterOptions.IsNotEqualTo) {
                filters[filter.key] = filter.value;
            }
        });

        return filters;
    }

    /**
     * @param qsParsed parsed URL from parent component
     * @param tableId id of the current table
     * returns an object that represents the values that are pushed inside URL search string
     */
    public static getTableRequestFromUrlParamsByTableId(qsParsed: Object, tableId: string): IApiTableRequest {
        let qsObj = qsParsed as any;
        let differentKeys: string[] = [];
        let perTableObj: { [key: string]: IUrlParamsObject } = {};

        Object.keys(qsParsed).forEach((key: string) => {
            if (differentKeys.indexOf(key) === -1) {
                // add key
                differentKeys = [...differentKeys, key];
            }

            let keySplit = key.split('_')[0];
            let innerKeySplit = key.split('_')[1];

            perTableObj = {
                ...perTableObj,
                [keySplit]: {
                    ...perTableObj[keySplit],
                    [innerKeySplit]: qsObj[key],
                },
            };
        });

        if (perTableObj[tableId]) {
            return this.transformObjectTableToFilters(perTableObj[tableId]);
        }

        return {} as IApiTableRequest;
    }

    public static transformObjectTableToFilters(obj: IUrlParamsObject): IApiTableRequest {
        const { pageNumber, pageSize, filterNames, values, options, sortColumn, sortOrder } = obj;
        let filters: IApiTableFilter[] = [];

        const tableReq: IApiTableRequest = {
            pageNumber: parseInt(pageNumber),
            pageSize: parseInt(pageSize),
            sortColumn,
            sortOrder: parseInt(sortOrder),
            filters: [],
        };

        filterNames && filterNames.forEach((k, index) => {
            const newFilter: IApiTableFilter = {
                key: k,
                value: values[index],
                option: (FilterOptions as any)[options[index]],
            };

            filters = [ ...filters, newFilter ];
        });

        tableReq.filters = filters;

        return tableReq;
    }

    public static getSortColumnFromQueryParams(queryParams: any) {
        if (queryParams && queryParams.sortColumn) {
            const order: SortOrder = queryParams.sortOrder && queryParams.sortOrder === '1' ? 'ascend' : 'descend';

            return {
                column: queryParams.sortColumn,
                order,
            };
        }

        return {
            column: '',
            order: null,
        };
    }

    public static getOrderFromInitColumn(columnName: string, initColumn: ISortColumn) {
        if (columnName === initColumn.column) {
            return initColumn.order;
        }

        return null;
    }

}
