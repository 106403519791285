import * as aoeActions from './aoe.actions';

import { Action } from "../../shared/interfaces/shared";
import { IAoeState } from "../interfaces/aoe-state";
import { aoeInitState } from "./aoe.init-state";

export const aoeReducer = (state: IAoeState = aoeInitState, action: Action<any>): IAoeState => {
    switch (action.type) {
        case aoeActions.ADD_BREADCRUMB: {
            const newBreadcrumbs = [...state.breadcrumbs, action.payload];

            return {
                ...state,
                breadcrumbs: newBreadcrumbs,
                breadcrumbHistory: newBreadcrumbs,
            };
        }

        case aoeActions.SET_BREADCRUMBS: {
            return {
                ...state,
                breadcrumbs: action.payload,
            };
        }

        case aoeActions.RESET_BREADCRUMBS: {
            return {
                ...state,
                breadcrumbs: [],
                breadcrumbHistory: [],
            };
        }

        case aoeActions.REFINE_BREADCRUMBS: {
            const clickedBreadcrumb = action.payload;
            let breadcrumbs = [...state.breadcrumbs];
            let breadcrumbClickedIndex = breadcrumbs.findIndex(bc => bc.path === clickedBreadcrumb.path);
            let newBreadcrumbs = breadcrumbs.slice(0, breadcrumbClickedIndex + 1);

            return {
                ...state,
                breadcrumbs: newBreadcrumbs,
            };
        }

        case aoeActions.ADD_BREADCRUMB_HISTORY: {
            return {
                ...state,
                breadcrumbs: [...state.breadcrumbHistory, action.payload],
            };
        }

        default:
            return state;
    }
};
