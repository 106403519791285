import { MigrationManifest, PersistedState, createMigrate, persistStore } from 'redux-persist';
import { Store, applyMiddleware, createStore } from 'redux';

import { AppState } from '../interfaces/app.state';
import { BehaviorSubject } from 'rxjs';
import { appEpics } from '../state/app.epics';
import { appInitState } from '../state/app.init-state';
import { appReducer } from '../state/app.reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createEpicMiddleware } from 'redux-observable';
import storage from 'redux-persist/lib/storage';

let epicsMiddleware = createEpicMiddleware();

const migrations: MigrationManifest = {
    0: (state: PersistedState) => state,
};

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['companies', 'customers', 'soa', 'orderLine', 'orderList', 'aoe', 'ordersConfirmation'],
    version: 0,
    migrate: createMigrate(migrations, { debug: true }),
};

const store: Store<AppState> = createStore(
    appReducer,
    composeWithDevTools(
        applyMiddleware(
            epicsMiddleware
        ),
    ),
);

const persistor = persistStore(store);
const store$ = new BehaviorSubject<AppState>(appInitState);

// Lazy loading ready
epicsMiddleware.run(appEpics);

let appState: AppState;

store.subscribe(() => {
    appState = store.getState();

    store$.next(appState);
});

export {
    store,
    store$,
    persistor
};
