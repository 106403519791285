import * as div from "./home.page.style";

import {
    BODY_CARDS_DATA,
    HEADER_CARDS_DATA
} from "../constants/cards.constants";
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router";

import { ActivityIndicator } from "../../shared/components/activity-indicator/activity-indicator";
import { AppState } from "../../shared/interfaces/app.state";
import { AuthService } from "../../auth/services/auth.service";
import { Cards } from "../components/cards/Cards";
import { IAzureADFunctionProps } from "react-aad-msal";
import { ITokenUserRole } from "../../users/interfaces/token-user-role";
import { PageWrapper } from "../../shared/styles/page-wrapper";
import { useSelector } from "react-redux";

interface Props extends IAzureADFunctionProps {}

const _HomePage: React.FC<Props> = (props: Props) => {
    // state
    const [loading] = useState<boolean>(false);

    // store
    const foundUnauthorizedCall = useSelector<AppState, boolean>(state => state.shared.foundUnauthorizedCall);
    const tokenUserRole = useSelector<AppState, ITokenUserRole | null>(state => state.users.tokenUserRole);

    const history = useHistory();

    const render = () => {
        if (foundUnauthorizedCall) {
            return <Redirect to='/login' />;
        }

        if (loading) {
            return <ActivityIndicator />;
        }

        return (
            <div.Wrapper>
                <Cards isHeader={true} data={HEADER_CARDS_DATA(tokenUserRole)} />
                <PageWrapper>
                    <Cards data={BODY_CARDS_DATA} />
                </PageWrapper>
            </div.Wrapper>
        );
    };

    useEffect(() => {
        AuthService.checkAuth(history);
    }, []);

    return render();
};

export const HomePage = _HomePage;
