import React, { useState } from 'react';
import { isEmpty, uniqueId } from 'lodash';

import { Button } from '../../../shared/components/button/button';
import { ButtonTypes } from '../../../shared/components/button/button.utils';
import { CustomersStateService } from '../../services/customers.state-service';
import { ICustomerInventoryShippingAddress } from '../../interfaces/customer';
import { Input } from '../../../shared/components/input/input';
import { Checkbox, Modal } from 'antd';

interface Props {
    address: ICustomerInventoryShippingAddress;
    onSubmit: (address: ICustomerInventoryShippingAddress, isNew: boolean) => void;
    customerId: number;
}

export const SaveInventoryAddressModal: React.FC<Props> = (props: Props) => {
    const { address, onSubmit, customerId } = props;

    const [inputs, setInputs] = useState({
        id: address?.id || 0,
        location: address?.location || '',
        shipToAddressLine1: address?.shipToAddressLine1 || '',
        shipToAddressLine2: address?.shipToAddressLine2 || '',
        shipToCity: address?.shipToCity || '',
        shipToState: address?.shipToState || '',
        shipToZipCode: address?.shipToZipCode || '',
        shipToCountry: address?.shipToCountry || '',
        mexicoAverageShippingWorkingDays: address?.mexicoAverageShippingWorkingDays || 0,
        quebecAverageShippingWorkingDays: address?.quebecAverageShippingWorkingDays || 0,
        defaultShippingAddress : address?.defaultShippingAddress || false,
        shippedFromCountry: address?.shippedFromCountry || '',
    });

    const render = () => {
        return (
            <Modal visible={true}
                title={'Save Address'}
                onCancel={() => CustomersStateService.toggleInventoryAddressModal(false)}
                okButtonProps={{ disabled: isButtonDisabled() }}
                footer={[
                    <Button key='cancel'
                        type={ButtonTypes.SECONDARY}
                        onClick={() => CustomersStateService.toggleInventoryAddressModal(false)}
                        label={'Cancel'} />,
                    <Button key='submit'
                        onClick={() => onSubmitClick()}
                        disabled={isButtonDisabled()}
                        label={'Submit'} />
                ]}>

                <Input label={'Location'}
                    value={inputs.location}
                    required={true}
                    onChange={location => setInputs({ ...inputs, location })} />

                <Input label={'Ship To Address Line 1'}
                    required={true}
                    isTextArea={true}
                    value={inputs.shipToAddressLine1}
                    onChange={address => setInputs({ ...inputs, shipToAddressLine1: address })} />

                <Input label={'Ship To Address Line 2'}
                    isTextArea={true}
                    maxRows={5}
                    value={inputs.shipToAddressLine2}
                    onChange={address => setInputs({ ...inputs, shipToAddressLine2: address })} />

                <Input label={'Ship To City'}
                    required={true}
                    value={inputs.shipToCity}
                    onChange={shipToCity => setInputs({ ...inputs, shipToCity })} />

                <Input label={'Ship To State'}
                    required={false}
                    value={inputs.shipToState}
                    onChange={shipToState => setInputs({ ...inputs, shipToState })} />

                <Input label={'Ship To Zip Code'}
                    required={true}
                    value={inputs.shipToZipCode}
                    onChange={shipToZipCode => setInputs({ ...inputs, shipToZipCode })} />

                <Input label={'Ship To Country'}
                    required={true}
                    value={inputs.shipToCountry}
                    onChange={shipToCountry => setInputs({ ...inputs, shipToCountry })} />
                
                <Input label={'Shipped from Country Code'}
                    required={false}
                    value={inputs.shippedFromCountry}
                    onChange={shippedFromCountry => setInputs({ ...inputs, shippedFromCountry })} />

                <Input label={'Mexico Average Shipping Working Days'}
                    required={true}
                    value={inputs.mexicoAverageShippingWorkingDays}
                    type='number'
                    onChange={days => setInputs({ ...inputs, mexicoAverageShippingWorkingDays: parseInt(days) })}
                    minValue={'0'} />

                <Input label={'Quebec Average Shipping Working Days'}
                    required={true}
                    value={inputs.quebecAverageShippingWorkingDays}
                    type='number'
                    onChange={days => setInputs({ ...inputs, quebecAverageShippingWorkingDays: parseInt(days) })}
                    minValue={'0'} />

                <Checkbox checked={inputs.defaultShippingAddress} onChange={event => setInputs({ ...inputs, defaultShippingAddress: event.target.checked})}>
                    Default Shipping Address for the Location
                </Checkbox>

            </Modal>
        );
    };

    const onSubmitClick = () => {
        let isNew = isEmpty(address) ? true : false;

        onSubmit({
            ...inputs,
            customerId,
            uuid: isNew ? uniqueId('new_') : address.uuid,
        }, isNew);
    };

    const isButtonDisabled = () => {
        if (
            !inputs.location ||
            !inputs.shipToAddressLine1 ||
            !inputs.shipToCity ||
            !inputs.shipToCountry ||
            !inputs.shipToZipCode
        ) {
            return true;
        }

        return false;
    };

    return render();
};
