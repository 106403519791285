import { Selector, createSelector } from 'reselect';

import { AppState } from '../../shared/interfaces/app.state';
import { IDayOffState } from '../interfaces/day-off-state';
import { IDayOff } from '../interfaces/day-off';

const DAYOFF_MODULE: Selector<AppState, IDayOffState> = state => state.dayOff;

export const DAYOFFS = createSelector<AppState, IDayOffState, IDayOff[]>(
    DAYOFF_MODULE,
    state => state.dayOffs
);

export const DAYOFFS_LOADING = createSelector<AppState, IDayOffState, boolean>(
    DAYOFF_MODULE,
    state => state.getDayOffsLoading
);

export const DELETED_DAYOFF_RESPONSE = createSelector<AppState, IDayOffState, string>(
    DAYOFF_MODULE,
    state => state.deleteDayOff.response
);

export const DELETED_DAYOFF_ERROR = createSelector<AppState, IDayOffState, string>(
    DAYOFF_MODULE,
    state => state.deleteDayOff.error
);


export const DELETE_DAYOFF_LOADING = createSelector<AppState, IDayOffState, boolean>(
    DAYOFF_MODULE,
    state => state.deleteDayOff.loading
);


export const CREATE_DAYOFF_RESPONSE = createSelector<AppState, IDayOffState, string>(
    DAYOFF_MODULE,
    state => state.createDayOff.response
);

export const CREATE_DAYOFF_ERROR = createSelector<AppState, IDayOffState, string>(
    DAYOFF_MODULE,
    state => state.createDayOff.error
);

export const CREATE_DAYOFF_LOADING = createSelector<AppState, IDayOffState, boolean>(
    DAYOFF_MODULE,
    state => state.createDayOff.loading
);


