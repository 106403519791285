import * as React from "react";

import { ErpOrderLinesPage } from "../pages/erp-order-lines.page";
import { IAzureADFunctionProps } from "react-aad-msal";
import { OrderDetailsPage } from "../pages/order-details.page";
import { OrdersPage } from "../pages/orders.page";
import { Route } from "react-router";

export const ordersRoutes = (azureAd: IAzureADFunctionProps) => {
    return [
        <Route
            exact={true}
            path="/orders/orders"
            key="orders-all"
            render={props => <OrdersPage {...props} {...azureAd} />}
        />,

        <Route exact={true}
            path='/orders/order-details/:id'
            key='order-details'
            render={props => <OrderDetailsPage {...props} {...azureAd} />} />,

        <Route exact={true}
            path='/orders/order-details/order-number/:orderNumber'
            key='order-details-by-order-number'
            render={props => <OrderDetailsPage {...props} {...azureAd} />} />,

        <Route exact={true}
            path='/orders/order-line'
            key='erp-order-lines'
            render={props => <ErpOrderLinesPage {...props} {...azureAd} />} />,
    ];
};
