import * as React from 'react';
import * as styled from './snackbar.style';

import { BELL, CHECK_CIRCLE, WARNING } from '../../assets/icons';

import { Icon } from '../../icon/icon';
import { SharedStateService } from '../../services/shared.state-service';
import { SnackbarTypes } from './snackback.utils';
import { colors } from '../../styles/colors';

interface Props {
    message: string;
    type: SnackbarTypes | null;
}

export const Snackbar: React.FC<Props> = (props: Props) => {
    const { message, type } = props;

    const render = () => {
        return (
            <styled.Wrapper type={type}>
                {getSnackbarIcon(type)}

                {/** Message */}
                <styled.Message>
                    {message}
                </styled.Message>

            </styled.Wrapper>
        );
    };

    React.useEffect(() => {
        setTimeout(() => {
            SharedStateService.setSnackbar({ isOpen: false, message: '', type: null });
        }, 5000);
    }, []);

    const getSnackbarIcon = (type: SnackbarTypes | null): JSX.Element => {
        switch (type) {
            case SnackbarTypes.SUCCESS: {
                return (
                    <Icon d={CHECK_CIRCLE} color={colors.$success} />
                );
            }

            case SnackbarTypes.ERROR: {
                return (
                    <Icon d={BELL} color={colors.$alert} />
                );
            }

            case SnackbarTypes.WARNING: {
                return (
                    <Icon d={WARNING} color={colors.$warning} />
                );
            }

            default: {
                return (
                    <Icon d={CHECK_CIRCLE} color={colors.$success} />
                );
            }
        }
    }

    return render();
};
