import * as CompaniesService from '../services/companies.state-service';
import * as React from 'react';

import { CustomersStateService, _deleteCustomer, _getCustomersByCompanyId, _toggleDeleteCustomerModal } from '../../customers/services/customers.state-service';
import { IApiTableRequest, IBreadcrumb, IServerError } from '../../shared/interfaces/shared';
import { ICustomer, IParser } from '../../customers/interfaces/customer';
import { _resetAoeBreadcrumbs, addBreadcrumb } from '../../aoe/services/aoe-state.service';
import { faCheck, faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons';

import { AppState } from '../../shared/interfaces/app.state';
import { AuthService } from '../../auth/services/auth.service';
import { BackendError } from '../../shared/components/backend-error/backend-error';
import { ColumnsType } from 'antd/lib/table';
import { ConfirmationModal } from '../../shared/components/confirmation-modal/confirmation-modal';
import { CustomerExpandedTable } from '../../customers/components/customers-expanded-table/customers-expanded-table';
import { ExpandableConfig } from 'antd/lib/table/interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GeckoTable } from '../../shared/components/gecko-table/gecko-table';
import { IAzureADFunctionProps } from 'react-aad-msal';
import { ICompany } from '../interfaces/company';
import { ICountryCode } from '../../shared/interfaces/country-code';
import { IPagination } from '../../shared/interfaces/pagination';
import { IPoType } from '../../shared/interfaces/po-type';
import { IRowMenu } from '../../shared/interfaces/row-menu';
import { ISiteId } from '../../shared/interfaces/site-id';
import { ISoa } from '../../soa/interfaces/soa';
import { PageTitle } from '../../shared/components/page-title/page-title';
import { PageWrapper } from '../../shared/styles/page-wrapper';
import { ROLES } from '../../users/interfaces/roles';
import { SaveCompanyModal } from '../components/save-company-modal/save-company-modal';
import { SaveCustomerModal } from '../../customers/components/save-customer-modal/save-customer-modal';
import { SharedStateService } from '../../shared/services/shared.state-service';
import { UsersService } from '../../users/services/users.service';
import { UsersStateService } from '../../users/services/users-state.service';
import { _getSoas } from '../../soa/services/soa-state.service';
import { colors } from '../../shared/styles/colors';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

interface Props extends IAzureADFunctionProps { }

export const CompaniesPage: React.FC<Props> = (props: Props) => {
    const history = useHistory();

    // store
    const companiesLoading = useSelector<AppState, boolean>(state => state.companies.companiesLoading);
    const deleteCompanyModal = useSelector<AppState, boolean>(state => state.companies.modals.delete);
    // const companies = useSelector<AppState, ICompany[]>(state => state.companies.companies);
    const company = useSelector<AppState, ICompany>(state => state.companies.company);
    const saveCompanyModal = useSelector<AppState, boolean>(state => state.companies.modals.save);
    const saveCompanyError = useSelector<AppState, IServerError | null>(state => state.companies.saveCompany.error);
    const soas = useSelector<AppState, ISoa[]>(state => state.soa.soas);
    const soasLoading = useSelector<AppState, boolean>(state => state.soa.getSoasLoading);
    const pagination = useSelector<AppState, IPagination<ICompany>>(state => state.companies.pagination);
    const expandedCompany = useSelector<AppState, ICompany | null>(state => state.companies.expandedCompany);
    const deleteCustomerModal = useSelector<AppState, boolean>(state => state.customers.modals.delete);
    const saveCustomerModal = useSelector<AppState, boolean>(state => state.customers.modals.save);
    const customer = useSelector<AppState, ICustomer>(state => state.customers.currentCustomer);
    const poTypes = useSelector<AppState, IPoType[]>(state => state.shared.poTypes);
    const parsers = useSelector<AppState, IParser[]>(state => state.customers.parsers);
    const siteIds = useSelector<AppState, ISiteId[]>(state => state.shared.siteIds);
    const countryCodes = useSelector<AppState, ICountryCode[]>(state => state.shared.countryCodes);
    const externalCustomerIds = useSelector<AppState, string[]>(state => state.customers.erpCustomerIds);

    const render = () => {
        return (
            <PageWrapper>
                <PageTitle text={'Companies'} />

                <GeckoTable dataSource={{ ...pagination }}
                    columns={columns(soas)}
                    showRowMenu={true}
                    // onRow={onRow}
                    showPagination={true}
                    rowMenu={company => rowMenu(company)}
                    tableId={'companies'}
                    filters={[]}
                    // expandable={expandable()}
                    isLoading={companiesLoading || soasLoading}
                    initConfig={initCompaniesTableReq}
                    actions={{ add: true }}
                    onAddClick={() => CompaniesService._toggleSaveCompanyModal(true)}
                    apiCall={params => apiCall(params)} />

                {
                    deleteCompanyModal &&
                    <ConfirmationModal onClose={() => onDeleteModalClose()}
                        onConfirm={() => CompaniesService._deleteCompany(company)} />
                }

                {
                    saveCompanyModal &&
                    <SaveCompanyModal company={company} error={saveCompanyError} />
                }

                {
                    deleteCustomerModal &&
                    <ConfirmationModal onClose={() => _toggleDeleteCustomerModal(false)}
                        onConfirm={() => _deleteCustomer(customer)} />
                }

                {
                    saveCustomerModal &&
                    <SaveCustomerModal customer={customer}
                        companyId={expandedCompany ? expandedCompany.id.toString() : ''}
                        countryCodes={countryCodes}
                        parsers={parsers}
                        error={''}
                        siteIDs={siteIds}
                        externalCustomerIds={externalCustomerIds}
                        poTypes={poTypes} />
                }

                <BackendError />
            </PageWrapper>
        );
    };

    const onPageInit = React.useCallback(() => {
        AuthService.checkAuth(history);

        _getSoas();
        _resetAoeBreadcrumbs();
        CustomersStateService.getErpCustomerIds();
    }, []);

    React.useEffect(() => {
        CustomersStateService.getParsers();
        SharedStateService.getSiteIds();
        SharedStateService.getPoTypes();
        UsersStateService.getRoles();
        SharedStateService.getCountryCodes();

        return () => {
            CompaniesService.CompaniesStateService.setExpandedCompany(null);
        };
    }, []);

    React.useEffect(() => {
        onPageInit();

        return () => {
            CompaniesService._resetCompaniesModule();
        };
    }, [onPageInit]);

    const initCompaniesTableReq: IApiTableRequest = {
        pageSize: 20,
        pageNumber: 1,
        filters: [],
    };

    const expandable = (): ExpandableConfig<ICompany> => {
        return {
            expandedRowRender: () => <CustomerExpandedTable customers={expandedCompany ? expandedCompany.customers : []} />,
            onExpand: onRowExpand,
            expandedRowKeys: [expandedCompany ? expandedCompany.id : 0],
            expandedRowClassName: () => 'table-expanded-row',
            expandIcon: ({ expanded, onExpand, record }) => expanded ? (
                <FontAwesomeIcon icon={faMinusSquare} onClick={(e: any) => onExpand(record, e)} style={{ fontSize: 18, cursor: 'pointer' }} />
            ) : (
                <FontAwesomeIcon icon={faPlusSquare} onClick={(e: any) => onExpand(record, e)} style={{ fontSize: 18, cursor: 'pointer' }} />
            ),
        };
    };

    const apiCall = (params: IApiTableRequest) => {
        CompaniesService._getCompanies(params);
    };

    const onDeleteModalClose = () => {
        CompaniesService._resetCompany();
        CompaniesService._toggleDeleteCompanyModal(false);
    };

    // const onRow = (company: ICompany, _rowIndex?: number) => {
    //     return {
    //         onClick: () => onTableRowClick(company),
    //     };
    // };

    const onRowExpand = (expanded: boolean, company: ICompany) => {
        if (expanded) {
            // call for getCustomers
            CompaniesService.CompaniesStateService.setExpandedCompany(company);
        } else {
            CompaniesService.CompaniesStateService.setExpandedCompany(null);
        }
    };

    const onTableRowClick = (company: ICompany) => {
        const path = `/admin/companies/${company.id}/customerIds`;
        const breadcrumb: IBreadcrumb = {
            path,
            label: `Company: ${company.name}`,
        };

        addBreadcrumb(breadcrumb);
        CompaniesService.CompaniesStateService.setBreacrumbCompany(company);

        history.push(path);
    };

    const columns = (soas: ISoa[]): ColumnsType<ICompany> => {
        return [{
            title: 'Name',
            dataIndex: 'name',
            key: 'companies_name',
        }, {
            title: 'SOA',
            // dataIndex: 'soaId',
            render: (_text: string, company: ICompany) =>
                <>
                    {getSoaName(soas, company.soaId)}
                </>,
            key: 'companies_soa',
        }, {
            title: 'Email Domain Search',
            render: (_text: string, company: ICompany) => getIcon(company.emailDomainSearch),
            key: 'companies_emailDomainSearch',
        }];
    };

    const getIcon = (flag: boolean) => {
        if (flag) {
            return (
                <FontAwesomeIcon icon={faCheck}
                    color={colors.$success}
                    size={'lg'} />
            );
        }
    };

    const getSoaName = (soas: ISoa[], soaId: string) => {
        if (soas && soaId) {
            let foundSoa = soas.filter(soa => soa.id.toString() === soaId.toString())[0];

            if (foundSoa) {
                return foundSoa.name;
            }
        }

        return '';
    };

    const rowMenu = (company: ICompany): IRowMenu[] => {
        return [{
            label: 'View Customers',
            onClick: () => onTableRowClick(company),
        }, {
            label: 'Update Company',
            onClick: () => onEditPress(company),
        }, {
            label: 'Delete Company',
            onClick: () => onDeletePress(company),
            disabled: !UsersService.authorizeDisplay([ROLES.Admin]),
        }];
    }

    const onDeletePress = (company: ICompany) => {
        CompaniesService._toggleDeleteCompanyModal(true);
        CompaniesService._setCompany(company);
    };

    const onEditPress = (company: ICompany) => {
        CompaniesService._toggleSaveCompanyModal(true);
        CompaniesService._setCompany(company);
    };

    return render();
};
