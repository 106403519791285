import { IFilterDayOff, IDayOff } from '../interfaces/day-off';
import { Observable, from } from 'rxjs';

import { IDayOffFormValues } from '../interfaces/day-off-form-values'
import { axios } from '../../shared/services/axios.service';

export const getDayOffs = (): Observable<IDayOff[]> =>
    from<Promise<IDayOff[]>>( axios.get<IDayOff[]>('/daysoff')
        .then(res => res.data));

export const deleteDayOff = (id: number): Observable<string> =>
    from<Promise<string>>(axios.delete<string>(`/daysoff/${id}`)
        .then(res => res.statusText));


export const createDayOff = (dayOff: IDayOffFormValues): Observable<string> =>
    from<Promise<string>>(axios.post<string>('/daysoff', { ...dayOff })
        .then(res => res.statusText));

export const getFilterDayOffs = (): Observable<IFilterDayOff[]> =>
    from(
        axios.get(`/daysoff/forSearch`)
            .then(res => res.data),
    );

export const getDayOffById = (id: number): Observable<IDayOff> =>
    from(
        axios.get(`/daysoff/${id}`)
            .then(res => res.data),
    );
