import * as dayOffActions from './day-off.actions';
import * as dayOffWebApi from '../webapi/day-off.webapi';

import {
    _getDayOffs,
    _toggleCreateDayOffLoading,
    _toggleCreatetDayOffModal,
    _toggleDeleteDayOffLoading,
    _toggleDeleteDayOffModal,
    _toggleGetDayOffsLoading
} from '../services/day-off-state.service';
import { catchError, concatMap, map, tap } from 'rxjs/operators';

import { Action } from '../../shared/interfaces/shared';
import { ActionsObservable } from 'redux-observable';
import { AppState } from '../../shared/interfaces/app.state';
import { IDayOffFormValues } from '../interfaces/day-off-form-values'
import { SnackbarTypes } from '../../shared/components/snackbar/snackback.utils';
import { Store } from 'redux';
import { _setSnackbar } from '../../shared/services/shared.state-service'
import { of } from 'rxjs';

export const getDayOffs$ = (action$: ActionsObservable<Action<null>>, _store: Store<AppState>) =>
    action$.ofType(dayOffActions.GET_DAYOFFS_REQ).pipe(
        tap(() => _toggleGetDayOffsLoading(true)),
        map(action => action.payload),
        concatMap(() =>
            dayOffWebApi.getDayOffs().pipe(
                tap(() => _toggleGetDayOffsLoading(false)),
                map(dayOffs => dayOffActions.getDayOffsOk(dayOffs)),
                catchError(error => of(dayOffActions.getDayOffsFail(error)))
            )
        )
    );

export const deleteDayOffById$ = (action$: ActionsObservable<Action<number>>, _store: Store<AppState>) =>
    action$.ofType(dayOffActions.DELETE_DAYOFF_BY_ID_REQ).pipe(
        tap(() => _toggleDeleteDayOffLoading(true)),
        map(action => action.payload!),
        concatMap(id =>
            dayOffWebApi.deleteDayOff(id).pipe(
                tap(() => _toggleDeleteDayOffLoading(false)),
                tap(() => _toggleDeleteDayOffModal(false)),
                map(response => {
                    _getDayOffs();
                    _setSnackbar({
                        message: 'Successfully deleted',
                        type: SnackbarTypes.SUCCESS,
                        isOpen: true,
                    });
                    return dayOffActions.deleteDayOffByIdOK(response);
                }),
                catchError(error => {

                    _setSnackbar({
                        message: 'There has been an error',
                        type: SnackbarTypes.ERROR,
                        isOpen: true,
                    });
                    return of(dayOffActions.deleteDayOffByIdFail(error.response.data))
                })
            )
        )
    );


export const createDayOff$ = (action$: ActionsObservable<Action<IDayOffFormValues>>, _store: Store<AppState>) =>
    action$.ofType(dayOffActions.CREATE_DAYOFF_REQ).pipe(
        tap(() => _toggleCreateDayOffLoading(true)),
        map(action => action.payload!),
        concatMap(dayOff =>
            dayOffWebApi.createDayOff(dayOff).pipe(
                tap(() => _toggleCreateDayOffLoading(false)),
                tap(() => _toggleCreatetDayOffModal(false)),
                map(response => {
                    _getDayOffs();
                    _setSnackbar({
                        message: `DayOff for site id "${dayOff.siteId}" for day "${dayOff.day}"  was created.`,
                        type: SnackbarTypes.SUCCESS,
                        isOpen: true,
                    });
                    return dayOffActions.createDayOffOk(response);
                }),
                catchError(error => {
                    let err = error.response.data;
                    _setSnackbar({
                        message: err.description,
                        type: SnackbarTypes.ERROR,
                        isOpen: true,
                    });
                    return of(dayOffActions.createDayOffFail(error.response.data))
                })
            )
        )
    );

export const getFilterDayOffs$ = (action$: ActionsObservable<Action<null>>) =>
    action$.ofType(dayOffActions.GET_FILTER_DAYOFFS_REQ).pipe(
        map(action => action.payload),
        concatMap(() => dayOffWebApi.getFilterDayOffs().pipe(
            map(dayOffs => dayOffActions.getFilterDayOffsOk(dayOffs)),
            catchError(err => of(dayOffActions.getFilterDayOffsFail(err.response.data))),
        )),
    );

export const getDayOffById$ = (action$: ActionsObservable<Action<number>>) =>
    action$.ofType(dayOffActions.GET_DAYOFF_BY_ID_REQ).pipe(
        map(action => action.payload!),
        concatMap(id => dayOffWebApi.getDayOffById(id).pipe(
            map(dayOff => dayOffActions.getDayOffByIdOk(dayOff)),
            catchError(err => of(dayOffActions.getDayOffByIdFail(err.response && err.response.data))),
        )),
    );