import { INotificationPreferenceReq } from "../interfaces/notification-preference";
import { axios } from "../../shared/services/axios.service";
import { from } from "rxjs";

export class NotificationsWebApi {
    public static getNotificationChannels() {
        return from(
            axios.get('/NotificationChannel')
                .then(res => res.data),
        );
    }

    public static getNotificationEvents() {
        return from(
            axios.get('/NotificationEvent')
                .then(res => res.data),
        );
    }

    public static getPreferencesByCustomerId(id: string) {
        return from(
            axios.get(`/NotificationPreference/${id}`)
                .then(res => res.data),
        );
    }

    public static updatePreference(req: INotificationPreferenceReq) {
        let newReq = { ...req };

        return from(
            axios.put(`/NotificationPreference/${req.id}`, { ...newReq })
                .then(res => res.data),
        );
    }

    public static createPreference(req: INotificationPreferenceReq) {
        return from(
            axios.post(`/NotificationPreference`, { ...req })
                .then(res => res.data),
        );
    }

}
