import { UsersStateService } from "../../users/services/users-state.service";
import { getToken } from "../../shared/services/msal";

export class AuthService {
    public static async checkAuth(history: any) {
        let token = await getToken();

        if (!token) {
            UsersStateService.setToken('');
            history.push('/login');
        } else {
            UsersStateService.setToken(token.accessToken);
        }
    }

    public static async isAuth() {
        let token = await getToken();

        if (token) {
            UsersStateService.setToken(token.accessToken);
        }

        return !!token;
    }

}