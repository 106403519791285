import { IDownloadInvoiceReq, IEditErpOrderLineReq, IEditOrderReq, IErpOrderLine, IOrder } from "../interfaces/order";

import { IApiTableRequest } from "../../shared/interfaces/shared";
import { IEditErpOrderHeaderRequest } from "../interfaces/edit-erp-order-header-request";
import { IUpdateErpOrderLineRequest } from "../interfaces/update-erp-order-line-request";
import { OrdersActions } from "../state/orders.actions";
import { store } from "../../shared/services/state.service";

export class OrdersStateService {
    public static getOrders(request: IApiTableRequest) {
        store.dispatch(
            OrdersActions.getOrdersReq(request),
        );
    }

    public static getOrderDetails(id: number) {
        store.dispatch(
            OrdersActions.getOrderDetailsReq(id),
        );
    }

    public static resetCurrentCompleteOrder() {
        store.dispatch(
            OrdersActions.resetCurrentCompleteOrder(),
        );
    }

    public static getErpOrderInvoices(id: string) {
        store.dispatch(
            OrdersActions.getOrderInvoicesInfoReq(id),
        );
    }

    public static downloadInvoice(req: IDownloadInvoiceReq) {
        store.dispatch(
            OrdersActions.downloadInvoiceReq(req),
        );
    }

    public static getErpPoNumbers(req: string) {
        store.dispatch(
            OrdersActions.getErpPoNumbersReq(req),
        );
    }

    public static getErpOrderNumbers(req: string) {
        store.dispatch(
            OrdersActions.getErpOrderNumbersReq(req),
        );
    }

    public static toggleEditOrderModal(flag: boolean) {
        store.dispatch(
            OrdersActions.toggleEditOrderModal(flag),
        );
    }

    public static setCurrentOrder(order: IOrder) {
        store.dispatch(
            OrdersActions.setCurrentOrder(order),
        );
    }

    public static editOrder(req: IEditOrderReq) {
        store.dispatch(
            OrdersActions.editOrderReq(req),
        );
    }

    public static toggleEditErpOrderLine(flag: boolean) {
        store.dispatch(
            OrdersActions.toggleEditErpOrderLine(flag),
        );
    }

    public static setCurrentErpOrderLine(erpOrderLine: IErpOrderLine) {
        store.dispatch(
            OrdersActions.setCurrentErpOrderLine(erpOrderLine),
        );
    }

    public static editErpOrderLine(req: IEditErpOrderLineReq) {
        store.dispatch(
            OrdersActions.editErpOrderLineReq(req),
        );
    }

    public static toggleEditErpOrderHeaderModal(flag: boolean) {
        store.dispatch(
            OrdersActions.toggleEditErpOrderHeaderModal(flag),
        );
    }

    public static editErpOrderHeader(req: IEditErpOrderHeaderRequest) {
        store.dispatch(
            OrdersActions.editErpOrderHeaderReq(req),
        );
    }

    public static getErpOrderLines(req: IApiTableRequest) {
        store.dispatch(
            OrdersActions.getErpOrderLinesReq(req),
        );
    }

    public static toggleErpOrderLinesLoading(flag: boolean) {
        store.dispatch(
            OrdersActions.toggleErpOrderLinesLoading(flag),
        );
    }

    public static updateErpOrderLines(req: IUpdateErpOrderLineRequest) {
        store.dispatch(
            OrdersActions.updateErpOrderLinesReq(req),
        );
    }

    public static toggleBulkEditButton(flag: boolean) {
        store.dispatch(
            OrdersActions.toggleBulkEditButton(flag),
        );
    }

    public static toggleBulkEditModal(flag: boolean) {
        store.dispatch(
            OrdersActions.toggleBulkEdit(flag),
        );
    }

    public static toggleBulkEditMessage(flag: boolean) {
        store.dispatch(
            OrdersActions.toggleBulkEditMessage(flag),
        );
    }

    public static toggleOrdersLoading(flag: boolean) {
        store.dispatch(
            OrdersActions.toggleOrdersLoading(flag),
        );
    }

    public static toggleConfirmBulkEditModal(flag: boolean) {
        store.dispatch(
            OrdersActions.toggleConfirmBulkEditModal(flag),
        );
    }

    public static getOrderDetailsByOrderNumber(orderNumber: string) {
        store.dispatch(
            OrdersActions.getOrderDetailsByOrderNumberReq(orderNumber),
        );
    }

}
