import { IOrderBlob, IOrderList, IOrderListFilters } from '../interfaces/order-list';

import { IOrderListState } from '../interfaces/order-list-state';
import { IPagination } from '../../shared/interfaces/pagination';
import { IShippingFee } from '../interfaces/shipping-fee';

export const orderListInitState: IOrderListState = {
    modals: {
        delete: false,
        create: false,
        shippingFees: false,
        updateAddress: false,
    },
    orderLists: [] as IOrderList[],
    pagination: {} as IPagination<IOrderList>,
    filteredOrderLists: [] as IOrderList[],
    filters: {} as IOrderListFilters,
    getOrderListsLoading: false,
    createOrderList: {
        loading: false,
        response: '',
        error: '',
    },
    deleteOrderList: {
        deletedOrderList: {} as IOrderList,
        loading: false,
        response: '',
        error: '',
    },
    editOrderList: {
        currentOrderList: {} as IOrderList,
        loading: false,
        response: '',
        error: '',
    },
    document: {
        loading: false,
        response: '',
        error: '',
    },
    confirmCustomerOrder: {
        loading: false,
        error: '',
    },
    sendToErp: {
        loading: false,
        error: '',
    },
    validationLoading: false,
    showFilters: false,
    viewMode: false,
    filterSoas: [],
    filtersModal: {
        poNumbers: [],
    },
    orderBlob: {} as IOrderBlob,
    shippingFees: [],
    loadingShippingFees: false,
    selectedShippingFee: {} as IShippingFee,
};
