import { IFilterColumnProps } from '../interfaces/theme-interfaces';
import { colors } from './colors';
import { fonts } from './fonts';
import styled from 'styled-components';

export class Theme {
    public static readonly Inline = styled.div`
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
    `;

    public static readonly Center = styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
    `;

    public static readonly InlineFlexEnd = styled.div`
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        flex-direction: row;
    `;

    public static readonly Section = styled.div<ISectionProps>`
        margin-bottom: 30px;
        width: ${props => props.width ? props.width : '100%'};
        margin-left: ${props => props.marginLeft ? props.marginLeft : 0};
    `;

    public static readonly Label = styled.div`
        font-weight: 500;
        font-size: 14px;
        color: ${colors.$darkBlue};
    `;

    public static readonly SpaceBetween = styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        margin-bottom: 10px;
    `;

    public static readonly SpaceBetweenTop = styled.div`
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: row;
        margin-bottom: 10px;
    `;

    public static readonly ErrorMessage = styled.div`
        color: ${colors.$alert};
        font-weight: 600;
        margin-top: 10px;
    `;

    public static readonly PageWrapper = styled.div`
        max-width: 1460px;
        margin: 20px auto;
    `;

    public static readonly Clickable = styled.div`
        cursor: pointer;
        color: #1890ff;
    `;

    public static readonly Bold = styled.div`
        font-weight: 900;
    `;

    public static readonly Filters = {
        Wrapper: styled.div`
            width: 100%;
        `,
        Title: styled.div`
            /* font-family: ${fonts.proxima};
            font-weight: 500;
            font-size: 16px;
            color: ${colors.$darkBlue}; */
            font-size: 12px;
            font-weight: bold;
            font-family: ${fonts.proxima};
            color: ${colors.$darkBlue};
            line-height: 1.17;
            margin-bottom: 5px;
        `,
        Body: styled.div`
            display: flex;
            align-items: center;
            flex-direction: row;
        `,
        Column: styled.div<IFilterColumnProps>`
            display: flex;
            align-items: center;
            flex-direction: row;
            flex: 1;
            padding-left: ${props => props.paddingLeft || 0};
            padding-right: ${props => props.paddingRight || 0};
        `,
    };

}

interface ISectionProps {
    width?: string;
    marginLeft?: string;
}
