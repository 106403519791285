/**
 * List of filters separated by Business Object
 * "GeckoTable" accepts a list of the following items in "filters" prop
 */
export enum FILTERS {
    COMPANY = 'company',
    CURRENCY = 'currency',
    CUSTOMER_ID = 'customerId',
    PRICE = 'price',
    PO_NUMBER = 'poNumber',
    ERP_COMPANY = 'erpCompany',
    ERP_CUSTOMER_ID = 'erpCustomerId',
    ERP_PONUMBER = 'erpPoNumber',
    ERP_STATUS = 'erpStatus',
    SOA = 'soa',
    STATUS = 'status',
    SHIPPED_DATE = 'shippedDate',
    ERP_SHIPPED_DATE = 'erpShippedDate',
    EXPECTED_DATE = 'expectedDate',
    REVISED_DATE = 'revisedDate',
    ORDER_DATE = 'orderDate',
    CONFIRMED_DATE = 'confirmedDate',
    ORDER_ID = 'orderId',
    PART_ID = 'partId',
    QTY = 'qty',
    SHIPPED_QTY = 'shippedQty',
    UNIT_PRICE = 'unitPrice',
    PART_DESCRIPTION = 'partDescription',
    ERP_TOTAL_COST = 'totalCost',
    ERP_ORDER_LINES_STATUS = 'erpOrderLinesStatus',
}
