import * as companiesActions from '../../companies/state/companies.actions';
import * as customersActions from '../../customers/state/customers.actions';
import * as orderListActions from '../../order-list/state/order-list.actions';

import { SharedActions } from './shared.actions';
import { SharedState } from "../interfaces/shared.state";
import { sharedInitState } from "./shared.init-state";
import { sortBy } from 'lodash';

export function sharedReducer(state: SharedState = sharedInitState, action: any): SharedState {
    switch (action.type) {
        // case SharedActions.SET_SAMPLE: {
        //     return {
        //         ...state,
        //         sample: action.payload,
        //     };
        // }

        case SharedActions.GET_PO_TYPES_OK: {
            return {
                ...state,
                poTypes: action.payload,
            };
        }

        case SharedActions.SET_SNACKBAR: {
            return {
                ...state,
                snackbar: action.payload,
            };
        }

        case SharedActions.GET_SHIPVIA_OK: {
            const shipViasSorted = sortBy(action.payload, ['code']);

            return {
                ...state,
                shipVias: shipViasSorted,
            };
        }

        case SharedActions.SET_FOUND_UNAUTHORIZED_CALL: {
            return {
                ...state,
                foundUnauthorizedCall: action.payload,
            };
        }

        case SharedActions.GET_FOBS_OK: {
            const sortedFobs = sortBy(action.payload);

            return {
                ...state,
                fobs: sortedFobs,
            };
        }

        case SharedActions.GET_SITE_IDS_OK: {
            return {
                ...state,
                siteIds: action.payload,
            };
        }

        case SharedActions.SET_BACKEND_NOT_WORKING: {
            return {
                ...state,
                backendNotWorking: action.payload,
            };
        }

        case SharedActions.GET_ALL_PART_IDS_OK: {
            return {
                ...state,
                allPartIds: action.payload,
            };
        }

        case SharedActions.SET_CURRENT_API_TABLE_REQUEST: {
            return {
                ...state,
                apiTableRequest: action.payload,
            };
        }

        case SharedActions.SET_SHOULD_TABLE_UPDATE: {
            return {
                ...state,
                shouldTableUpdate: action.payload,
            };
        }

        case SharedActions.GET_COUNTRY_CODES_OK: {
            return {
                ...state,
                countryCodes: action.payload,
            };
        }

        // ===== BREADCRUMBS =====

        case companiesActions.SET_BREADCRUMB_COMPANY: {
            return {
                ...state,
                breadcrumbs: {
                    ...state.breadcrumbs,
                    company: action.payload,
                },
            };
        }

        case companiesActions.GET_BREADCRUMB_COMPANY_OK: {
            return {
                ...state,
                breadcrumbs: {
                    ...state.breadcrumbs,
                    company: action.payload,
                },
            };
        }

        case customersActions.SET_BREADCRUMB_CUSTOMER: {
            return {
                ...state,
                breadcrumbs: {
                    ...state.breadcrumbs,
                    customer: action.payload,
                },
            };
        }

        case customersActions.GET_BREADCRUMB_CUSTOMER_OK: {
            return {
                ...state,
                breadcrumbs: {
                    ...state.breadcrumbs,
                    customer: action.payload,
                },
            };
        }

        case orderListActions.SET_BREADCRUMB_ORDER_LIST: {
            return {
                ...state,
                breadcrumbs: {
                    ...state.breadcrumbs,
                    orderList: action.payload,
                },
            };
        }

        case orderListActions.GET_BREADCRUMB_ORDER_LIST_OK: {
            return {
                ...state,
                breadcrumbs: {
                    ...state.breadcrumbs,
                    orderList: action.payload,
                },
            };
        }

        default:
            return state;
    }
}
