import { ICompany } from "../interfaces/company";
import { ICompanyState } from "../interfaces/companies-state";
import { IPagination } from "../../shared/interfaces/pagination";

export const companiesInitState: ICompanyState = {
    companies: [],
    company: {} as ICompany,
    companiesLoading: false,
    pagination: {} as IPagination<ICompany>,
    erpCompanies: [],
    expandedCompany: null,

    saveCompany: {
        response: {} as ICompany,
        error: null,
    },

    deleteCompany: {
        response: {} as ICompany,
        error: null,
    },

    modals: {
        delete: false,
        save: false,
    },

    filters: {
        companies: [],
    }
};
