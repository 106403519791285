import { ICustomer, ICustomerInventoryShippingAddress } from "../interfaces/customer";

import { ICustomerDetails } from "../interfaces/customer-details";
import { ICustomersState } from "../interfaces/customers-state";

export const customersInitState: ICustomersState = {
    customer: {} as ICustomer,
    customers: [],

    customersLoading: false,
    customersError: '',

    currentCustomer: {} as ICustomer,

    erpCustomerIds: [],
    erpCustomerIdsLoading: false,

    parsers: [],

    customerByExternalId: {
        response: null,
        error: null,
    },
    customerByExternalIdLoading: false,

    customersForUser: [],
    customersForUserLoading: false,

    deleteCustomer: {
        response: {} as ICustomer,
        error: '',
    },

    saveCustomer: {
        response: {} as ICustomer,
        error: '',
    },

    modals: {
        save: false,
        delete: false,
        inventoryAddress: false,
    },

    filters: {
        externalCustomerIds: [],
    },

    productCodes: [],
    incoterms: [],
    incotermsLoading: false,

    freights: [],
    freightsLoading: false,

    customerParts: [],
    customerPartsLoading: false,

    customerDetails: {} as ICustomerDetails,
    customerDetailsLoading: false,

    shippingAddresses: [],
    shippingAddressesLoading: false,

    selectedInventoryAddress: {} as ICustomerInventoryShippingAddress,
};
